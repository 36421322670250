Adcurve.deferProductUpdates = function(shopCodes, total_products, endpoint, component, extra_data, method) {
  method = typeof method !== 'undefined' ? method : 'POST';
  var batch_codes, deferreds = [], processed = 0, batch_number = 100;
  for (var i = 0; i <= parseInt(total_products / batch_number, 10); i++) {
    batch_codes = shopCodes.splice(0, batch_number);
    var data = _.extend({shop_codes: batch_codes}, extra_data);
    deferreds.push(
      $.ajax({
        method: method,
        url: endpoint,
        data: data,
        success: function(){
          processed += batch_number;
          component.updateProgressBar(total_products, processed);
        }
      })
    );
  }
  return deferreds.reverse();
};
Adcurve.requestFailed = function(jqXHR, statusTxt, errTxt) {
  $('.progress-bar-container').hide();
  $('.content .header').after('<div class="flash error"><i class="material-icons md-24 icon">error</i>' + I18n.t("ads_management.request_failed") + '</div>');
};
