/*global Adcurve, _, moment */
Adcurve.StatisticsItem = function(element, collection) {
  this._item = element;
  this.collection = collection;
  if (this._item.shop_code){
    // Product id is not used anywhere, GC it.
    delete this._item.product_id;
    this.setUnspecified();
    this.setDefaultFields();
    this.setDefaultNumberStats();
    this.setDefaults();
    this.setTaxonomies();
  }
};

Adcurve.StatisticsItem.prototype.setUnspecified = function() {
  if (this._item.shop_code == 'Unspecified') {
    this._item.unspecified = true;
    this._item.product_name = 'Unspecified';
    this._item.category = 'Uncategorized';
    this._item.brand = '';
    this._item.delivery_period = '';
    this._item.delivery_cost = 0;
    this._item.assists = 0;
    this._item.active = false;
    this._item.enabled = false;
    this._item.stock_status = '';
  } else {
    this._item.unspecified = false;
  }
};

Adcurve.StatisticsItem.prototype.setDefaultFields = function() {
  var that = this;
  if (typeof Adcurve.shopFields === 'undefined') {
    return;
  }
  var currencyIDs = Adcurve.shopFields.currencyIDs();
  var shopFieldsIDs = Adcurve.shopFields.IDs();
  var field;
  for (var i = 0; i < shopFieldsIDs.length; i++) {
    field = shopFieldsIDs[i];
    if (_.indexOf(currencyIDs, field) !== -1) {
      that._item[field] = typeof(that._item[field]) === 'undefined' ? 0 : that._item[field] / 100;
    }
  }
};
Adcurve.StatisticsItem.prototype.calculateProductMargin = function() {
  if (this._item.price_incl === 0 && this._item.price_excl === 0) {
    return 0;
  }
  var price_excl;
  if (this._item.price_excl !== 0) {
    price_excl = this._item.price_excl;
  } else {
    price_excl = this._item.price_incl / (1 + (Adcurve.selectedShop.tax / 100));
  }

  var margin;
  if ((Adcurve.selectedShop.tax === '' || Adcurve.selectedShop.margin === '') && (this._item.price_excl === 0 && this._item.floor_price === 0)) {
    margin = 0;
  } else if (this._item.floor_price !== 0) {
    margin =  (price_excl - this._item.floor_price);
  } else {
    margin = (price_excl * (Adcurve.selectedShop.margin / 100));
  }

  return margin;
}
Adcurve.StatisticsItem.prototype.setDefaultNumberStats = function() {
  if (!this._item.profit) { this._item.profit = 0; }
  if (!this._item.costs) { this._item.costs = 0; }
  if (!this._item.traffic) { this._item.traffic = 0; }
  if (!this._item.revenue) { this._item.revenue = 0; }
  if (!this._item.margin) { this._item.margin = 0; }
  if (!this._item.ecpc) { this._item.ecpc = 0; }
  if (!this._item.max_cpc) { this._item.max_cpc = 0; }
  if (!this._item.roi) { this._item.roi = 0; }
  if (!this._item.assists) { this._item.assists = 0; }
  if (!this._item.revenue) { this._item.revenue = 0; }
  if (!this._item.price_incl) { this._item.price_incl = 0; } else { this._item.price_incl = this._item.price_incl / 100; }
  if (!this._item.marked_price) { this._item.marked_price = 0; } else { this._item.marked_price = this._item.marked_price / 100; }
  if (!this._item.price_excl) { this._item.price_excl = 0; } else { this._item.price_excl = this._item.price_excl / 100; }
  if (!this._item.floor_price) { this._item.floor_price = 0; } else { this._item.floor_price = this._item.floor_price / 100; }
  if(!this._item.order_amount_excluding_tax) { this._item.order_amount_excluding_tax = 0; }
  this._item.product_margin = this.calculateProductMargin();
};

Adcurve.StatisticsItem.prototype.setDefaults = function() {
  if (!this._item.product_ean) {
    this._item.product_ean = '';
  }
  if (typeof this._item.stock_status !== 'undefined' && this._item.stock_status !== null && this._item.stock_status !== "") {
    this.collection.stockStatusesPush(this._item.stock_status);
  }
  if (typeof this._item.delivery_period !== 'undefined' && this._item.delivery_period !== null && this._item.delivery_period !== "") {
    this.collection.deliveryPeriodsPush(this._item.delivery_period);
  }
  if (this._item.price_excl !== 0) {
    this._item.margin_ratio = (1 - this._item.floor_price /  this._item.price_excl );
  } else {
    this._item.margin_ratio = 0;
  }

  if (this._item.sub_category) {
    this._item.category = this._item.category + "»" + this._item.sub_category;
  }
  if (typeof this._item.user_updated_at !== 'undefined') {
    this._item.user_updated_at = moment(this._item.user_updated_at).format('MMMM Do YYYY, h:mm:ss a');
  }
  if (typeof this._item.modified_by !== 'undefined') {
    // TODO: replace with local variables, remove from dependency
    this._item.modified_by_user = Adcurve.users.findById(this._item.modified_by);
  }
  if (typeof this._item.category_updated_at !== 'undefined') {
    this._item.category_updated_at = moment(this._item.category_updated_at).format('MMMM Do YYYY, h:mm:ss a');
  }
  if (typeof this._item.category_modified_by !== 'undefined') {
    // TODO: replace with local variables, remove from dependency
    this._item.category_modified_by_user = Adcurve.users.findById(this._item.category_modified_by);
  }
  this._item.shop_code_html_id = this._item.shop_code.replace(/\W+/gi, "");
  // TODO: replace with local variables, remove from dependency
  if (Date.parse(this._item.last_updated) > Adcurve.adSettings.feed_last_generated ||
      Date.parse(this._item.last_webhook_date) > Adcurve.adSettings.feed_last_generated) {
    this._item.not_synced = true;
    Adcurve.notSyncedProducts += 1;
  } else {
    this._item.not_synced = false;
  }
  this._item.isQuarantine = (Date.now() - Date.parse(this._item.quarantined_at)) / 86400000 < 30;
};

Adcurve.StatisticsItem.prototype.item = function() {
  return this._item;
};

Adcurve.StatisticsItem.prototype.setTaxonomies = function() {
  var allRuleTaxonomies = this._item.taxonomies;
  var that = this;
  this._item.taxonomies = [];
  this._item.rule_channel_category_ids = [];

  if (typeof allRuleTaxonomies !== 'undefined') {
    // Go over all taxonomies created from rules and append them to the taxonomies array
    _.each(allRuleTaxonomies, function(value, id){
      if (Adcurve.taxonomies.findById(id)) {
        // If it's a taxonomy, append it
        var tax = new Adcurve.TaxonomiesItem(id, value, {type: 'rule'});
        that._item.taxonomies.push(tax.item());
      } else {
        // It is probably a category, append it to categories array
        that._item.rule_channel_category_ids = value;
        that._item.rule_pub_category_path = Adcurve.categories.categoryPathName(value[0]);
        that._item.channel_category_ids = value;
      }
    });
  }
  if(!this._item.manual_channel_category_ids) {
    this._item.manual_channel_category_ids = [];
  } else {
    this._item.channel_category_ids = this._item.manual_channel_category_ids;
    this._item.manual_pub_category_path = Adcurve.categories.categoryPathName(this._item.manual_channel_category_ids[0]);
  }

  if(!_.isEmpty(this._item.manual_taxonomies)) {
    _.each(this._item.manual_taxonomies, function(value, id){
      var data = {};
      // Fetch manual taxonomy metadata based on manual taxonomy id
      if (typeof that._item.manual_taxonomies_metadata !== "undefined" && typeof that._item.manual_taxonomies_metadata[id] !== "undefined") {
        data = that._item.manual_taxonomies_metadata[id];
        data.taxonomy_updated_at = moment(data.taxonomy_updated_at).format('MMMM Do YYYY, h:mm:ss a');
      }
      data.type = 'manual';
      // Create a new taxonomy item based on ID value and data
      var tax = new Adcurve.TaxonomiesItem(id, value[0], data);
      // Manual taxonomies overwrite the ones generated from rules
      var position = _.findIndex(that._item.taxonomies, function(taxonomy){ return taxonomy.id === id; });
      if (position !== -1){
        that._item.taxonomies[position] = tax.item();
      } else {
        that._item.taxonomies.push(tax.item());
      }
    });
    // remove the objects to save memory
    delete this._item.manual_taxonomies;
    delete this._item.manual_taxonomies_metadata;
  }
  if(!this._item.channel_category_ids) {
    this._item.channel_category_ids = [];
    this._item.pub_category_path = '';
  } else {
    this._item.pub_category_path = Adcurve.categories.categoryPathName(this._item.channel_category_ids[0]);
    if (this._item.pub_category_path === '') {
      this._item.channel_category_ids = [];
    } else {
      this.collection.appliedCategoriesPush(this._item.pub_category_path);
      if (Adcurve.adSettings.cant_change_category) {
        Adcurve.mappedShopCodes = Adcurve.mappedShopCodes ? Adcurve.mappedShopCodes : [];
        Adcurve.mappedShopCodes.push(this._item.shop_code);
      }
    }
  }
};
