/*global $ */
Adcurve.AdmarktConnection = function(authorizeUrl, redirectUri, saveOauthIdentityUrl){
  this.authorizeUrl = authorizeUrl;
  this.redirectUri = redirectUri;
  this.saveOauthIdentityUrl = saveOauthIdentityUrl;

  this.hideNextStepButton = function(){
    $('#form-connection #next-step').hide();
  };

  this.showConnectingLoader = function(){
    location.hash = 'connection'
    $('#disconnected .button').attr('disabled', true)
    $('#disconnected .not-loading').toggleClass('hidden');
    $('#disconnected .loading').toggleClass('hidden');
  };

  this.attachConnectionEvents = function(){
    this._attachEventForConnect();
    this._attachEventForDisconnect();
  };

  this._attachEventForConnect = function(){
    var self = this;
    $("#admarkt-connect").click(function(event) {
      event.preventDefault();
      location.href = self.authorizeUrl;
    });
  };

  this._attachEventForDisconnect = function(){
    var self = this;
    $("#admarkt-disconnect").click(function(event) {
      self._setOauthIdentityId(null);
      self.hideNextStepButton();
      return true;
    });
  }

  this.saveOauthDetails = function(code){
    $.ajax({
      type: 'POST',
      url: this.saveOauthIdentityUrl,
      data: { code: code, redirect_uri: this.redirectUri },
      dataType: 'json',
      context: this,
      success: function(response) {
        this._setOauthIdentityId(response.id);
        this._submitForm();
      },
      error: function(result) {
        console.log('storeAuthResult got error result', result);
      }
    });
  }

  this._submitForm = function(){
    $('form#form-connection, form#publisher_settings_form').submit();
  };

  this._setOauthIdentityId = function(id) {
    $('#cost_connection_oauth_identity_id').val(id);
  };
}
