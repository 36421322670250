/*global Adcurve, _, $ */
import EventBridge from "../../apps/product_management/services/EventBridge";

Adcurve.queryBuilder = (function () {
  var api = {},
      BUILDER = '#builder',
      defaultRules = [{
        id: 'product_name',
        operator: 'contains'
      }];

  api.init = function(options){
    api.build(options);
    setCallbacks();
    api.updateUI();
  };

  api.updateUI = function () {
    var applyFilterButton = new Adcurve.applyFilterButton($("#builder").find('.rules-group-body .rules-list'));
    applyFilterButton.render();
  };

  api.build = function(options){
    const defaultOperators = $.fn.queryBuilder.constructor.DEFAULTS.operators;

    $(BUILDER).queryBuilder({
      filters: options.filters,
      optgroups: {
        a_status: 'Status',
        g_information: 'Details',
        m_statistics: 'Analytics',
        x_extra: 'Extra'
      },
      rules: options.rules || defaultRules,
      conditions: ['AND'],
      allow_groups: false,
      allow_empty: true,
      display_empty_filter: false,
      operators: defaultOperators.concat([
        { type: 'is_manually_set', nb_inputs: 0, multiple: false, apply_to: ['string'] },
        { type: 'is_not_manually_set', nb_inputs: 0, multiple: false, apply_to: ['string'] },
        { type: 'is_manually_set_by_tip', nb_inputs: 0, multiple: false, apply_to: ['string'] }
      ]),
      icons: {
        add_rule: ''
      },
      sort_filters: Adcurve.sortFilters,
      mongoRuleOperators: {
        fieldToCompare: function(v) {
          return {
            'val': v,
            'op': v === null ? 'is_null' : (v === '' ? 'is_empty' : 'equal')
          };
        },
        $regex: function(v) {
          v = unEscapeMongoRegExp(v.$regex);
          if (v.slice(0, 4) == '^(?!' && v.slice(-5) == '$).*$') {
            return { 'val': v.slice(4, -5), 'op': 'not_equal' };
          } else if (v.slice(0, 4) == '^(?!' && v.slice(-1) == ')') {
            return { 'val': v.slice(4, -1), 'op': 'not_begins_with' };
          } else if (v.slice(0, 5) == '^((?!' && v.slice(-5) == ').)*$') {
            return { 'val': v.slice(5, -5), 'op': 'not_contains' };
          } else if (v.slice(0, 4) == '(?<!' && v.slice(-2) == ')$') {
            return { 'val': v.slice(4, -2), 'op': 'not_ends_with' };
          } else if (v.slice(0, 1) == '^' && v.slice(-1) == '$') {
            return { 'val': v.slice(1, -1), 'op': 'equal' };
          } else if (v.slice(-1) == '$') {
            return { 'val': v.slice(0, -1), 'op': 'ends_with' };
          } else if (v.slice(0, 1) == '^') {
            return { 'val': v.slice(1), 'op': 'begins_with' };
          } else {
            return { 'val': v, 'op': 'contains' };
          }
        }
      },
      lang: {
        add_rule: 'Add Filter',
        operators: {
          is_empty: "is not set",
          is_not_empty: "is set",
          is_manually_set: "is manually set",
          is_not_manually_set: "is set by rule",
          is_manually_set_by_tip: "is manually set by tip"
        }
      }
    });
  };

  api.updatePublisherCategory = function (categoryPath) {
    // TODO create and move module for appliedCategories
    var found = _.find(Adcurve.appliedCategories, function (obj) {
      return _.values(obj)[0] === categoryPath;
    });
    if (found) {
      return false;
    }
    var list = _.map(Adcurve.appliedCategories, function (obj) { return _.values(obj)[0]; });
    list.push(categoryPath);
    Adcurve.appliedCategories = _.map(list.sort(), function (item) {
      var values = {};
      values[item] = item;
      return values;
    });
    api.rerender({useDefault: true, filters: Adcurve.sidebarFilters()});
  };

  api.destroy = function(){
    $(BUILDER).queryBuilder('destroy');
  };

  api.getRules = function(){
    return $(BUILDER).queryBuilder('getRules');
  };

  api.exportFilters = function() {
    return $(BUILDER).queryBuilder('getMongo');
  };

  api.importFilters = function(rules){
    $(BUILDER).queryBuilder('setRulesFromMongo', JSON.parse(rules));
  };

  api.setRules = function(rules){
    $(BUILDER).queryBuilder('setRules', rules);
  };

  api.selectizeFilter = function(options) {
    var selectizeConfig = {
      valueGetter: function(rule) {
        var value = rule.$el.find('.rule-value-container .selectize-input .item').data('value');
        if (_.indexOf(Adcurve.comparableFieldsIDs(), value) !== -1) {
          rule.data = {fieldToCompare: value};
        } else {
          rule.data = undefined;
        }
        // Between operator
        if (rule.$el.find('.rule-value-container .selectize-input').length === 0) {
          value = [rule.$el.find('.rule-value-container input')[0].value,rule.$el.find('.rule-value-container input')[1].value];
        }

        return value;
      },
      valueSetter: function(rule, value) {
        var input = rule.$el.find('.rule-value-container input');
        if (rule.operator.type == 'between') {
          $(input[0]).val(value[0]);
          $(input[1]).val(value[1]);
        } else {
          input[0].selectize.addOption({id:value,name:value});
          input[0].selectize.addItem(value);
          input[0].selectize.setValue(value);
        }
      },
      plugin: 'selectize',
      plugin_config: {
        valueField: 'id',
        labelField: 'name',
        searchField: 'name',
        sortField: 'name',
        create: true,
        persist: false,
        maxItems: 1,
        placeholder: 'Type for values (i.e. 0.5)',
        options: Adcurve.comparableFields(options.id)
      }
    };
    if (options.validation !== undefined) {
      $.extend(
          options.validation,
          {
            callback: function(value, rule) {
              return _.indexOf(Adcurve.comparableFieldsIDs(), value) !== -1 || this.validateValueInternal(rule, value);
            }
          }
      );
    }

    return $.extend(selectizeConfig, options);
  };
  api.selectizeAggregatedFilter = function(options) {
    var selectizeConfig = {
      valueGetter: function(rule) {
        var value = rule.$el.find('.rule-value-container .selectize-input .item').data('value');
        if (_.indexOf(Adcurve.aggregatedComparableFieldsIDs(), value) !== -1) {
          rule.data = {fieldToCompare: value};
        } else {
          rule.data = undefined;
        }
        // Between operator
        if (rule.$el.find('.rule-value-container .selectize-input').length === 0) {
          value = [rule.$el.find('.rule-value-container input')[0].value,rule.$el.find('.rule-value-container input')[1].value];
        }

        return value;
      },
      valueSetter: function(rule, value) {
        var input = rule.$el.find('.rule-value-container input');
        if (rule.operator.type == 'between') {
          $(input[0]).val(value[0]);
          $(input[1]).val(value[1]);
        } else {
          input[0].selectize.addOption({id:value,name:value});
          input[0].selectize.addItem(value);
          input[0].selectize.setValue(value);
        }
      },
      plugin: 'selectize',
      plugin_config: {
        valueField: 'id',
        labelField: 'name',
        searchField: 'name',
        sortField: 'name',
        create: true,
        persist: false,
        maxItems: 1,
        placeholder: 'Type for values (i.e. 0.5)',
        options: Adcurve.aggregatedComparableFields(options.id)
      }
    };
    if (options.validation !== undefined) {
      $.extend(
          options.validation,
          {
            callback: function(value, rule) {
              return _.indexOf(Adcurve.aggregatedComparableFieldsIDs(), value) !== -1 || this.validateValueInternal(rule, value);
            }
          }
      );
    }

    return $.extend(selectizeConfig, options);
  };
  api.rerender = function(options) {
    // used when adding new publisher categories and switching tabs
    var applyFilterButton = new Adcurve.applyFilterButton($('#filters'));
    applyFilterButton.render();
    var rules;
    if (window.location.hash === "#notmapped"){
      options.useDefault = false;
      options.forceRules = [{
        id: 'pub_category_path',
        operator: 'is_empty'
      }];
    }
    if (options.useDefault === true) {
      rules = defaultRules;
    } else {
      if (typeof options.forceRules !== 'undefined') {
        rules = options.forceRules;
      } else if ($(BUILDER).queryBuilder('validate')) {
        rules = api.getRules();
      }
    }
    api.destroy();
    api.init({rules: rules, filters: options.filters});
    if ($(BUILDER).queryBuilder('validate') || $('#builder .rule-container').length === 0) {
      var result = $(BUILDER).queryBuilder('getRules');
      EventBridge.filterChanged(result);
    }
  };

  api.reInitialize = function(options){
    if($.isEmptyObject(options.rules)) {
      if ($.isEmptyObject(options.filters)) {
        options.filters = Adcurve.sidebarFilters();
        delete options.rules;
      } else {
        options.rules = [{
          id: 'costs',
          operator: 'less'
        }];
      }
      api.destroy();
      api.init(options);
      return;
    }
    if ($.isEmptyObject(options.filters)) {
      options.filters = Adcurve.sidebarFilters();
    }
    api.rerender(options);
    var element = $('.rule-operator-container > span:first');
    _.each(options.rules.rules, function(rle) {
      if (rle.label == 'Status' && element.text() === ' equal ') {
        element.remove();
      }
    });
  };

  api.reloadRules = function() {
    const rules = api.getRules();

    if ($(BUILDER).queryBuilder("validate")) {
      EventBridge.filterChanged(rules);
    }
  }

  function unEscapeMongoRegExp(str) {
    str = str.replace(/\\\-/g, '-');
    str = str.replace(/\\\[/g, '[');
    str = str.replace(/\\\]/g, ']');
    str = str.replace(/\\\//g, '/');
    str = str.replace(/\\\{/g, '{');
    str = str.replace(/\\\}/g, '}');
    str = str.replace(/\\\)/g, ')');
    str = str.replace(/\\\(/g, '(');
    str = str.replace(/\\\*/g, '*');
    str = str.replace(/\\\+/g, '+');
    str = str.replace(/\\\?/g, '?');
    str = str.replace(/\\\./g, '.');
    str = str.replace(/\\\\/g, '\\');
    str = str.replace(/\\\^/g, '^');
    str = str.replace(/\\\$/g, '$');
    str = str.replace(/\\\|/g, '|');
    return str;
  }
  function setCallbacks() {
    var applyStylesAfterUpdate = function(e, rule) {
      var element = $('#' + rule.id);
      var currentRule = element.find('.rule-value-container');
      if (currentRule.find('select').length > 0){
        currentRule.removeClass('input').addClass('selector');
      } else {
        currentRule.removeClass('selector').addClass('input');
      }
      if (rule && rule.operator && rule.operator.type === 'contains') {
        currentRule.find('input').attr("placeholder", "Separator: |");
      }
      if (element.find('.rule-operator-container span').text() === ' equal ') {
        element.find('.rule-operator-container span').remove();
      }
      element.find('select').addClass('chosen-select').chosen({
        allow_single_deselect: true,
        disable_search_threshold: 10,
        no_results_text: 'No results matched',
        width: '203px'
      });
      if (rule && rule.filter) {
        element.find('.rule-filter-container .chosen-select').val(rule.filter.id)
        element.find('.rule-filter-container .chosen-select').trigger("chosen:updated")
      }

      if ($('input.form-control.selectized').length > 0) {
        $('input.form-control.selectized')[0].selectize.on('change', function () {
          $('input.form-control.selectized').trigger('change');
        });
      }
    };
    var applyStylesAfterCreate = function(e, rule) {
      var element = $('#' + rule.id);
      applyStylesAfterUpdate(e, rule);
      element.find('.rule-filter-container, .rule-operator-container').addClass('selector');
      element.find('button').addClass('action delete').html('<i class="material-icons md-18">delete</i>');
      $(BUILDER).find('.chosen-select').chosen({
        allow_single_deselect: true,
        disable_search_threshold: 10,
        no_results_text: 'No results matched',
        width: '203px'
      });
      $('.rules-group-body').scrollTop($('.rules-group-body')[0].scrollHeight);
    };
    // Init
    $('.rule-filter-container, .rule-operator-container, .rule-value-container').addClass('selector').find('select').addClass('chosen-select');
    $('.rule-value-container').addClass('input');
    $(BUILDER).find('.rule-container button').addClass('action delete').html('<i class="material-icons md-18" title="Remove this filter">delete</i>');
    $('.chosen-select').chosen({
      allow_single_deselect: true,
      disable_search_threshold: 10,
      no_results_text: 'No results matched',
      width: '203px'
    });
    $('.rule-value-container input').attr("placeholder", "Separator: |");
    $(BUILDER).on('afterDeleteRule.queryBuilder afterUpdateRuleValue.queryBuilder afterUpdateRuleOperator.queryBuilder', function(e, rule) {
      if ($(BUILDER).queryBuilder('validate') || $('#builder .rule-container').length === 0) {
        var result = $(BUILDER).queryBuilder('getRules');
        EventBridge.filterChanged(result);
      }
    });
    // Set placeholder for multiple shop codes search
    $(BUILDER).on('afterUpdateRuleOperator.queryBuilder', function(e, rule) {
      var element = $('#' + rule.id);
      if (rule.operator.type === "equal" || rule.operator.type === "contains") {
        if (rule.filter.id === "shop_code") {
          element.find(".input input").attr("placeholder", "Separator: | or space");
        } else {
          element.find(".input input").attr("placeholder", "Separator: |");
        }
      } else {
        element.find(".input input").attr("placeholder", "");
      }
      applyStylesAfterUpdate(e, rule);
    });

    // Execute scripts when rulefilter is picked
    $(BUILDER).on('afterUpdateRuleFilter.queryBuilder', applyStylesAfterUpdate);

    // Execute scripts after rule is added
    // DO NOT put in single event
    $(BUILDER).on('afterApplyRuleFlags.queryBuilder', applyStylesAfterCreate);
    $(BUILDER).on('afterCreateRuleFilters.queryBuilder', applyStylesAfterCreate);

    // Update fields if Operator is set to between
    $(BUILDER).on('afterUpdateRuleOperator.queryBuilder', function(e, rule) {
      var element = $('#' + rule.id).find('.rule-value-container');
      if (rule.operator.type == 'between'){
        element.removeClass('selector').addClass('input range-input');
      } else {
        element.removeClass('range-input');
      }
    });
    // Fix for Selectize
    $(BUILDER).on('afterCreateRuleInput.queryBuilder afterUpdateRuleOperator.queryBuilder', function(e, rule) {
      $('.rule-operator-container .chosen-select').trigger("chosen:updated");
      var element = $('#' + rule.id).find('.rule-value-container');
      if (rule.filter.plugin == 'selectize') {
        if (rule.operator.type == 'between') {
          if (element.find('input')[0].selectize !== undefined) {
            element.find('input')[0].selectize.destroy();
            element.find('input')[1].selectize.destroy();
          }
        }
        rule.$el.find('.selectize-control').css('min-width', '203px');
      }
    });
  }

  return api;
}());
