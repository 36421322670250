/*global Adcurve, _, $, vex, I18n, Lazy */
Adcurve.clearMapping = function(taxonomyID, shopCodes, isCategory) {
  var shopCodesCount = shopCodes.length;
  var changedShopCodes = shopCodes.slice(0);
  var extraData = {
    taxonomy: taxonomyID,
    category: isCategory
  }
  Adcurve.mainComponent.updateProgressBar(shopCodesCount, 0);
  var deferreds = Adcurve.deferProductUpdates(shopCodes, shopCodesCount, Adcurve.mainComponent.props.taxonomyEndpoint, Adcurve.mainComponent, extraData, 'DELETE');
  $.when.apply(null, deferreds).done(function() {
    var newAdsData = Adcurve.mainComponent.state.adsData;

    _.each(newAdsData, function(element){
      if (changedShopCodes.indexOf(element.shop_code) != -1) {
        if (isCategory) {
          element.manual_channel_category_ids = [];
          element.manual_pub_category_path = '';
          element.channel_category_ids = element.rule_channel_category_ids;
          element.pub_category_path = Adcurve.categories.categoryPathName(element.channel_category_ids[0]);
        } else {
          element.taxonomies = Lazy(element.taxonomies).reject({id: taxonomyID.toString()}).toArray();

          // TODO: show rule taxonomy after a manual taxonomy was added - future ticket
          // if (typeof(element.rule_taxonomies[taxonomyID]) != 'undefined') {
          //   element.taxonomies[taxonomyID] = element.rule_taxonomies[taxonomyID];
          // }
        }
        if (!element.not_synced) {
          Adcurve.notSyncedProducts += 1;
        }
        element.not_synced = true;
      }
    });
    Adcurve.mainComponent.setState({total_number: shopCodesCount, current_number: shopCodesCount, adsData: newAdsData, selectedShopCodes: []});
    Adcurve.mainComponent.cleanupAfterProductUpdates();
  }).fail(Adcurve.requestFailed);
};
Adcurve.openRemoveManualVex = function(shopCodes, isCategory, taxonomyID, action, cancelAction) {
  vex.defaultOptions.className = 'vex-theme-plain';
  vex.dialog.buttons.YES.text = 'Yes';
  vex.dialog.buttons.YES.className = 'button full success';
  vex.dialog.buttons.NO.text = 'Cancel';
  vex.dialog.buttons.NO.className = 'button full error';


  vex.dialog.open({
    message: I18n.t('ads_management.clear_confirm_text_html'),
    callback: function (success) {
      if (success) {
        if (cancelAction) {
          cancelAction();
        }
        if (action === 'clear_manual_status') {
          Adcurve.mainComponent.updateSelectedProducts('clear_manual_status', shopCodes);
        } else if (action === 'clear_quarantine') {
          Adcurve.mainComponent.updateSelectedProducts('clear_quarantine', shopCodes);
        } else if (action === 'clear_manual_status_and_quarantine') {
          Adcurve.mainComponent.updateSelectedProducts('clear_manual_status', shopCodes);
          Adcurve.mainComponent.updateSelectedProducts('clear_quarantine', shopCodes);
        } else {
          Adcurve.clearMapping(taxonomyID, shopCodes, isCategory);
        }
      }
    }
  });
};
