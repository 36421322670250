/*global Adcurve, Lazy */
Adcurve.CategoriesCollection = function(data){
  this._collection = data;
  this._allowedCategories = [];
  if (this._collection.length === 0) {
    return;
  }
  var that = this;
  this.each(function(category){
    var path = that.buildCategoryPath(category, []).reverse().join(' / ');
    category.path = path;
    if (category.mapping_allowed === true) {
      that._allowedCategories.push(category);
    }
  });
};
Adcurve.CategoriesCollection.prototype.isEmpty = function(){
  return this._collection.length === 0;
};
Adcurve.CategoriesCollection.prototype.collection = function(){
  return this._collection;
};

Adcurve.CategoriesCollection.prototype.each = function(callback){
  Lazy(this._collection).each(function(item){
    callback(item);
  });
};
Adcurve.CategoriesCollection.prototype.findById = function(id){
  return Lazy(this._collection).find(function(t){ return t.id === parseInt(id); });
};

Adcurve.CategoriesCollection.prototype.findByString = function(search){
  var searchTerm = new RegExp(search, 'i');
  var results = Lazy(this.allowedCategories()).filter(function(item){
    var keywords = item.keywords.join(" ");
    return (searchTerm.test(item.path)) || (searchTerm.test(keywords));
  });
  if (search.length > 5) {
    return results.toArray()
  }
  return results.take(30).toArray();
};

Adcurve.CategoriesCollection.prototype.categoryPathName = function(id){
  var result = this.findById(id);
  if (typeof result !== "undefined") {
    return result.path;
  } else {
    return '';
  }
};

Adcurve.CategoriesCollection.prototype.allowedCategories = function(){
  return this._allowedCategories;
};

Adcurve.CategoriesCollection.prototype.buildCategoryPath = function (currentCategory, pathArray) {
  if (currentCategory.parent_id === 0) {
    pathArray.push(currentCategory.name);
    return pathArray;
  }
  var found = Lazy(this._collection).find(function(category) {
    return (category.id == currentCategory.parent_id);
  });
  if (typeof found !== "undefined") {
    pathArray.push(currentCategory.name);
    this.buildCategoryPath(found, pathArray);
  }
  return pathArray;
};
