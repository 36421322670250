if (typeof(Adcurve) === "undefined") {
  window.Adcurve = {};
}

import "./Ability";
import "./CategoriesCollection";
import "./ColumnsCollection";
import "./FieldsCollection";
import "./Filters";
import "./RulesCollection";
import "./RulesItem";
import "./StatisticsCollection";
import "./StatisticsItem";
import "./ShopFieldsCollection";
import "./TaxonomiesCollection";
import "./TaxonomiesItem";
import "./UsersCollection";
