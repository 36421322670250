if (typeof(Adcurve) === "undefined") {
  window.Adcurve = {};
}

import "./Admins";
import "./Affiliates";
import "./Alerts";
import "./Effects";
import "./ManualProductData";
import "./Orders";
import PublisherWizardForm from "./PublisherWizardForm";
import "./ShopSettings";
import "./PublisherSettings"
import Subscriptions from "./Subscription";
import "./TipDetails";
import "./Tips";
import "./UserSettings";

export default { PublisherWizardForm, Subscriptions };
