/*global $ */
window.bingConnect = (function() {
  var api = {},
  config = {};

  api.init = function(obj) {
    config = obj;
    $('#form-connection #next-step').hide();
    bindButtons();
  };

  api.processCallback = function(oauthIdentityId) {
    $('#setting-connection').click();
    $('#form-connection #next-step').show();
    setIdentityIdDOM(oauthIdentityId);
  };

  function bindButtons() {
    $(".bing-connect").click(function(event) {
      event.preventDefault();
      location.href = config.authorize_url;
    });

    $("#bing-disconnect").click(function(event) {
      setIdentityIdDOM(null);
      $('#form-connection #next-step').hide();
      return true;
    });
  }

  function setIdentityIdDOM(id) {
    $('#cost_connection_oauth_identity_id').val(id);
  }

  return api;
}());
