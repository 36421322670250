import React from "react";

const Attributes = ({data}) => {
    const string = data;

    if (typeof string === "undefined" || string === null) {
      return <div/>
    } else if (string.length > 10) {
      return (<div data-tooltip={string} data-position="left center" data-variation="small">{string.substring(0, 10)}...</div>);
    } else {
      return <div>{string}</div>
    }
  }

  export default Attributes;
