Adcurve.onLoad(function () {
  // generic support for public pages / layouts
  $('.help-icon').popup({
      position: 'bottom center',
      hoverable: true,
      delay: {
      show: 100,
      hide: 100
      }
  });
  $('.select').dropdown();
});
