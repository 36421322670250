/*globals Adcurve */
Adcurve.Ability = function(role){
  this.role = role;
  this._abilities = {};
  this.can = function(ability) {
    return (typeof this._abilities[ability] !== "undefined" && this._abilities[ability]);
  };
  this.cannot = function(ability) {
    return !this.can(ability);
  }
  if (role !== "Viewer" && role!== 'Channel admin') {
    this._abilities.manageAds = true;
    this._abilities.importFeeds = true;
  }
  if (role === 'System administrator') {
    this._abilities.manageDisabledAds = true;
  }

};
