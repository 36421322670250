/*global Adcurve, Lazy, CategoryBuilder, AdsAggregator */

Adcurve.StatisticsCollection = function(data){
  this._collection = data;
  this._stockStatuses = [];
  this._deliveryPeriods = [];
  this._appliedCategories = [];
  this._categoryBuilder = new CategoryBuilder();
  this._publisherCategoryBuilder = new PublisherCategoryBuilder();
  this._tempFamilyCodeArray = [];
  if (Adcurve.shopFields.hasField('family_code')) {
    this._familyCodeBuilder = new AdsAggregator(Adcurve.shopFields.byUserFieldName('family_code'));
  }
  this._brandBuilder = new AdsAggregator('brand');
  this.arrayToObject = function(item) {
    var values = {};
    values[item] = item;
    return values;
  };
};
Adcurve.StatisticsCollection.prototype.finishProcessing = function(){
  if (Adcurve.shopFields.hasField('family_code')) {
    this._tempFamilyCodeArray = this._familyCodeBuilder.formatted();
  }
  Adcurve.appliedCategories = Lazy(this.appliedCategories()).sort().uniq().map(this.arrayToObject).toArray();
  Adcurve.stockStatuses =  Lazy(this.stockStatuses()).sort().uniq().map(this.arrayToObject).toArray();
  Adcurve.deliveryPeriods = Lazy(this.deliveryPeriods()).sort().uniq().map(this.arrayToObject).toArray()
};

Adcurve.StatisticsCollection.prototype.push = function(e){
  if (e.shop_code) {
    var statistic = new Adcurve.StatisticsItem(e, this);
    this._categoryBuilder.push(statistic.item());
    this._publisherCategoryBuilder.push(statistic.item());
    if (Adcurve.shopFields.hasField('family_code')) {
      this._familyCodeBuilder.push(statistic.item());
    }
    this._brandBuilder.push(statistic.item());
    this._collection.push(statistic.item());
  }
};

Adcurve.StatisticsCollection.prototype.stockStatusesPush = function(data){
  this._stockStatuses.push(data);
};
Adcurve.StatisticsCollection.prototype.stockStatuses = function(){
  return this._stockStatuses;
};
Adcurve.StatisticsCollection.prototype.appliedCategoriesPush = function(data){
  this._appliedCategories.push(data);
};
Adcurve.StatisticsCollection.prototype.appliedCategories = function(){
  return this._appliedCategories;
};
Adcurve.StatisticsCollection.prototype.deliveryPeriodsPush = function(data){
  this._deliveryPeriods.push(data);
};
Adcurve.StatisticsCollection.prototype.deliveryPeriods = function(){
  return this._deliveryPeriods;
};

Adcurve.StatisticsCollection.prototype.collection = function(){
  return this._collection;
};
