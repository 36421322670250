/*global $, _, moment, Adcurve */
var AdcurveDatePicker;

(function ($, _) {
    'use strict';
    moment.updateLocale('en', {
      week: { dow: 1}
    });

    AdcurveDatePicker = (function () {
      var api = {
        // default callback when datepicker changed
        callback: function (startDate, endDate) {
          location.href = '?started_at=' + startDate + '&finished_at=' + endDate + location.hash;
        }
      };
      var currentMonth = moment().get('month');
      var yesterday = moment().subtract(1, "days");
      var dateRanges = [
        [moment().subtract(7, "days"), yesterday],
        [moment().subtract(14, "days"), yesterday],
        [moment().subtract(30, "days"), yesterday],
        [moment().subtract(60, "days"), yesterday],
        [moment().subtract(90, "days"), yesterday],
        [moment().isoWeekday(1), yesterday],
        [moment().set({'date': 1, 'month': currentMonth}), yesterday]
      ];
      api.init = function() {
        // 1. initialize jquery datepicker
        $("#report-range").daterangepicker({
          ranges: {
            "Last 7 Days": dateRanges[0],
            "Last 14 Days": dateRanges[1],
            "Last 30 Days": dateRanges[2],
            "Last 60 Days": dateRanges[3],
            "Last 90 Days": dateRanges[4],
            "This week": dateRanges[5],
            "This month": dateRanges[6]
          },
          startDate: moment().subtract(30, "days"),
          endDate: moment().subtract(1, "days"),
          showISOWeekNumbers: true,
          timePicker: false,
          applyClass: "button full success",
          cancelClass: "button error",
          autoApply: true,
          opens: 'left',
          maxDate: moment().subtract(0, "days")
        }, function(start, end) {
          api.updateDisplayLabel(start, end);
          api.callback(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
        });

        // 2. apply customization
        var dateRangePicker = $('.daterangepicker');
        dateRangePicker.hide();
        $('.daterangepicker i.fa').remove();
        $('.daterangepicker_input').addClass('input');
        $('.daterangepicker_input input').removeClass('input-mini');

        // 3. select
        var reportLabel = $('#reportrange-label');
        selectLayoutFromState(reportLabel.data('startDate'), reportLabel.data('endDate'));
        bindEvents();
      };

      api.updateDisplayLabel = function (start, end) {
        return $("#reportrange-label").html(start.format("MMM D, YYYY") + " - " + end.format("MMM D, YYYY"));
      };

      function bindEvents () {
        var dateRangePicker = $('.daterangepicker');
        // events
        dateRangePicker.on('click', '.customApplyBtn', function() {
          return dateRangePicker.find('.applyBtn').trigger('click');
        });
        dateRangePicker.on('click', '.customCancelBtn', function() {
          return dateRangePicker.find('.cancelBtn').trigger('click');
        });

        dateRangePicker.on('click', '.ranges li', function() {
          $('.daterangepicker .ranges li').removeClass('active');
          return $(this).addClass('active');
        });

        $('#report-range').on('apply.daterangepicker', function(_event, picker) {
          var startDate = picker.startDate.format('YYYY-MM-DD'),
              endDate = picker.endDate.format('YYYY-MM-DD');
          return api.callback(startDate, endDate);
        });
      }

      function selectLayoutFromState(daterangepickerStartDate, daterangepickerEndDate) {
        var startDate, endDate, resultIndex;

        if (daterangepickerStartDate && daterangepickerEndDate) {
          startDate = moment(daterangepickerStartDate, 'YYYY-MM-DD');
          endDate = moment(daterangepickerEndDate, 'YYYY-MM-DD');
          resultIndex = findRangeIndex([startDate, endDate]);
          // find range from server / front-end State
          if (resultIndex) {
            $("#report-range .ranges li:nth-child(" + resultIndex + ")").addClass('active');
          } else {
            $('#report-range').data('daterangepicker').setStartDate(startDate);
            $('#report-range').data('daterangepicker').setEndDate(endDate);
          }
          api.updateDisplayLabel(startDate, endDate);
        } else {
          // pick default range
          startDate = moment().subtract(30, "days");
          endDate = moment().subtract(1, "days");
          api.updateDisplayLabel(startDate, endDate);
        }
      }

      function findRangeIndex(givenRange) {
        var results = [];

        _.forEach(dateRanges, function(dateRange, index) {
          if (compareDateRanges(givenRange, dateRange)) {
            results.push(index);
          }
        });

        if (results.length > 0) {
          return results[0];
        } else {
          return -1;
        }
      }

      function compareDateRanges(dateRangeA, dateRangeB) {
        return dateRangeA.year === dateRangeB.year &&
                dateRangeA.month === dateRangeB.month &&
                dateRangeA.day === dateRangeB.day;
      }

      return api;
    }());

}($, _));

Adcurve.onLoad(AdcurveDatePicker.init);
