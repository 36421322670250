/*global Adcurve, _, Attributes, CurrencyValue */
import Attributes from "../apps/product_management/components/listing_components/Attributes";
import CurrencyValue from "../apps/product_management/components/listing_components/CurrencyValue";

Adcurve.ShopFieldsCollection = function(data){
  this._collection = data;
  this._currencyShopFieldsIDs = _.compact(_.map(this._collection, function(t) {
    if (_.indexOf(Adcurve.basicCurrencyRuleFields, t.rule) !== -1) {
      return t.system_name;
    }
  }));
  this._mapped = this.mapData(data, Adcurve.FLOAT_OPERATORS, Adcurve.STRING_OPERATORS);
  this._mappedForContent = this.mapData(data, Adcurve.CONTENT_FLOAT_OPERATORS, Adcurve.CONTENT_STRING_OPERATORS);
  this._shopFieldsIDs = _.map(this._collection, function(t) {
    return t.system_name;
  });
  this._shopFieldsNames = _.map(this._collection, function(t) {
    return Adcurve.humanize(t.user_field_name);
  });
  this._extraColumns = this.buildExtraColumns();
};

Adcurve.ShopFieldsCollection.prototype.collection = function(){
  return this._collection;
};
Adcurve.ShopFieldsCollection.prototype.mapped = function(){
  return this._mapped;
};
Adcurve.ShopFieldsCollection.prototype.mappedForContent = function(){
  return this._mappedForContent;
};
Adcurve.ShopFieldsCollection.prototype.IDs = function(){
  return this._shopFieldsIDs;
};
Adcurve.ShopFieldsCollection.prototype.currencyIDs = function(){
  return this._currencyShopFieldsIDs;
};
Adcurve.ShopFieldsCollection.prototype.Names = function(){
  return this._shopFieldsNames;
};
Adcurve.ShopFieldsCollection.prototype.extraColumns = function(){
  return this._extraColumns;
};
Adcurve.ShopFieldsCollection.prototype.byUserFieldName = function(name){
  var field = _.find(this._collection, function(e) {return name === e.user_field_name});
  if (typeof field !== "undefined") {
    return field.system_name;
  }
};
Adcurve.ShopFieldsCollection.prototype.hasField = function(name) {
  return typeof this.byUserFieldName(name) !== "undefined";
}
Adcurve.ShopFieldsCollection.prototype.mapData = function(data, floatOperators, stringOperators){
  return _.map(data, function(t) {
    var extraData = {};
    if (_.indexOf(Adcurve.basicRuleFields, t.rule) !== -1) {
      if (_.indexOf(Adcurve.basicCurrencyRuleFields, t.rule) !== -1) {
        extraData = { operators: floatOperators, type: "double", validation: {min: -10000000,step: 0.01} };
      } else {
        extraData = { operators: floatOperators, type: "integer", validation: {min: 0} };
      }
    } else {
      extraData = { operators: stringOperators, type: "string" };
    }
    return _.extend({ id: t.system_name, label: Adcurve.humanize(t.user_field_name), optgroup: 'x_extra'}, extraData);
  });
};
Adcurve.ShopFieldsCollection.prototype.buildExtraColumns = function() {
  return _.map(this._collection, function(t) {
    return { "columnName": t.system_name,
      "locked": false,
      "visible": true,
      "displayName": Adcurve.humanize(t.user_field_name),
      "cssClassName": "statistic",
      "customComponent": _.indexOf(Adcurve.basicCurrencyRuleFields, t.rule) !== -1 ? CurrencyValue : Attributes
    };
  }).concat([{ "columnName": "traffic",
      "locked": false,
      "visible": true,
      "displayName": "Visits",
      "cssClassName": "statistic short"
    },
    { "columnName": "products_in_stock",
      "locked": false,
      "visible": true,
      "displayName": "Stock",
      "cssClassName": "statistic short"
    },
    { "columnName": "stock_status",
      "locked": false,
      "visible": true,
      "displayName": "Stock status",
      "cssClassName": "statistic short"
    },
    { "columnName": "delivery_period",
      "locked": false,
      "visible": true,
      "displayName": "Delivery period",
      "cssClassName": "statistic"
    },
    { "columnName": "delivery_cost",
      "locked": false,
      "visible": true,
      "displayName": "Delivery Costs",
      "cssClassName": "statistic"
    },
    { "columnName": "product_ean",
      "locked": false,
      "visible": true,
      "displayName": "EAN",
      "cssClassName": "statistic"
    },
    { "columnName": "vendor_code",
      "locked": false,
      "visible": true,
      "displayName": "Vendor Code",
      "cssClassName": "statistic"
    }]);
};

Adcurve.comparableFields = function(currentFilter) {
  var fields = [
    {id: 'costs', name: 'Costs'},
    {id: 'ecpc', name: 'eCPC'},
    {id: 'profit', name: 'Profit'},
    {id: 'roi', name: 'ROI %'},
    {id: 'margin', name: 'Margin'},
    {id: 'product_margin', name: 'Product Margin'},
    {id: 'margin_ratio', name: 'Product Margin %'},
    {id: 'price_incl', name: 'Selling Price'},
    {id: 'price_excl', name: 'Selling Price Excl'},
    {id: 'floor_price', name: 'Floor Price'},
    {id: 'marked_price', name: 'Marked Price'},
    {id: 'revenue', name: 'Revenue'},
    {id: 'max_cpc', name: 'Max CPC'}
  ];

  _.each(Adcurve.shopFields.mapped(), function(f){
    if (_.indexOf(['double', 'integer'], f.type) !== -1) {
      fields = fields.concat({id: f.id, name: f.label});
    }
  });
  fields = _.filter(fields, function(element){
    return currentFilter != element.id;
  });

  return fields;
};

Adcurve.comparableFieldsIDs = function() {
  return Lazy(Adcurve.comparableFields()).pluck("id").union(Adcurve.aggregatedComparableFieldsIDs()).toArray();
};

Adcurve.aggregatedComparableFields = function(currentFilter) {
  var fields = [
    {id: 'costs', name: 'Costs'},
    {id: 'profit', name: 'Profit'},
    {id: 'margin', name: 'Margin'},
    {id: 'revenue', name: 'Revenue'},
  ];
  Adcurve.aggregationExtraFields.each(function(field){
    fields.push({ id: Adcurve.shopFields.byUserFieldName(field), name: Adcurve.humanize(field)});
  })
  fields = Lazy(fields).filter(function(element){
    return currentFilter != element.id;
  }).toArray();

  return fields;
};
Adcurve.aggregatedComparableFieldsIDs = function() {
  return Lazy(Adcurve.aggregatedComparableFields()).pluck("id").toArray();
};
