Adcurve.onLoad(function() {
  var closeAccordionSection = function() {
    $('.accordion .section-title').not('.prio').removeClass('active');
    $('.accordion .section-title').not('.prio').removeClass('selected');
    $('.accordion section ul').not('.prio').slideUp(300).removeClass('open');
    $('.accordion div ul').not('.prio').slideUp(300).removeClass('open');
  }
  $('.section-title').click(function(e) {
      // Grab current anchor value
      $('.accordion').find('.preview').removeClass('preview')
      var currentAttrValue = $(this).attr('href');
      if($(e.currentTarget).is('.selected')) {
        closeAccordionSection();
      } else {
        closeAccordionSection();
        // Add active class to section title
        $(this).addClass('selected');
        // Open up the hidden content panel
        $('.accordion ' + currentAttrValue).slideDown(300).addClass('open');
      }
      e.preventDefault();
  });
  // Opens up active section on page load
  //TODO make this more specific to certain DOM. too much side-effects
  if ($('.accordion').find('.active')) {
    var activePublisher = $('.accordion').find('.active');
    $(activePublisher).parent().slideDown(300).addClass('open prio').prev('h3').addClass('active prio');
  }
  if ($('.accordion section:nth-child(1)').hasClass('open')) {
    $('.accordion section:nth-child(2)').addClass('preview')
  }
});
