/*global _, $ */
Adcurve.Routes.generate = function(domElement, tabs) {
    var routes = {};
    _.each(tabs, function (tab) {
      var domSelector = $('#' + domElement + '-' + tab);
      routes[tab] = function() {
        domSelector.attr('checked', 'checked');
      };
      domSelector.click(function(){
        window.location.hash = tab;
      });
    });
  return routes;
};
