Adcurve.onLoad(function() {
  function determineMongoOperator(value, field) {
    if (value !== null && typeof value == 'object') {
      var subkeys = Object.keys(value);

      if (subkeys.length === 1) {
        return subkeys[0];
      }
      else {
        if (value.$gte !== undefined && value.$lte !== undefined) {
          return 'between';
        }
        if (value.$lt !== undefined && value.$gt !== undefined) {
          return 'not_between';
        }
        else if (value.$regex !== undefined) { // optional $options
          return '$regex';
        }
        else {
          return;
        }
      }
    }
    else {
      return 'eq';
    }
  };
  if ($.fn.queryBuilder !== undefined) {
    $.fn.queryBuilder.extend({
      /**
      * Get rules as MongoDB query
      * @throws UndefinedMongoConditionError, UndefinedMongoOperatorError
      * @param data {object} (optional) rules
      * @return {object}
      */
      getMongo: function(data) {
        data = (data === undefined) ? this.getRules() : data;

        var self = this;

        return (function parse(data) {
          if (!data.condition) {
            data.condition = self.settings.default_condition;
          }
          if (['AND', 'OR'].indexOf(data.condition.toUpperCase()) === -1) {
            $.fn.queryBuilder.constructor.utils.error('UndefinedMongoCondition', 'Unable to build MongoDB query with condition "{0}"', data.condition);
          }

          if (!data.rules) {
            return {};
          }

          var parts = [];

          data.rules.forEach(function(rule) {
            if (rule.rules && rule.rules.length > 0) {
              parts.push(parse(rule));
            }
            else {
              var mdb = self.settings.mongoOperators[rule.operator];
              var ope = self.getOperatorByType(rule.operator);
              var values = [];

              if (mdb === undefined) {
                $.fn.queryBuilder.constructor.utils.error('UndefinedMongoOperator', 'Unknown MongoDB operation for operator "{0}"', rule.operator);
              }

              if (ope.nb_inputs !== 0) {
                if (Adcurve.isFieldAsValueRule(rule)) {
                  values.push({fieldToCompare: rule.data.fieldToCompare});
                } else {
                  if (!(rule.value instanceof Array)) {
                    rule.value = [rule.value];
                  }

                  rule.value.forEach(function(v) {
                    values.push($.fn.queryBuilder.constructor.utils.changeType(v, rule.type, false));
                  });
                }
              }

              var part = {};
              part[rule.field] = mdb.call(self, values);
              parts.push(part);
            }
          });

          var res = {};
          if (parts.length > 0) {
            res['$' + data.condition.toLowerCase()] = parts;
          }
          return res;
        }(data));
      },

      /**
      * Convert MongoDB object to rules
      * @throws MongoParseError, UndefinedMongoConditionError, UndefinedMongoOperatorError
      * @param data {object} query object
      * @return {object}
      */
      getRulesFromMongo: function(data) {
        if (data === undefined || data === null) {
          return null;
        }

        var self = this;
        var conditions = {
          '$and': 'AND',
          '$or': 'OR'
        };

        return (function parse(data) {
          var topKeys = Object.keys(data);

          if (topKeys.length > 1) {
            $.fn.queryBuilder.constructor.utils.error('MongoParse', 'Invalid MongoDB query format');
          }
          if (!conditions[topKeys[0].toLowerCase()]) {
            $.fn.queryBuilder.constructor.utils.error('UndefinedMongoCondition', 'Unable to build MongoDB query with condition "{0}"', topKeys[0]);
          }

          var rules = data[topKeys[0]];
          var parts = [];

          rules.forEach(function(rule) {
            var keys = Object.keys(rule);

            if (conditions[keys[0].toLowerCase()]) {
              parts.push(parse(rule));
            }
            else {
              var field = keys[0];
              var value = rule[field];

              var operator = determineMongoOperator(value, field);
              if (operator === undefined) {
                $.fn.queryBuilder.constructor.utils.error('MongoParse', 'Invalid MongoDB query format');
              }

              var mdbrl = self.settings.mongoRuleOperators[operator];
              if (mdbrl === undefined) {
                $.fn.queryBuilder.constructor.utils.error('UndefinedMongoOperator', 'JSON Rule operation unknown for operator "{0}"', operator);
              }

              var opVal = mdbrl.call(self, value);
              parts.push({
                id: self.change('getMongoDBFieldID', field, value),
                field: field,
                operator: opVal.op,
                value: (opVal.val instanceof Object) && _.indexOf(Adcurve.comparableFieldsIDs(), opVal.val.fieldToCompare) !== -1 ? opVal.val.fieldToCompare : opVal.val
              });
            }
          });

          var res = {};
          if (parts.length > 0) {
            res.condition = conditions[topKeys[0].toLowerCase()];
            res.rules = parts;
          } else if (rules.length == 0) {
            res.rules = [];
          }
          return res;
        }(data));
      },

      /**
      * Set rules from MongoDB object
      * @param data {object}
      */
      setRulesFromMongo: function(data) {
        this.setRules(this.getRulesFromMongo(data));
      }
    });
  }
});
