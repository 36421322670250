/*global Adcurve, $, _ */
Adcurve.onLoad(function(){
  var columns = [{ "data": "date", "className": "info"}];
  var order = ['traffic', 'assists', 'assist_ratio', 'cex_amount', 'cmargin', 'costs', 'ecpc', 'profit', 'roi', 'croas', 'orders'];
  if (Adcurve.shopStatsDaily.length > 0) {
    var keys = Object.keys(Adcurve.shopStatsDaily[0]);
    _.each(order, function(column){
        if (_.indexOf(keys, column) !== -1) {
          if (column === "traffic" || column === "assists" || column === "orders") {
            columns.push({ "data": column, "render": Adcurve.FORMAT_DATATABLE_INTEGER, "className": "statistic",  "defaultContent": 0});
          } else if (column === "assist_ratio" ){
            columns.push({ "data": column, "render": Adcurve.FORMAT_DATATABLE_PERCENTAGE_WITHOUT_MULTIPLY, "className": "statistic",  "defaultContent": Adcurve.FORMAT_DATATABLE_PERCENTAGE_WITHOUT_MULTIPLY(0)});
          } else if (column !== "events" && column !== "date"){
            columns.push({ "data": column, "render": Adcurve.FORMAT_DATATABLE_CURRENCY, "className": "statistic",  "defaultContent": Adcurve.FORMAT_DATATABLE_CURRENCY(0)});
          }
        }
    });
  }
  $('#stats-daily-table').DataTable( {
    "paging": false,
    "pageLength": 2000,
    "order": [[0, "asc"]],
    "ordering": true,
    "info": false,
    "autoWidth": false,
    "bFilter": false,
    "scrollY": "532px",
    "scrollX": true,
    "scrollCollapse": true,
    "fixedColumns": false,
    "oLanguage": {
      "sLoadingRecords": "<div class='spinner'><div></div><div></div><div></div></div>"
    },
    "data": Adcurve.shopStatsDaily,
    "columns": columns
  });
}, '#stats-daily-table');
