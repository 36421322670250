/*global Adcurve, vex, $, I18n, FormTagValidator, StripeCheckout */
Adcurve.PaymentForm = function (config, stripeConfig) {
  var api = {},
      form,
      StripeHandle;
  api.openVex = function () {
    // stripe selected by default
    form = $('#payment_details_form');
    vex.defaultOptions.className = 'vex-theme-plain vex-payment-window';
    vex.dialog.buttons.YES.text = I18n.t('billing.stripe.save_button');
    vex.dialog.buttons.YES.className = 'button full success';
    vex.dialog.buttons.NO.text = 'Cancel';
    vex.dialog.buttons.NO.className = 'button full error';
    vex.dialog.open({
      message: (function () {
        if (config.edit) {
          return I18n.t('billing.edit_dialog_title');
        } else {
          return I18n.t('billing.upgrade_dialog_title');
        }
      }()),
      afterOpen: function () {
        $('.vex-dialog-buttons .success').attr('id', 'next-step');
        setupUI();
        form.appendTo('.vex-dialog-input');
        form.show();
        if (config.edit) {
          $('#next-step').html(I18n.t('billing.update_button'));
        }
      },
      beforeClose: function () {
        $(vex.getAllVexes()[0]).hide();
        form.appendTo('body');
        form.hide();
      },
      callback: function (success) {
        if (success) {
          $('#payment_details_form')[0].submit();
        }
      }
    });
  };

  api.openStripe = function () {
    var callbacks = {
      token: function (result) {
        $('input#stripe-token').val(result.id);
        $('#payment_details_form').submit(); // will go to vex callback
      },
    };
    if (StripeHandle !== undefined) {
      StripeHandle.open(callbacks);
    } else {
      $.getScript('https://checkout.stripe.com/checkout.js', function () {
        StripeHandle = StripeCheckout.configure(stripeConfig);
        StripeHandle.open(callbacks);
      });
    }
  };

  function setupUI () {
    $('#next-step').click(handleSubmitButton);
    $('#step-one button').click(function () {
      var type = $(this).data('payment-type');
      $('#step-one button').removeClass('active');
      $(this).addClass('active');
      $('input#billing_account_payment_type').val(type);
      switchPaymentType(type);
    });
    switchPaymentType(getPaymentType());
  }

  function handleSubmitButton (event) {
    if (getPaymentType() == config.paymentTypes.stripe) {
      if (FormTagValidator.valid($(form), event)) {
        api.openStripe();
      }
    } else {
      form.submit();
    }

    event.preventDefault();
    event.stopPropagation();
  }

  function getPaymentType() {
    return $('input#billing_account_payment_type').val();
  }

  function switchPaymentType (type) {
    var placeholder;
    if (type == config.paymentTypes.direct_debit) {
      $('#iban-field').show();
      placeholder = 'direct_debit';
    } else if (type == config.paymentTypes.invoice) {
      $('#iban-field').hide();
      placeholder = 'invoice';
    } else {
      $('#iban-field').hide();
      placeholder = 'stripe';
    }
    $('#terms label').html(I18n.t('billing.' + placeholder + '.terms_html'));
    $('p#intro').html(I18n.t('billing.' + placeholder + '.intro_html'));
    $('#next-step').html(I18n.t('billing.' + placeholder + '.save_button'));
  }
  return api;
};
