let ChartCookie = {};

ChartCookie.set = function(cname, cvalue) {
  var d = new Date();
  d.setTime(d.getTime() + 2592000000);
  var expires = "expires="+d.toUTCString();
  document.cookie = ChartCookie.shop_id + "|" + ChartCookie.publisher_id + cname + "=" + cvalue + ";" + expires + ";path=/";
};

ChartCookie.get = function(cname) {
  var name = ChartCookie.shop_id + "|" + ChartCookie.publisher_id + cname + "=";
  var ca = document.cookie.split(';');
  for(var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
        c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
    }
  }
  return "";
};

ChartCookie.check = function(cname) {
  return ChartCookie.get(cname) !== "";
};

window.ChartCookie = ChartCookie;

export default ChartCookie;
