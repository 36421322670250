/*globals Adcurve, I18n, _, AdcurveAbility */
Adcurve.taxonomyFormatted = function(element, forExport) {
  var taxonomies = element.taxonomies;
  var build = '', adcurveTaxonomyKey, adcurveTaxonomyValue, data_html;
  var appendMetadata = function(element, taxonomy) {
    var data = "";
    if (AdcurveAbility.can('manageAds') && taxonomy.taxonomy_set_from_tip) {
      data += "<p style='margin-left: 5px;margin-top: 15px'>- " + I18n.t('status.taxonomy_set_from_tip') + "</p>";
    }
    if (taxonomy.taxonomy_modified_by) {
      data += "<p style='margin-top: 15px'>" + I18n.t('status.last_modified_by', {name: Adcurve.users.findById(taxonomy.taxonomy_modified_by), date: taxonomy.taxonomy_updated_at }) + "</p>";
    }
    return data;
  };
  var icon = function(adcurveTaxonomyKey){
    if (adcurveTaxonomyKey.icon_name == 'human-male-female') {
      return "<i class='mdi mdi-human-male-female mdi-24px'></i>";
    }
    return "<i class='material-icons md-18'>" + adcurveTaxonomyKey.icon_name + "</i>";
  };

  // Removing elements from array using a backwards for loop
  for(var i = taxonomies.length -1; i >= 0; i--) {
    var taxonomy = taxonomies[i]
    adcurveTaxonomyKey = Adcurve.taxonomies.findById(taxonomy.id);
    if (adcurveTaxonomyKey) {
      adcurveTaxonomyValue = _.find(adcurveTaxonomyKey.categories, function(elem) { return elem.id == taxonomy.value; });
      if (adcurveTaxonomyValue) {
        data_html = "<h4>" + adcurveTaxonomyKey.name + "</h4>" + adcurveTaxonomyValue.path;
        if (forExport) {
          build += adcurveTaxonomyKey.name + ": " + adcurveTaxonomyValue.path + " -- ";
        } else if (taxonomy.type === 'rule'){
          build += "<div class='product-status taxonomy-tooltip' data-html=\"" + data_html + "\">" + icon(adcurveTaxonomyKey) + "</div>";
        } else {
          data_html += appendMetadata(element, taxonomy);
          build += "<div class='product-status taxonomy-tooltip' data-html=\"" + data_html + "\">" + icon(adcurveTaxonomyKey);
          if (AdcurveAbility.can('manageAds')){
            build += "<i class='mdi mdi-close-box mapping' data-id='"+element.shop_code+"' data-taxonomy-id='"+adcurveTaxonomyKey.id+"' title='Remove this mapping'></i>";
          }
          build += "</div>";
        }
      } else {
        // currently we only have 1 to 1 mapping, so we can delete the whole taxonomy key if we can't find the mapping
        taxonomies.splice(i, 1);
      }
    } else {
      // can't match the taxonomy key, we remove it from the element taxonomies object
      taxonomies.splice(i, 1);
    }
  }
  if (forExport) {
    // remove final -- from the taxonomies list
    return build.slice(0,-4);
  }
  return build.trim();
};
