/*global Adcurve, $ */
Adcurve.onLoad(function(){
  if($('#affiliates_table').length){
    var c = Lazy([
        { "data": "name", "render":  function ( data, type, row ) {
          if (data === 'Affiliate') {
            return 'Affiliate #' + row.external_id;
          } else {
            return data;
          }
        }, "className": "info"},
        { "data": "traffic", "className": "statistic"},
        { "data": "assists", "className": "statistic"},
        { "data": "cex_amount", "render": Adcurve.FORMAT_DATATABLE_CURRENCY, "className": "statistic", "type": "currency"},
        { "data": "cmargin", "render": Adcurve.FORMAT_DATATABLE_CURRENCY, "className": "statistic", "type": "currency"},
        { "data": "costs", "render": Adcurve.FORMAT_DATATABLE_CURRENCY, "className": "statistic", "type": "currency"},
        { "data": "profit", "render": Adcurve.FORMAT_DATATABLE_CURRENCY, "className": "statistic", "type": "currency"},
        { "data": "roi", "render": Adcurve.FORMAT_DATATABLE_PERCENTAGE, "className": "statistic", "type": "currency"},
        { "data": "ecpc", "render": Adcurve.FORMAT_DATATABLE_CURRENCY, "className": "statistic", "type": "currency"},
        // efee
        { "data": null, "render":  function ( data, type, row ) {
          if( row && row["costs"] > 0 && row["cex_amount"] > 0 ){
            return Adcurve.FORMAT_DATATABLE_PERCENTAGE( row["costs"] / row["cex_amount"] );
          }
          return Adcurve.FORMAT_DATATABLE_PERCENTAGE(0);
        }, "className": "statistic"},
        // max fee
        { "data": null, "render":  function ( data, type, row ) {
          if( row && row["cmargin"] > 0 && row["order_amount_excluding_tax"] > 0 ){
            return Adcurve.FORMAT_DATATABLE_PERCENTAGE( row["cmargin"] / row["order_amount_excluding_tax"] );
          }
          return Adcurve.FORMAT_DATATABLE_PERCENTAGE(0);
        }, "className": "statistic"},
    ]);
    if (Adcurve.channelUser) {
      c = c.reject({data: 'profit'}).reject({data: 'roi'}).reject({data: 'cmargin'});
    }
    $('#affiliates_table').DataTable( {
      "paging": true,
      "order": [[ 1, "desc" ]],
      "info": false,
      "searching": true,
      "bFilter": false,
      "autoWidth": false,
      "pageLength": 25,
      "scrollX": true,
      "ajax": Adcurve.affiliatesPath,
      "processing": true,
      "oLanguage": {
        "sSearch": "",
        'sSearchPlaceholder': "Search for affiliate",
        "sProcessing": "",
        "sLoadingRecords": "<div class='spinner'><div></div><div></div><div></div></div>"
      },
      "columns": c.toArray()
    });
  }
});
