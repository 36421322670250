/*global Adcurve, $ */
Adcurve.STATUS_VALUES = [
    {'active': 'Active'},
    {'inactive': 'Inactive'},
    {'enabled': 'Enabled'},
    {'disabled': 'Disabled'},
    {'manually_enabled': 'Manually Activated'},
    {'manually_disabled': 'Manually Deactivated'},
    {'manually_disabled_tip': 'Manually Deactivated By Tip'},
    {'content_rule_deactivation': 'Content Rule Error'},
  ];
Adcurve.STRING_OPERATORS = ["contains", "not_contains", "equal", "not_equal", "is_empty", "is_not_empty"];
Adcurve.FLOAT_OPERATORS = ["less", "less_or_equal", "greater", "greater_or_equal", "equal", "not_equal", "between", "is_empty", "is_not_empty"];
Adcurve.SELECT_OPERATORS = ["equal", "not_equal", "is_empty", "is_not_empty"];
Adcurve.PRICE_MODEL_SPECIFIC_FILTERS = ['costs','ecpc','max_cpc','profit','roi'];
Adcurve.CONTENT_STRING_OPERATORS = ["concat", "l_concat", "find_and_replace", "remove", "replace", "trim", "round", "title_case"];
Adcurve.CONTENT_FLOAT_OPERATORS = ["plus", "minus", "multiply", "divide", "replace", "remove", "round"];
Adcurve.CURRENCY_FIELDS = ['costs', 'ecpc', 'margin', "product_margin", 'max_cpc', 'profit', 'price_incl', 'price_excl', 'floor_price', 'revenue', 'order_amount_excluding_tax', "delivery_cost", 'marked_price'];
// TODO: remove CHANGEABLE_COLUMNS and replace with a calculation inside ColumnsCollection
Adcurve.CHANGEABLE_COLUMNS = ["revenue", "margin", "costs", "profit", "roi", "max_cpc", "ecpc", "products_in_stock", "stock_status", "delivery_period", "delivery_cost", "floor_price", "price_incl", "price_excl", "margin_ratio", "vendor_code", "product_ean", "order_amount_excluding_tax", "product_margin", "marked_price"];
Adcurve.ACTIVATE_FILTER = 41;
Adcurve.DEACTIVATE_FILTER = 42;
Adcurve.MAP_FILTER = 43;
Adcurve.CONTENT_FILTER = 44;
Adcurve.ACTIVATION_ACTIONS = [0, 1, 14, 15, 22, 23, 41, 42];
Adcurve.SYSTEM_ACTIONS = [47, 48];
Adcurve.CONTENT_ACTIONS = [2, 30, 4, 31, 32, 33, 5, 7, 39, 9, 10, 11, 12, 18, 19, 20, 21, 36, 37, 40, 44, 45, 46];
Adcurve.PERIOD_DEPENDENT = ['visits', 'profit', 'costs', 'ecpc', 'margin', 'max_cpc', 'revenue', 'roi'];
Adcurve.ACTIONS = { 'Activate': Adcurve.ACTIVATE_FILTER, 'Deactivate': Adcurve.DEACTIVATE_FILTER,
                    'Map': Adcurve.MAP_FILTER, 'change_content': Adcurve.CONTENT_FILTER };

Adcurve.FORMAT_DATATABLE_CURRENCY = function (data) {
  if (data === 'N/A') {
    return data;
  }
  if (data === null) {
    data = 0;
  }
  return $.fn.dataTable.render.number( Adcurve.shopOptions.currencyThousandsSeparator, Adcurve.shopOptions.currencyDecimalMark, 2, Adcurve.shopOptions.currencyUnit ).display(data);
};
Adcurve.FORMAT_DATATABLE_PERCENTAGE = function (data) {
  return $.fn.dataTable.render.number( Adcurve.shopOptions.currencyThousandsSeparator, Adcurve.shopOptions.currencyDecimalMark, 2, "" ).display(data * 100) +'%';
};
Adcurve.FORMAT_DATATABLE_PERCENTAGE_WITHOUT_MULTIPLY = function (data) {
  return $.fn.dataTable.render.number( Adcurve.shopOptions.currencyThousandsSeparator, Adcurve.shopOptions.currencyDecimalMark, 2, "" ).display(data) +'%';
};
Adcurve.FORMAT_DATATABLE_INTEGER = function (data) {
  return parseInt(data, 10);
};
