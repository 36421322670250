var ready = function() {
    $('#tip_product_list #select-all').change(function(){
      $("tbody .checkbox input").prop('checked', $(this).prop("checked"));
    });
    if($('#tip_product_list').length){
      $('#tip_product_list').DataTable( {
        "order": [[ 5, "desc" ]],
        "paging": false,
        "info": false,
        "filter": false,
        "autoWidth": false,
        "columnDefs": [
          { "orderable": false, "targets": [0,1,2] }
        ]
      });
      Adcurve.productAd.initRoutie();
    }

    $('#action-button').click(function(e){
      vex.defaultOptions.className = 'vex-theme-plain';
      vex.dialog.buttons.YES.text = 'OK';
      vex.dialog.buttons.YES.className = 'button success full';
      vex.dialog.buttons.NO.text = 'CANCEL';
      vex.dialog.buttons.NO.className = 'button error full';
      var totalCount = $(this).data('quarantine-count');
      var mappingTip = $(this).data('mapping-tip');
      var publisherName = $(this).data('publisher-name');
      var selectedProducts = $(this).parents('form').find('input[name^="shop_codes"]:checked').length;
      var quarantineCount = parseInt(totalCount) - parseInt(selectedProducts);
      e.preventDefault();
      var deactivateButton = $(this);
      vex.dialog.confirm({
        message: I18n.t('tips.popup_title'),
        input: '<div class="flash warning">' + (mappingTip !== undefined ? '' : I18n.t('tips.quarantine_message', { count: quarantineCount, publisher_name: publisherName })),
        callback: function(value){
          if(value) deactivateButton.parents('form').trigger('submit');
        }
      });
      return false;
    });
};


document.addEventListener("DOMContentLoaded", ready);
