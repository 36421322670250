const CustomFlowSuccessHandler = function(oldForm, newForm, stepHeader){
  this.$oldForm = $(oldForm);
  this.$newForm = $(newForm);
  this.$stepHeader = $(stepHeader);

  this.execute = function() {
    this.moveToNextStep();
    this.reinitializeDropdown();
    this.handleTermsAcceptedFieldError();
  };

  this.moveToNextStep = function(){
    this.$oldForm.replaceWith(this.$newForm);
  };

  this.reinitializeDropdown = function(){
    this.$newForm.find('.select').dropdown();
  };

  this.handleTermsAcceptedFieldError = function(){
    if(this.$newForm.find('#info_terms_accepted').parents('.field_with_errors').length > 0){
        this.$newForm.find('#info_terms_accepted').unwrap();
        this.$newForm.find('.checkbox-label').unwrap();
        this.$newForm.find('.checkbox-label').attr('style', 'color: #E74C3C !important;');
    }
  };
};

$(document).on('submit', '.custom_shop_form', function(event) {
  event.preventDefault();

  var $form = $(this);
  $form.find('#next-step').attr('disabled', true);
  var formData = new FormData($(this)[0]);
  $('#next-step i').removeClass('hidden');
  $.ajax({
    method: 'POST',
    data: formData,
    url: $form.attr('action'),
    cache: false,
    processData: false,
    contentType: false,
    success: function(response) {
      var $newForm = $(response.form);
      var successHandler = new CustomFlowSuccessHandler($form, $newForm, $(response.step_header));
      successHandler.execute();
      var formatter = Adcurve.formWithNumberFormat($('.custom_shop_form'));
      $('#next-step i').addClass('hidden');
      $newForm.enableClientSideValidations();
    }
  });
});

window.CustomFlowSuccessHandler = CustomFlowSuccessHandler;

export default CustomFlowSuccessHandler;
