/*global Adcurve, Lazy */
Adcurve.RulesCollection = function(data){
  this._collection = [];
  var that = this;
  Lazy(data).each(function(r) {
    var rule = new Adcurve.RulesItem(r);
    that._collection.push(rule);
  });
};

Adcurve.RulesCollection.prototype.all = function() {
  return this._collection;
};

Adcurve.RulesCollection.prototype.include = function(element) {
  var shouldShow = true;
  Lazy(this._collection).each(function(ruleItem) {
    shouldShow = ruleItem.compare(element, shouldShow);
  });
  return shouldShow;
};
