window.BeslistCpcNewConnection = function(authorizeURL, shopId, publisherId) {
  this.authorizeURL = authorizeURL;
  this.shopID = shopId;
  this.publisherID = publisherId;

  this.consumerKey = function(){
    return $('#cost_connection_consumer_key').val();
  };

  this.consumerSecret = function(){
    return $('#cost_connection_consumer_secret').val();
  };

  this.hideButton = function(){
    $('#form-connection #next-step').hide();
    $('#publisher_settings_form #save').hide();
  };

  this.attachEventHandlers = function(){
    this._attachClickEventForConnectButton();
    this._attachClickEventForDisconnectButton();
  };

  this.toggleSpinner = function(disable){
    $('#connected .button, #disconnected .button').attr('disabled', disable)
    $('#connected .not-loading, #disconnected .not-loading').toggleClass('hidden');
    $('#connected .loading, #disconnected .loading').toggleClass('hidden');
  };

  this._attachClickEventForConnectButton = function(){
    var self = this;
    $("#beslist-cpc-new-connect").click(function(e) {
      e.preventDefault();
      self._getRefreshToken();
    });
  };

  this._attachClickEventForDisconnectButton = function(){
    var self = this;
    $("#beslist-cpc-new-disconnect").click(function(event) {
      self.removeOauthIdentity()
      return true;
    });
  };

  this._getRefreshToken = function(){
    this.toggleSpinner(true);
    $.ajax({
      method: 'POST',
      url: '/shops/' + this.shopID + '/publishers/' + this.publisherID + '/beslist_oauths',
      dataType: 'json',
      context: this,
      data: this._data(),
      success: function(response) {
        this.toggleSpinner(false);
        if(response.error){
          $('.content').find('.flash.error').remove();
          $('.content').prepend(response.html);
        } else {
          console.log("response:", response);
          this.setOauthId(response.oauth_id);
          this.submitForm();
        }
      }
    });
  }

  this.setOauthId = function(id){
    $('#cost_connection_oauth_identity_id').val(id);
  };

  this.removeOauthIdentity = function(oauthID){
    this.toggleSpinner(true);
    $.ajax({
      method: 'DELETE',
      url: '/shops/' + this.shopID + '/publishers/' + this.publisherID + '/beslist_oauths',
      success: function(response) {
        location.reload();
      }
    });
  };

  this.submitForm = function(){
    $('form#form-connection, form#publisher_settings_form').submit();
  };

  this._data = function(response) {
    return {
      consumer_key: this.consumerKey(),
      consumer_secret: this.consumerSecret(),
    }
  };
}
