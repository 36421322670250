Adcurve.applyFilterButton = function(container) {
  this.container = container;
  this._cloneSelector = 'apply-filters';
  this._defaultID = 'apply-filters-default';

  this.render = function(){
    this._removePrevious();
    this._clone().appendTo(this.container);
  }

  this._clone = function(){
    return this._default().clone().attr('id', this._cloneSelector).removeClass('hidden');
  }

  this._removePrevious = function(){
    $('#' + this._cloneSelector).remove();
  }

  this._default = function() {
    return $("<a />", { class: 'button full success hidden', 'id': this._defaultID, text: 'Apply Filters' });
  }
}
