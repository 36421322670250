/*global Adcurve, $, _ */

import EventBridge from  "../../apps/product_management/services/EventBridge";

Adcurve.contentQueryBuilder = (function () {
  var api = {},
      BUILDER = '#content_builder',
      default_rules = [{
        id: 'brand',
        operator: 'concat'
      }];

  api.init = function(rules){
    api.build(rules);
    setCallbacks();
  };

  api.build = function(rules){
    $(BUILDER).queryBuilder({
      filters: Adcurve.contentSelectizedFilters(),
      rules: rules || default_rules,
      conditions: ['AND'],
      allow_groups: false,
      allow_empty: true,
      operators: [
        { type: 'concat', nb_inputs: 1, multiple: false, apply_to: ['string'] },
        { type: 'l_concat', nb_inputs: 1, multiple: false, apply_to: ['string'] },
        { type: 'find_and_replace', nb_inputs: 2, multiple: false, apply_to: ['string'] },
        { type: 'remove', nb_inputs: 0, multiple: false, apply_to: ['string'] },
        { type: 'replace', nb_inputs: 1, multiple: false, apply_to: ['string', 'number'] },
        { type: 'round', nb_inputs: 1, multiple: false, apply_to: ['number', 'string'] },
        { type: 'trim', nb_inputs: 0, multiple: false, apply_to: ['string'] },
        { type: 'plus', nb_inputs: 1, multiple: false, apply_to: ['number'] },
        { type: 'minus', nb_inputs: 1, multiple: false, apply_to: ['number'] },
        { type: 'multiply', nb_inputs: 1, multiple: false, apply_to: ['number'] },
        { type: 'divide', nb_inputs: 1, multiple: false, apply_to: ['number'] },
        { type: 'title_case', nb_inputs: 0, multiple: false, apply_to: ['string'] }
      ],
      icons: {
        add_rule: ''
      },
      lang: {
        add_rule: '',
        operators: api.operatorTranslations()
      },
      plugins: ['sortable']
    });
  };

  api.destroy = function(){
    $(BUILDER).queryBuilder('destroy');
  };

  api.getRules = function(){
    return $(BUILDER).queryBuilder('getRules');
  };

  api.operatorTranslations = function(){
    return {
      concat: "insert at the end",
      l_concat: "insert at the beginning",
      find_and_replace: "find & replace",
      title_case: "title case",
      remove: "remove",
      replace: "replace value with",
      trim: "trim",
      round: "round",
      plus: "+",
      minus: "-",
      multiply: "*",
      divide: "/"
    };
  };

  api.selectizeFilter = function(options) {
    var selectizeConfig = {
      valueGetter: function(rule) {
        var value = rule.$el.find('.rule-value-container .selectize-input .item').data('value');
        if (_.indexOf(Adcurve.comparableContentFieldsIDs(options), value) !== -1) {
          rule.data = {fieldToCompare: value};
        } else {
          rule.data = undefined;
        }
        // Between operator
        if (rule.$el.find('.rule-value-container .selectize-input').length === 0) {
          value = [rule.$el.find('.rule-value-container input')[0].value,rule.$el.find('.rule-value-container input')[1].value];
        }
        return (value === null || value === undefined || typeof(value) == 'object') ? value : value.toString();
      },
      valueSetter: function(rule, value) {
        var input = rule.$el.find('.rule-value-container input.form-control');
        var element = $('#' + rule.id).find('.rule-value-container');
        var selectize = element.find('input')[0].selectize;

        switch (rule.operator.type) {
          case 'find_and_replace':
            $(input[0]).val(value[0]);
            $(input[1]).val(value[1]);
            element.find('input')[0].selectize.destroy();
            element.find('input')[1].selectize.destroy();
            break;
          case 'multiply':
          case 'divide':
            selectize.clearOptions();
            break;
        }

        if (rule.operator.type !== 'find_and_replace') {
          input[0].selectize.addOption({id:value,label:value});
          input[0].selectize.addItem(value);
          input[0].selectize.setValue(value);
        }
      },
      plugin: 'selectize',
      plugin_config: {
        valueField: 'id',
        labelField: 'label',
        searchField: 'label',
        sortField: 'label',
        create: true,
        persist: false,
        maxItems: 1,
        placeholder: 'Type for values',
        lockOptgroupOrder: true,
        optgroups: [
          {value: 'g_information', label: 'Details', label_scientific: 'Details'},
          {value: 'm_statistics', label: 'Analytics', label_scientific: 'Analytics'},
          {value: 'x_extra', label: 'Extra', label_scientific: 'Extra'}
        ],
        options: Adcurve.contentComparableFilters(options)
      }
    };
    if (options.validation !== undefined) {
      $.extend(
        options.validation,
        {
          callback: function(value, rule) {
            return _.indexOf(Adcurve.comparableContentFieldsIDs(options), value) !== -1 || _.indexOf(["Floor", "Ceiling", "Truncate", "Nearest"], value) !== -1 || this.validateValueInternal(rule, value);
          }
        }
      );
    }

    return $.extend(selectizeConfig, options);
  };

  function setCallbacks() {
    var applyStylesAfterUpdate = function(e, rule) {
      var element = $('#' + rule.id);
      var currentRule = element.find('.rule-value-container');
      if (currentRule.find('select').length > 0){
        currentRule.removeClass('input').addClass('selector');
      } else {
        currentRule.removeClass('selector').addClass('input');
      }
      $(BUILDER).find('select').addClass('select').dropdown();
    };
    var applyStylesAfterCreate = function(e, rule) {
      var element = $('#' + rule.id);
      applyStylesAfterUpdate(e, rule);
      element.find('.rule-filter-container').find('select').val($('#field_to_change').val());
      element.find('.rule-filter-container').find('select').trigger('change');

      applyGenericStyles(element);
      $(BUILDER).find('.select').dropdown();
    };
    var applyGenericStyles = function(element) {
      element.find('.rule-filter-container').addClass('hidden');
      element.find('.rule-operator-container').addClass('selector').find('select').addClass('select');
      element.find('button').addClass('delete').html('<i class="material-icons md-18">delete</i>');
      element.find('.drag-handle').html('<i class="material-icons md-18" title="Move">open_with</i>');
      if (element.hasClass('rule-container')) {
        element.find('.drag-handle').appendTo(element.find(".rule-actions"));
      } else {
        element.find('.rule-container').each(function(index, container) {
          $(container).find('.drag-handle').appendTo($(container).find(".rule-actions"));
        });
      }
    };
    var applyEditStyles = function() {
      _.each($(BUILDER).queryBuilder('getModel').rules, function(rule) {
        fixReplaceOperator(rule);
      });
    };
    var fixReplaceOperator = function(rule) {
      var element = $('#' + rule.id).find('.rule-value-container');
      if (element.children().length === 2 && element.attr('style') !== 'display: none;'){
        element.removeClass('selector').addClass('input range-input');
      } else {
        element.removeClass('range-input');
      }
    };
    var validateRules = function() {
      $(BUILDER).queryBuilder('validate');
      EventBridge.contentFilterChanged($(BUILDER).queryBuilder('getRules'));
    };

    // Init
    applyGenericStyles($(BUILDER));
    applyEditStyles();
    $(BUILDER).find('.rule-value-container').addClass('input');
    $(BUILDER).find("[data-add='rule']").removeAttr('class');
    $(BUILDER).find("[data-add='rule']").addClass('button full success icon').html('<i class="material-icons md-24">add</i>');
    $(BUILDER).find(".rules-group-header").insertAfter($('.rules-group-body'));
    $(BUILDER).find('select').addClass('select').dropdown();

    $(BUILDER).on('afterDeleteRule.queryBuilder afterUpdateRuleValue.queryBuilder afterUpdateRuleOperator.queryBuilder afterMove.queryBuilder', validateRules);

    // Execute scripts when rulefilter is picked
    $(BUILDER).on('afterUpdateRuleOperator.queryBuilder', applyStylesAfterUpdate);
    $(BUILDER).on('afterUpdateRuleFilter.queryBuilder', applyStylesAfterUpdate);

    // Execute scripts after rule is added
    // DO NOT put in single event
    $(BUILDER).on('afterApplyRuleFlags.queryBuilder', applyStylesAfterCreate);
    $(BUILDER).on('afterCreateRuleFilters.queryBuilder', applyStylesAfterCreate);

    // Update fields if Operator is set to between
    $(BUILDER).on('afterUpdateRuleOperator.queryBuilder', function(e, rule) {
      fixReplaceOperator(rule);
    });
    // Fix for Selectize
    $(BUILDER).on('afterCreateRuleInput.queryBuilder afterUpdateRuleOperator.queryBuilder', function(e, rule) {
      var element = $('#' + rule.id).find('.rule-value-container');
      var selectize = element.find('input')[0].selectize;
      var scrollElement = document.getElementById('rule-create-window');
      scrollElement.scrollTop = scrollElement.scrollHeight - scrollElement.clientHeight;
      if (rule.filter.plugin === 'selectize' && selectize !== undefined) {
        switch (rule.operator.type) {
          case 'round':
            selectize.clearOptions();
            selectize.addOption([{id: "Floor", label: "Floor"},{id: "Ceiling", label: "Ceiling"},{id: "Truncate", label: "Truncate"},{id: "Nearest", label: "Nearest"}]);
            selectize.refreshOptions(false);
            break;
          case 'find_and_replace':
            element.find('input')[0].selectize.destroy();
            element.find('input')[1].selectize.destroy();
            break;
          case 'multiply':
          case 'divide':
            selectize.clearOptions();
            break;
          default:
            if (_.indexOf(Adcurve.CONTENT_FLOAT_OPERATORS, rule.operator.type) !== -1) {
              selectize.addOption(Adcurve.contentFloatFilters());
              selectize.refreshOptions(false);
            }
        }
      }
    });

    if ($('input.form-control.selectized').length > 0) {
      $('input.form-control.selectized')[0].selectize.on('change', function () {
        $('input.form-control.selectized').trigger('change');
      });
    }
  }

  return api;
}());
