ClientSideValidations.validators.remote['email_uniqueness'] = function(element, options) {
  if ($.ajax({
    url: '/validators/email_uniqueness',
    data: { email: element.val() },
    // async *must* be false
    async: false
  }).status == 422) { return options.message; }
}

ClientSideValidations.validators.local['percentage'] = function(element, options) {
  if (Adcurve.unformatNumber(element.val()) <= 0) {
    return options.message;
  }
}
