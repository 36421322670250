/*global Adcurve, $ */
Adcurve.Alerts = function(){
  this.container = $('.nav-item.alerts');
  this.fetchAlerts = function(){
    if(this.container.length === 0 || this._url() === null){
      return;
    }
    $.ajax({
      method: 'GET',
      url: this._url(),
      context: this,
      success: function(response) {
        this._render(response.html);
        this._attachCount();
        this._attachEvents();
        this._renderImportant();
      }
    });
  };

  this._url = function(){
    var data = this.container.data();
    if(data === null || typeof(data) === 'undefined'){
      return null;
    }
    if(data.publisherId === null || data.publisherId === '' || typeof(data.publisherId) === 'undefined') {
      return '/shops/' + data.shopId + '/alerts';
    }
    return '/shops/' + data.shopId + '/publishers/' + data.publisherId + '/alerts';
  };

  this._render = function(html){
    this.container.find('.container-placeholder').replaceWith(html);
  };

  this._attachCount = function(){
    var alerts = $('.alerts-list .list .item:not(.hidden)');
    var alertsCount = alerts.length;
    alerts.each(function() {
      if ($(this).css('display') === 'none' && $(this).is("#signed-out-flash"))  {
        alertsCount = alertsCount - 1;
      }
    });

    if (alertsCount !== 0) {
      $('.alerts .icon').css('display','flex');
      $('.alerts .badge').html(alertsCount);
    } else {
      $('.alerts .icon').css('display','none');
    }
  };

  this._attachEvents = function() {
    this._attachToggleClick();
    this._attachCloseEvent();
  };

  this._attachToggleClick = function() {
    $('.alerts .icon').click(function() {
      $('.alerts-list').toggle();
    });
  };

  this._attachCloseEvent = function(){
    var self = this;
    $('.alerts .item .close').on('click', function() {
      var alert = $(this).closest('.item');
      if(alert.attr('id') == 'no_traffic_alert'){
        var tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate()+1);
        tomorrow.setHours(0);
        tomorrow.setMinutes(0);
        tomorrow.setSeconds(0);
        $.cookie('no_traffic_alert_' + alert.data().publisherId, true,  { expires: tomorrow });
      }
      alert.transition('fade');
      setTimeout(self._attachCount, 500);
    });
  };

  this._renderImportant = function() {
    $('.alerts .item.alert').each(function() {
      var h4 = $(this).find('h4').clone();
      $('.important-alerts').append("<div class='item'><p>" + $(h4).text() + "</p></div>");
    });
    var delay = 3000;
    $($('.important-alerts .item').get().reverse()).each(function() {
      $(this).delay(delay).animate({
        opacity: 0
      }, 500);
      delay += 500;
    });
  };
};


Adcurve.onLoad(function() {
  if (!Adcurve.freeFeed) {
    var alerts = new Adcurve.Alerts();
    alerts.fetchAlerts();
    // For finishing a publisher wizard
    $(document).on('click', '#clear-wizard', function(){
      var data = alerts.container.data();
      $.ajax({
        method: 'POST',
        url: '/shops/' + data.shopId + '/publishers/' + data.publisherId + '/clear_wizard',
        context: this,
        success: function(response) {
          if (response.success){
            $('#resume-wizard').remove();
            $('.nav-item.alerts .badge').text(parseInt($('.nav-item.alerts .badge').text(), 10) - 1);
          }
        }
      });
    });
  }

  setTimeout(function() {
    $('.flash.success').fadeOut();
  }, 3000);
  setTimeout(function() {
    $('.flash.error').fadeOut();
  }, 3000);

  $('.app').not('.app .button')
    .mouseenter(function() {
      $(this).find('.status, .logo').animate({'margin-top': '-55px'}, 'fast');
      $(this).find('.head').animate({'height': '68px'}, 'fast');
      $(this).find('.description').animate({'padding-bottom': '8px'}, 'fast');
    })
    .mouseleave(function() {
      $(this).find('.status, .logo').animate({'margin-top': '0px'}, 'fast');
      $(this).find('.head').animate({'height': '110px'}, 'fast');
      $(this).find('.description').animate({'padding-bottom': '15px'}, 'fast');
    });
  $('#settings_sidebar a.active').parent().addClass('active');
  $('.nav-items .item a.active').parent().addClass('active');
  $('#adz-sidebar .reset').click(function() {
    $('.sections input:checkbox').removeAttr('checked');
  });

  if(($('.daterange:visible').length === 0) && ($('.sidebar:visible').length === 0)) {
    $('.content .header').css('width', '100%');
  }

  $('#chat-widget').click(function(){
    $('.lc-chat').toggleClass('hidden');
  });

  // Used for adding new user
  $('.table-action, .table-action-cancel').click(function() {
    $('.action-details').toggle();
    var $form = $('.action-details').find('form');

    if ($form.is(':visible')) {
      $form.resetClientSideValidations();
    } else {
      $form.disableClientSideValidations();
    }
  });

  $(document).on('submit', '#create_user_form', function() {
    $(this).find('button[type=submit]').attr('disabled', true);
  });

  $(document).on('click', '.autodisable-button', function() {
    $(this).attr('href', 'javascript: void(0)');
  });
});
