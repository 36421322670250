/* globals $, gapi, console, _ */
window.googleConnect = (function () {
  'use strict';
  var api = {},
      config = {},
      adwordsAccountId,
      initOpts;

  api.configure = function (obj) {
    config = obj;
    initOpts = {
      client_id: config.client_id,
      fetch_basic_profile: false,
      scope: config.scope
    };
  };

  api.init = function () {
    // hide the next button in the wizard
    $('#form-connection #next-step').hide();
    gapi.load('auth2', function() {
      if (gapi.auth2.getAuthInstance() === null) {
        gapi.auth2.init(initOpts).then(googleInit);
      } else {
        googleInit();
      }

    });
    adwordsAccountId = $('#cost_connection_google_adwords_client_id').val();
  };

  api.googleUser = function () {
    return gapi.auth2.getAuthInstance().currentUser.get();
  };

  api.accounts = function (callback) {
    $.ajax({
      type: 'GET',
      url: config.adwords_accounts_url + '&sub=' + api.googleUser().getId() + '&selected=' + adwordsAccountId,
      success: function(response) {
        callback(response);
      },
      error: function() {
        signOut(showControls);
      },
    });
  };

  api.displayInfo = function (callback) {
    gapi.client.load('plus','v1', function () {
      var request = gapi.client.plus.people.get({
        'userId': 'me'
      });

      request.execute(callback);
    });
  };

  api.oauthIdentity = function (sucessCallback, failCallback) {
    $.getJSON(config.search_oauth_identity + '&sub=' + api.googleUser().getId()).done(sucessCallback).fail(failCallback);
  };

  function googleInit() {
    bindButtons();

    if (api.googleUser().isSignedIn()) {
      api.oauthIdentity(function (response) {
        setIdentityIdDOM(response.id);
        showControls();
      }, function(response) {
        if(response.status == 404) {
          revoke(showControls);
        }
      });
    } else {
      showControls();
    }
  }

  function setIdentityIdDOM(id) {
    $('#cost_connection_oauth_identity_id').val(id);
  }

  function setAdwordsAccountDOM(id) {
    $('#cost_connection_google_adwords_client_id').val(id);
  }

  function storeAuthResult (authResult) {
    var payload = { code: authResult.code };
    $.ajax({
      type: 'POST',
      url: config.save_oauth_identity,
      dataType: 'json',
      success: function(result) {
        // Handle or verify the server response.
        setIdentityIdDOM(result.id);
        showControls();
      },
      error: function(result) {
        console.log('storeAuthResult got error result', result);
      },
      data: payload
    });
  }

  function bindButtons() {
    $("#google-connect").click(function (event) {
      event.preventDefault();
      signIn();
    });

    $("#google-signout").click(function (event) {
      event.preventDefault();
      $('#form-connection #next-step').hide();
      signOut(signIn);
    });

    $("#google-disconnect").click(function () {
      signOut();
      return true;
    });
  }

  function revoke(callback) {
    callback = callback | function () {};
    gapi.auth2.getAuthInstance().disconnect().then(showControls).then(callback);
  }

  function signIn () {
    gapi.auth2.getAuthInstance().grantOfflineAccess({
      scope: config.scope,
      prompt: 'select_account consent',
    }).then(storeAuthResult);
  }

  function signOut(callback) {
    callback = callback | function () {};
    setIdentityIdDOM(null);
    setAdwordsAccountDOM(null);
    gapi.auth2.getAuthInstance().signOut().then(showControls).then(callback);
  }

  function showControls() {
    if (api.googleUser().isSignedIn()) {
      $('#signed-out').hide();
      $('#signed-out-message').hide();
      $('#signed-out-flash').hide();
      $('#signed-in').show();
      $('#signed-in-message').show();
      renderDisplayInfo();
      renderAccounts();
    } else {
      $('#signed-in').hide();
      $('#signed-in-message').hide();
      $('#signed-out').show();
      $('#signed-out-message').show();
      $('#signed-out-flash').show();
    }
  }

  function renderAccounts() {
    var selector = $('#accounts-list'),
        errorText = $('#no-accounts');

    api.accounts(function(accounts) {
      $('#form-connection #next-step').show();
      var html = _.map(accounts, function(account){
        return "<option value=\"" + account.client_id + "\">" + (account.name === null ? "" : account.name) + "(" + account.client_id + ")</option>";
      }).join("\n");
      $('#accounts-list #cost_connection_google_adwords_client_id').html(html);
      switch(accounts.length){
        case 0:
          errorText.show();
          selector.hide();
          break;
        case 1:
          // $('#accounts-list .select').closest("form").submit();
          break;
        default:
          errorText.hide();
          selector.show();
          // sorry there is no way i can have a callback on the dom modifications above, semantic-ui is a bitch
          window.setTimeout(function () {
            $('#accounts-list .select').dropdown();
            selector.parent().show();
          }, 100);
      }
    });
  }

  function renderDisplayInfo() {
    api.displayInfo(function(result) {
      $('#google-name').text(result.displayName);
    });
  }

  return api;
}());
