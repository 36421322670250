if (typeof(Adcurve) === "undefined") {
  window.Adcurve = {};
}


import I18n from "./i18n.js.erb";
import "./Config";

import Common from "./common";
import Application from "./application";
import Public from "./public";
import "./fnAddTr"

const Helpers = { Common, Application, Public, I18n };

export default Helpers;
