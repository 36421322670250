/*global _, $, Adcurve */
Adcurve.Routes.ALL_CHANNELS = 0;
Adcurve.Routes.ALL_INDUSTRIES = 8;

Adcurve.Routes.availablePublishersRoutes = function(channelID, industryID) {
  var count = 0;
  var matchesFilter = function(element){
    return ($(element).data('industry') == industryID || industryID == Adcurve.Routes.ALL_INDUSTRIES) && ($(element).data('channel') == channelID || channelID == Adcurve.Routes.ALL_CHANNELS);
  };
  var showNoPublisherAlert = function(count) {
    if (count === 0) {
      $('#no-filtered-publishers').removeClass('hidden');
    } else {
      $('#no-filtered-publishers').addClass('hidden');
    }
  };
  var markActiveSidebarFilter = function() {
    $('.sidebar .filter-item').removeClass('active');
    $("#industry_" + industryID).parent().addClass('active');
    $("#channel_" + channelID).parent().addClass('active');
  };
  $('.available-publisher').hide();
  _.each($('.available-publisher'), function(element){
    if (matchesFilter(element)) {
      count++;
      setTimeout(function(){$(element).fadeIn(400);}, 50 * count);
    }
  });
  markActiveSidebarFilter();
  showNoPublisherAlert(count);
};

Adcurve.onLoad(function(){
  var createPath = function(channelID, industryID){
    return ['channel', channelID, 'industry', industryID].join('/');
  };

  $('.sidebar .filter-item a').click(function(e){
    e.preventDefault();
    var regex = /channel\/(\d*)\/industry\/(\d*)/i;
    var match = regex.exec(window.location.hash);
    var channelID = Adcurve.Routes.ALL_CHANNELS;
    var industryID = Adcurve.Routes.ALL_INDUSTRIES;
    if (match !== null) {
      channelID = match[1];
      industryID = match[2];
    }
    if (typeof $(e.target).data('industry') !== "undefined") {
      industryID = $(e.target).data('industry');
    } else {
      channelID = $(e.target).data('channel');
    }
    window.location.hash = createPath(channelID, industryID);
  });
});
