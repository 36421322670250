/*global Adcurve, _ */
Adcurve.UsersCollection = function(data){
  this.collection = data;
};

Adcurve.UsersCollection.prototype.findById = function(id){
  var item = _.find(this.collection, function(i) { return i.id === id; });
  return item !== undefined ? item.name : "Admin";
};

Adcurve.UsersCollection.prototype.isEmpty = function(){
  return this.collection.length === 0;
};
