/* global NProgress, $, I18n */
// Main application namespace

if (typeof window.Adcurve === 'undefined') {
  window.Adcurve = {};
}

// eslint-disable-next-line no-undef
Adcurve.Routes = {};

// Cookies
$.cookie.json = true;

// https://github.com/caarlos0-graveyard/nprogress-rails/blob/master/app/assets/javascripts/nprogress-turbolinks.js
$(() => {
  $(document).on('page:fetch turbolinks:request-start', () => {
    NProgress.start();
  });
  $(document).on('page:receive turbolinks:request-end', () => {
    NProgress.set(0.7);
  });
  $(document).on('page:change turbolinks:load', () => {
    NProgress.done();
  });
  $(document).on('page:restore turbolinks:request-end turbolinks:before-cache', () => {
    NProgress.remove();
  });
});

// NProgress
NProgress.configure({
  showSpinner: false,
  template: '<div class="custom-loader" role="bar"></div>',
});
I18n.defaultLocale = 'en';
I18n.locale = 'en';

// OnLoadWrapper
// eslint-disable-next-line no-undef
Adcurve.onLoad = (callback, selector) => {
  const runCallback = () => {
    if (typeof selector === 'undefined' || $(selector).length > 0) {
      callback();
    }
  };

  if (document.readyState === 'complete') {
    runCallback();
  } else {
    document.addEventListener('DOMContentLoaded', runCallback);
  }
};
