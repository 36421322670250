import EventEmitter from "wolfy87-eventemitter"

function EventBridge() {
  var ee = new EventEmitter();
  var ADS_CHANGED = 'ADS_CHANGED';
  var FILTER_CHANGED = 'FILTER_CHANGED';
  var CONTENT_FILTER_CHANGED = 'CONTENT_FILTER_CHANGED';
  var EDIT_RULE_CHANGED = 'EDIT_RULE_CHANGED';
  var VIEW_CHANGED = 'VIEW_CHANGED';

  return {
    listenOnFilterChanged: function(callback) {
      ee.on(FILTER_CHANGED, callback);
    },
    removeOnFilterChanged: function(callback) {
      ee.off(FILTER_CHANGED, callback);
    },
    filterChanged: function(newfilter) {
      ee.trigger(FILTER_CHANGED, [newfilter]);
    },
    listenOnAdsChanged: function(callback) {
      ee.on(ADS_CHANGED, callback);
    },
    removeOnAdsChanged: function(callback) {
      ee.off(ADS_CHANGED, callback);
    },
    adsChanged: function(adsStats) {
      ee.trigger(ADS_CHANGED, [adsStats]);
    },
    listenOnContentFilterChanged: function(callback) {
      ee.on(CONTENT_FILTER_CHANGED, callback);
    },
    removeOnContentFilterChanged: function(callback) {
      ee.off(CONTENT_FILTER_CHANGED, callback);
    },
    contentFilterChanged: function(newfilter) {
      ee.trigger(CONTENT_FILTER_CHANGED, [newfilter]);
    },
    listenOnRuleChanged: function(callback) {
      ee.on(EDIT_RULE_CHANGED, callback);
    },
    removeOnRuleChanged: function(callback) {
      ee.off(EDIT_RULE_CHANGED, callback);
    },
    ruleChanged: function(rule) {
      ee.trigger(EDIT_RULE_CHANGED, [rule]);
    },
    listenOnViewChanged: function(callback) {
      ee.on(VIEW_CHANGED, callback);
    },
    removeOnViewChanged: function(callback) {
      ee.off(VIEW_CHANGED, callback);
    },
    viewChanged: function(newfilter) {
      ee.trigger(VIEW_CHANGED, [newfilter]);
    }
  };
}

if (window.EventBridge === null || typeof(window.EventBridge) === "undefined") {
  window.EventBridge = EventBridge();
}

export default window.EventBridge;
