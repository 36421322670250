function PublisherCategoryBuilder() {
  this.categories = {};
  this.index = 0;
  this.push = function(element) {
    var accumulatedPath = [], categArray = '', that = this;
    if (typeof element.pub_category_path !== "undefined") {
      categArray = element.pub_category_path.split(" / ");
    }
    if (categArray.length === 0) {
      return;
    }
    _.each(categArray, function(categ, key){
      accumulatedPath.push(categ);
      that.categories[accumulatedPath.join(" / ")] = Adcurve.aggregateProperties(that.categories[accumulatedPath.join(" / ")], element);
      that.categories[accumulatedPath.join(" / ")].level = key;
      that.categories[accumulatedPath.join(" / ")].key = that.index;
      that.index += 1;
      that.categories[accumulatedPath.join(" / ")].parent = accumulatedPath.slice(0,-1).join(" / ");
    });
  };
}

export default PublisherCategoryBuilder;
