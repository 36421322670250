if (typeof(window.Adcurve) === "undefined") {
  window.Adcurve = {};
}

import "./Accordion"
import "./Adcurve.QueryBuilder"
import "./AdcurveDatepicker"
import AdsAggregator from "./AdsAggregator";
import "./AjaxDefer"
import "./Alerts"
import CategoryBuilder from "./CategoryBuilder";
import "./Constants"
import "./ContentFilters"
import "./ContentQueryBuilder"
import "./GoogleEvents"
import "./HelpIcon"
import "./ManualActions"
import "./MoneyFormat"
import "./OpenIntercom"
import "./PaymentForm"
import "./ProductAd"
import "./PublisherCategories"
import PublisherCategoryBuilder from "./PublisherCategoryBuilder";
import "./ProductInformation"
import "./PublisherTaxonomies"
import "./QueryBuilderOverrides"
import "./RuleDescription"
import "./SemanticScripts"
import "./Sidebar"
import "./SiderBarApplyFilters"
import "./Strings"
import "./TimeZones"
import "./TurbolinksProgress.css";
import Charts from "./charts"

const Common = { AdsAggregator, CategoryBuilder, PublisherCategoryBuilder, Charts };

window.AdsAggregator = AdsAggregator;
window.CategoryBuilder = CategoryBuilder;
window.PublisherCategoryBuilder = PublisherCategoryBuilder;

export default Common;
