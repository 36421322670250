/*global Adcurve, $ */
Adcurve.onLoad(function(){
  function close_sidebar() {
    $('#sidebar-toggle').removeClass('active');
    $('.sidebar').animate({
      right: '-274px'
    }, 300).addClass('closed');
    if($('.daterange:visible').length === 0) {
      $('.content .header').removeAttr('style');
    }
  }
  function open_sidebar() {
    $('#sidebar-toggle').addClass('active');
    $('.sidebar').animate({
      right: '0px'
    }, 300);
    setTimeout(function() {
      $('.sidebar').removeClass('closed');
    }, 300);
    if($('.daterange:visible').length === 0) {
      $('.content .header').css('width', '100%');
    }
  }
  $('#sidebar-toggle').click(function() {
    if($('#sidebar-toggle').is('.active')) {
      close_sidebar();
    } else {
      open_sidebar();
    }
  });

  function close_side_nav() {
    $('#nav-side-toggle').removeClass('active');
    $('.nav-side').animate({
      width: '0px',
      padding: '0px',
      display: 'none'
    }, 300);
    $('.nav-main').animate({
      width: 'calc(100% - 40px)'
    }, 300);
    setTimeout(function(){
      $('.nav-side').addClass('closed');
    }, 300);
  }
  function open_side_nav() {
    $('#nav-side-toggle').addClass('active');
    if ($(window).width() < 1440) {
      $('.nav-side').animate({
        width: '200px',
        padding: '0 15px',
        display: 'initial'
      }, 300);
    } else {
      $('.nav-side').animate({
        width: '220px',
        padding: '0 15px',
        display: 'initial'
      }, 300);
    }
    $('.nav-main').animate({
      width: 'calc(100% - 260px)'
    }, 300);
    setTimeout(function(){
      $('.nav-side').removeClass('closed');
    }, 100);
  }
  $('#nav-side-toggle').click(function() {
    if ($('#nav-side-toggle').is('.active')) {
      close_side_nav();
    }else {
      open_side_nav();
    }
  });

  // remove toggle if there is no sidebar
  if ($('.sidebar').length === 0) {
    $('#sidebar-toggle').hide();
  }

  // set toggle state on load
  if ($(window).width() < 1280 && $('.available_publishers.show').length === 0) {
    $('#sidebar-toggle').removeClass('active');
    $('.sidebar').css('right', '-274px')
  }

  // set toggle state on resize
  // $(window).resize(function() {
  //   if ($(window).width() < 1280) {
  //     $('#sidebar-toggle').removeClass('active');
  //   }
  // });
});
