function createAssistLine (data, options, resizing) {
  d3.select('#assists_graph').select('svg').remove();
  var assists = data.assists;
  var margin = {top: 45, right: 100, bottom: 15, left: 60},
      width = (document.getElementById('assists_graph').offsetWidth),
      height = 220 - margin.top - margin.bottom;

  var formatDate = d3.timeFormat("%b %d"),
      parseDate = d3.timeParse("%Y-%m-%d %H:%M %Z"),
      formatTime = d3.timeFormat("%H:%M");

  var placedOrder = parseDate(data.created_at);
  var attrPublisher = [];
  var assist_tooltip = d3.select('#assists_graph').append('div')
      .attr('class', 'assist_tooltip')
      .style('opacity', 0);
  assists.forEach(function(d) {
    if (resizing !== true) {
        d.created_at = parseDate(d.created_at);
    }
    if (d.assigned === true) {
      attrPublisher.push({publisher_id: d.publisher_id,
                          publisher: d.publisher,
                          created_at: d.created_at,
                          publisher_favicon: d.publisher_favicon});
    }
  });

  var x = d3.scaleTime().range([100, width - 60]),
      y = d3.scaleLinear().range([height, 0]);

  var xAxis = d3.axisTop(x).tickSize(height),
      yAxis = d3.axisLeft(y);

  var zoom = d3.zoom()
      .scaleExtent([1, 32])
      .translateExtent([[0, 0], [width, height]])
      .extent([[0, 0], [width, height]])
      // .x(x)
      .on('zoom', zoomed);

  var line = d3.line()
      .x(function(d) { return x(d.created_at); })
      .y(y(0.56));

  var svg = d3.select("#assists_graph").append("svg")
      .attr("width", width)
      .attr("height", height + margin.top + margin.bottom);

  var defs = svg.append('defs');
  assists.forEach(function(d) {
    defs.append('pattern')
        .data(assists)
        .attr('id', d.publisher_id)
        .attr('patternUnits', 'objectBoundingBox')
        .attr('width', 1)
        .attr('height', 1)
        .append('image')
        .attr('xlink:href', d.publisher_favicon)
        .attr('width', 24)
        .attr('height', 24);
  });
  svg.append('defs').append('clipPath')
      .attr('id', 'clip')
    .append('rect')
      .attr('width', width)
      .attr('height', height);

  var g = svg.append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

  x.domain(d3.extent(assists, function(d) { return d.created_at; }));
  y.domain([0, 2]);

  g.append('path')
      .datum(assists)
      .attr('class', 'line')
      .attr('d', line)
      .style("stroke", "#0096B5")
      .style("stroke-width", "1");

  g.append('g')
      .attr('class', 'axis axis--x')
      .attr('transform', 'translate(0,' + height + ')')
      .call(xAxis)
      .selectAll("text")
      .attr('x', '30')
      .attr('y', '-155');

  g.append('g')
      .attr('class', 'axis axis--y')
      .call(yAxis);

  var max = d3.max(assists, function(d) {return d.created_at;});
  var min = d3.min(assists, function(d) {return d.created_at;});

  svg.call(zoom).transition()
      .duration(1500);


  svg.append("svg:line")
      .attr('class', 'fakeline')
      .attr('x1', -100)
      .attr('x2', width + 160)
      .attr('y1', 115)
      .attr('y2', 115)
      .style("stroke", "#0096B5")
      .style("stroke-width", "4");

  svg.selectAll("dot")
      .data(assists)
      .enter().append("circle")
      .attr("class", "click")
      .attr("r", 12)
      .attr("cx", function(d) { return x(d.created_at); })
      .attr("cy", 115)
      .style('fill', function(d) {
        if (!_.isEmpty(d.publisher_favicon)) {
          return 'url(#' + d.publisher_id +')';
        } else {
          return 'white';
        }
      })
      .style("stroke", '#0096B5')
      .style("stroke-width", "4")
      .on('mouseover', function(d) {
        assist_tooltip.transition()
          .duration(200)
          .style('opacity', 0.9);
        assist_tooltip.html(
          '<div class="value">' + formatTime(d.created_at) + '</div>' +
          '<div class="publisher">' + d.publisher + '</div>')
          .style('left', (parseFloat(d3.select(this).attr('cx')) + 15) + 'px')
          .style('top', '165px');
      })
      .on('mouseout', function(d) {
        assist_tooltip.transition()
          .duration(500)
          .style('opacity', 0);
      });
      // REDRAW CIRCLE FOR ATTRIBUTED PUBLISHER (to make it on top)
      svg.append('circle')
          .data(attrPublisher)
          .attr('class', 'click attrClick')
          .attr('r', 12)
          .attr('cx', function(d) { return x(d.created_at); })
          .attr('cy', 115)
          .style('fill', function(d) {
            if (!_.isEmpty(d.publisher_favicon)) {
              return 'url(#' + d.publisher_id + ')';
            } else {
              return 'white';
            }
          })
          .style("stroke", '#2ECC71')
          .style("stroke-width", "4")
          .on('mouseover', function(d) {
            assist_tooltip.transition()
              .duration(200)
              .style('opacity', 0.9);
            assist_tooltip.html(
              '<div class="value">' + formatTime(d.created_at) + '</div>' +
              '<div class="publisher">' + d.publisher + '</div>')
              .style('left', (parseFloat(d3.select(this).attr('cx')) + 15) + 'px')
              .style('top', '168px');
          })
          .on('mouseout', function(d) {
            assist_tooltip.transition()
              .duration(500)
              .style('opacity', 0);
          });
      svg.append('circle')
          .attr('class', 'orderTime')
          .attr('r', 12)
          .attr('cx', x(placedOrder))
          // .attr('cy', 69)
          .attr('cy', function() {
            if (x(placedOrder) === x(attrPublisher[0].created_at)) {
              return 100;
            } else {
              return 69;
            }
          })
          .style('fill', 'white')
          .style("stroke", '#0096B5')
          .style("stroke-width", "4");
      svg.append('text')
          .attr('class', 'order-flag')
          .attr('font-family', 'Material Icons')
          .attr('font-size', 18 + 'px')
          .attr('cursor', 'default')
          .attr('fill', '#0096B5')
          .attr('x', x(placedOrder) - 9)
          .attr('y', function() {
            if (x(placedOrder) === x(attrPublisher[0].created_at)) {
              return 109;
            } else {
              return 78;
            }
          })
          .text('\uE153')
          .on('mouseover', function(d) {
            assist_tooltip.transition()
              .duration(200)
              .style('opacity', 0.9);
            assist_tooltip.html(
              '<div class="value">' + formatTime(placedOrder) + '</div>' +
              '<div class="publisher">Order Placed</div>')
              .style('left', (parseFloat(d3.select('.orderTime').attr('cx')) + 15) + 'px')
              .style('top', '168px');
          })
          .on('mouseout', function(d) {
            assist_tooltip.transition()
              .duration(500)
              .style('opacity', 0);
          });
      // Attributed Click Placeholder
      var attrPlaceholder = svg.append('g').attr('class', 'attrGroup');
        attrPlaceholder.append('rect')
          .data(attrPublisher)
          .attr('class', 'attr-bg')
          .attr('width', 110)
          .attr('height', 20)
          .attr('x', function(d) { return x(d.created_at) - 55; })
          .attr('y', -100)
          .attr('rx', 2)
          .attr('ry', 2)
          .style('fill', '#42D17F')
          .transition()
          .delay(500)
          .attr('y', 60);
        attrPlaceholder.append('polyline')
          .attr('class', 'attr-arrow')
          .data(attrPublisher)
          .attr('points', function(d) { return '0,0,0,0,0,0'; })
          .transition()
          .delay(500)
          .attr('points', function(d) { return createTriangle(x(d.created_at)); })
          .style('fill', '#42D17F');
        attrPlaceholder.append('text')
          .data(attrPublisher)
          .attr('class', 'attr-text')
          .style('fill', 'white')
          .attr('x', function(d) {return x(d.created_at) - 50; })
          .attr('y', 0)
          .text('ATTRIBUTED TO')
          .transition()
          .delay(500)
          .attr('y', 74);


  function zoomed() {
    var t = d3.event.transform, xt = t.rescaleX(x);
    g.select('.line').attr('d', line.x(function(d) { return xt(d.created_at); }));
    g.select('.axis--x').call(xAxis.scale(xt));
    svg.selectAll(".click")
        .attr('cx', function(d) {return xt(d.created_at); })
        .attr('cy', 115)
        .attr('r', 12);
    svg.select('.orderTime').attr('cx', xt(placedOrder));
    svg.select('.order-flag').attr('x', xt(placedOrder) - 9);
    svg.select('.attr-bg').attr('x', function(d) { return xt(d.created_at) - 55; });
    svg.select('.attr-arrow').attr('points', function(d) {
      return createTriangle(xt(d.created_at));
    });
    svg.select('.attr-text').attr('x', function(d) { return xt(d.created_at) - 50; });
  }
  function createTriangle(center) {
    return "" + (center - 10) + ",85," + center + ",92," + (center + 10) + ",85";
  }
}


window.createAssistLine = createAssistLine;
export default { createAssistLine };



// function createAssistLine (data, options, resizing) {
//   d3.select('#assists_graph').select('svg').remove();
//   var assists = data.assists;
//   var margin = {top: 45, right: 100, bottom: 15, left: 60},
//     width = (document.getElementById('assists_graph').offsetWidth) - 160,
//     fullwidth = document.getElementById('assists_graph').offsetWidth,
//     height = 220 - margin.top - margin.bottom,
//     formatDate = d3.timeFormat("%b %d"),
//     parseDate = d3.timeParse("%Y-%m-%d %H:%M %Z"),
//     formatTime = d3.timeFormat("%H:%M");
//     // customFormat = d3.timeFormat([
//     //   [".%L", function(d) { return d.getMilliseconds(); }],
//     //   ["%H:%M", function(d) { return d.getSeconds(); }],
//     //   ["%H:%M", function(d) { return d.getMinutes(); }],
//     //   ["%H:%M", function(d) { return d.getHours(); }],
//     //   ["%b %d", function(d) { return d.getDay() && d.getDate() != 1; }],
//     //   ["%b %d", function(d) { return d.getDate() != 1; }],
//     //   ["%B", function(d) { return d.getMonth(); }],
//     //   ["%Y", function() { return true; }]
//     // ]);
//
//   function draw() {
//     var t = d3.event.transform, xt = t.rescaleX(x);
//
//     svg.select('x.axis').call(xAxis.scale(xt));
//     //
//     // var t = zoom.translate(),
//     //     s = zoom.scale(),
//     //     tx = Math.min(0, Math.max(width * (1 - s), t[0])),
//     //     ty = Math.min(0, Math.max(height * (1 -s), t[1]));
//     // zoom.translate([tx, ty]);
//     svg.select("g.x.axis").call(xAxis);
//     svg.select("g.x.axis").selectAll("text")
//         .attr('x', '30')
//         .attr('y', '15');
//     svg.select("g.y.axis").call(yAxis);
//     svg.select("path.line").attr("d", line);
//     svg.selectAll(".click")
//         .attr('cx', function(d) {return x(d.created_at); })
//         .attr('cy', 69)
//         .attr('r', 12);
//     svg.select('.attr-bg').attr('x', function(d) { return x(d.created_at) - 55; });
//     svg.select('.attr-arrow').attr('points', function(d) {
//       return createTriangle(x(d.created_at));
//     });
//     svg.select('.attr-text').attr('x', function(d) { return x(d.created_at) - 50; });
//     svg.select('.orderTime').attr('cx', x(placedOrder));
//     svg.select('.order-flag').attr('x', x(placedOrder) - 9);
//   }
//
//   function createTriangle(center) {
//     return "" + (center - 10) + ",42," + center + ",47," + (center + 10) + ",42";
//   }
//   var placedOrder = parseDate(data.created_at);
//   var attrPublisher = [];
//   assists.forEach(function(d) {
//     if (resizing !== true) {
//         d.created_at = parseDate(d.created_at);
//     }
//     if (d.assigned === true) {
//       attrPublisher.push({publisher_id: d.publisher_id,
//                           publisher: d.publisher,
//                           created_at: d.created_at,
//                           publisher_favicon: d.publisher_favicon});
//     }
//   });
//
//   var max = d3.max(assists, function(d) {return d.created_at;});
//   var min = d3.min(assists, function(d) {return d.created_at;});
//   var x = d3.scaleTime()
//       .domain(d3.extent(assists, function(d) {return d.created_at; }))
//       .range([0, width])
//       .nice();
//
//   var y = d3.scaleLinear()
//       .domain([0, 2])
//       .range([height, 0]);
//
//   var xAxis = d3.axisTop()
//       .scale(x)
//       .tickSize(-height)
//       .ticks(5);
//
//   var yAxis = d3.axisLeft()
//       .scale(y);
//
//   var line = d3.line()
//       .x(function(d) { return x(d.created_at); })
//       .y(y(1.11));
//
//   var svg = d3.select("#assists_graph").append("svg")
//       .attr("width", width + margin.left + margin.right)
//       .attr("height", height + margin.top + margin.bottom)
//       .append("g")
//       .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
//
//   var zoom = d3.zoom()
//       .scaleExtent([1, Infinity])
//       .translateExtent([0, 0], [width, height])
//       .extent([[0, 0], [width, height]])
//       // .x(x)
//       .on('zoom', draw);
//
//   var assist_tooltip = d3.select('#assists_graph').append('div')
//       .attr('class', 'assist_tooltip')
//       .style('opacity', 0);
//
//   // zoom.x(x);
//
//   var defs = svg.append('defs');
//   assists.forEach(function(d) {
//     defs.append('pattern')
//         .data(assists)
//         .attr('id', d.publisher_id)
//         .attr('patternUnits', 'objectBoundingBox')
//         .attr('width', 1)
//         .attr('height', 1)
//         .append('image')
//         .attr('xlink:href', d.publisher_favicon)
//         .attr('width', 24)
//         .attr('height', 24);
//   });
//
//   svg.append("clipPath")
//       .attr("id", "clip")
//       .append("rect")
//       .attr("x", x(0))
//       .attr("y", y(1))
//       .attr("width", x(1) - x(0))
//       .attr("height", y(0) - y(1));
//
//   svg.append("g")
//       .attr("class", "x axis")
//       .attr("transform", "translate(0)")
//       .call(xAxis)
//       .selectAll("text")
//       .attr('x', '30')
//       .attr('y', '15');
//
//   svg.append('text')
//       .data(assists)
//       .attr('x', '-45')
//       .attr('y', '-20')
//       .attr("transform", "rotate(-90)")
//       .text(function(d) {
//         if (formatDate(min) == (formatDate(max))) {
//           return formatDate(d.created_at);
//         }
//       })
//       .style('fill', '#B8C4C8');
//
//   svg.append("g")
//       .attr("class", "y axis")
//       .call(yAxis)
//       .append("text")
//       .attr("transform", "rotate(-90)")
//       .attr("y", 6)
//       .attr("dy", ".71em");
//
//   svg.append("path")
//       .datum(assists)
//       .attr("class", "line")
//       .attr("clip-path", "url(#clip)")
//       .attr("d", line)
//       .style("stroke", "#0096B5")
//       .style("stroke-width", "1");
//
//   svg.append("svg:line")
//       .attr('class', 'fakeline')
//       .attr('x1', -100)
//       .attr('x2', fullwidth)
//       .attr('y1', 70)
//       .attr('y2', 70)
//       .style("stroke", "#0096B5")
//       .style("stroke-width", "4");
//
//   svg.append("rect")
//       .attr("class", "pane")
//       .attr("width", width)
//       .attr("height", height)
//       .call(zoom)
//       .transition()
//       .duration(1500)
//       .call(zoom.transform, d3.zoomIdentity
//           .scale(width / (x(max) - x(min)))
//           .translate(-x(min), 0));
//
//   svg.selectAll("dot")
//       .data(assists)
//       .enter().append("circle")
//       .attr("class", "click")
//       .attr("r", 12)
//       .attr("cx", function(d) { return x(d.created_at); })
//       .attr("cy", 69)
//       .style('fill', function(d) {
//         if (!_.isEmpty(d.publisher_favicon)) {
//           return 'url(#' + d.publisher_id +')';
//         } else {
//           return 'white';
//         }
//       })
//       .style("stroke", '#0096B5')
//       .style("stroke-width", "4")
//       .on('mouseover', function(d) {
//         assist_tooltip.transition()
//           .duration(200)
//           .style('opacity', 0.9);
//         assist_tooltip.html(
//           '<div class="value">' + formatTime(d.created_at) + '</div>' +
//           '<div class="publisher">' + d.publisher + '</div>')
//           .style('left', (parseFloat(d3.select(this).attr('cx')) + 15) + 'px')
//           .style('top', '165px');
//       })
//       .on('mouseout', function(d) {
//         assist_tooltip.transition()
//           .duration(500)
//           .style('opacity', 0);
//       });
//
//   // REDRAW CIRCLE FOR ATTRIBUTED PUBLISHER (to make it on top)
//   svg.append('circle')
//       .data(attrPublisher)
//       .attr('class', 'click attrClick')
//       .attr('r', 12)
//       .attr('cx', function(d) { return x(d.created_at); })
//       .attr('cy', 69)
//       .style('fill', function(d) {
//         if (!_.isEmpty(d.publisher_favicon)) {
//           return 'url(#' + d.publisher_id + ')';
//         } else {
//           return 'white';
//         }
//       })
//       .style("stroke", '#2ECC71')
//       .style("stroke-width", "4")
//       .on('mouseover', function(d) {
//         assist_tooltip.transition()
//           .duration(200)
//           .style('opacity', 0.9);
//         assist_tooltip.html(
//           '<div class="value">' + formatTime(d.created_at) + '</div>' +
//           '<div class="publisher">' + d.publisher + '</div>')
//           .style('left', (parseFloat(d3.select(this).attr('cx')) + 15) + 'px')
//           .style('top', '168px');
//       })
//       .on('mouseout', function(d) {
//         assist_tooltip.transition()
//           .duration(500)
//           .style('opacity', 0);
//       });
//   svg.append('circle')
//       .attr('class', 'orderTime')
//       .attr('r', 12)
//       .attr('cx', x(placedOrder))
//       // .attr('cy', 69)
//       .attr('cy', function() {
//         if (x(placedOrder) === x(attrPublisher[0].created_at)) {
//           return 100;
//         } else {
//           return 69;
//         }
//       })
//       .style('fill', 'white')
//       .style("stroke", '#0096B5')
//       .style("stroke-width", "4");
//   svg.append('text')
//       .attr('class', 'order-flag')
//       .attr('font-family', 'Material Icons')
//       .attr('font-size', 18 + 'px')
//       .attr('cursor', 'default')
//       .attr('fill', '#0096B5')
//       .attr('x', x(placedOrder) - 9)
//       .attr('y', function() {
//         if (x(placedOrder) === x(attrPublisher[0].created_at)) {
//           return 109;
//         } else {
//           return 78;
//         }
//       })
//       .text('\uE153')
//       .on('mouseover', function(d) {
//         assist_tooltip.transition()
//           .duration(200)
//           .style('opacity', 0.9);
//         assist_tooltip.html(
//           '<div class="value">' + formatTime(placedOrder) + '</div>' +
//           '<div class="publisher">Order Placed</div>')
//           .style('left', (parseFloat(d3.select('.orderTime').attr('cx')) + 15) + 'px')
//           .style('top', '168px');
//       })
//       .on('mouseout', function(d) {
//         assist_tooltip.transition()
//           .duration(500)
//           .style('opacity', 0);
//       });
//   // Attributed Click Placeholder
//   var attrPlaceholder = svg.append('g').attr('class', 'attrGroup');
//     attrPlaceholder.append('rect')
//       .data(attrPublisher)
//       .attr('class', 'attr-bg')
//       .attr('width', 110)
//       .attr('height', 20)
//       .attr('x', function(d) { return x(d.created_at) - 55; })
//       .attr('y', -100)
//       .attr('rx', 2)
//       .attr('ry', 2)
//       .style('fill', '#42D17F')
//       .transition()
//       .delay(500)
//       .attr('y', 20);
//     attrPlaceholder.append('polyline')
//       .attr('class', 'attr-arrow')
//       .data(attrPublisher)
//       .attr('points', function(d) { return '0,0,0,0,0,0'; })
//       .transition()
//       .delay(500)
//       .attr('points', function(d) { return createTriangle(x(d.created_at)); })
//       .style('fill', '#42D17F');
//     attrPlaceholder.append('text')
//       .data(attrPublisher)
//       .attr('class', 'attr-text')
//       .style('fill', 'white')
//       .attr('x', function(d) {return x(d.created_at) - 50; })
//       .attr('y', 0)
//       .text('ATTRIBUTED TO')
//       .transition()
//       .delay(500)
//       .attr('y', 34);
// }
