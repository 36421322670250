/*global $, numeral, Adcurve, _ */

import "numeral";

(function (options) {
  if (typeof options === 'undefined' || options === null) {
    options = {};
  }
  numeral.language('adcurve', {
      delimiters: {
          thousands: options.currencyThousandsSeparator,
          decimal: options.currencyDecimalMark
      },
      abbreviations: {
          thousand: 'k',
          million: 'm',
          billion: 'b',
          trillion: 't'
      },
      currency: {
          symbol: options.currencyUnit
      }
  });
  // switch between languages
  numeral.language('adcurve');

  var PERCENT_FORMAT = '0.00';
  var CURRENCY_FORMAT = '0,0.00';

  Adcurve.unformatNumber = function(value) {
    return numeral().unformat(value);
  };

  Adcurve.formatNumberInput = function (domQuery, format) {
    $(domQuery).val(formatValue($(domQuery).val(), format));
  };

  Adcurve.formWithNumberFormat = function (form) {
    var inputs = [];

    form.bind('submit', function () {
      _.each(inputs, function (input, i) {
        var el = $(input.query),
            v1 = el.val();
        if (typeof v1 !== "undefined") {
          el.val(formatValue(v1, input.format));
        }
      });
    });

    return {
      addInput: function (domQuery, format, allowZero) {
        if (bindNumberFormat(domQuery, format, allowZero)) {
          inputs.push({query: domQuery, format: format});
        }
      }
    };
  };

  function bindNumberFormat (domQuery, format, allowZero) {
    var el = $(domQuery);

    if (el && el.val() === undefined) {
      return false;
    }

    if (isEmpty(el.val(), allowZero)) {
      el.val('');
      var s = format == '%' ? PERCENT_FORMAT : CURRENCY_FORMAT;
      el.attr('placeholder', numeral(0).format(s));
    } else {
      // onload, money values are in cents (s2m specific)
      var val = format == '%' ? el.val() : el.val() / 100;
      el.val(formatValue(val, format));
    }

    el.bind('focus', function () {
      if (isEmpty($(this).val(), allowZero)) {
        $(this).val('');
        $(this).attr('placeholder', '');
      }
    });

    el.bind('blur', function () {
        var v1 = $(this).val();
        $(this).val(formatValue(v1, format));
    });

    return true;
  }

  function formatValue(v1, format) {
      var v2, found, precision;
      if (format == '%') {
          v2 = numeral(v1).format(PERCENT_FORMAT, roundFunction);
          return v2;
      }
      v2 = numeral(v1).format(CURRENCY_FORMAT, roundFunction);
      // scale decimal precision if format result is smaller
      if (v2.length < v1.length) {
          found = v1.match(/(.|,)[0-9]*$/);
          // if found char is decimal char
          if (found.length == 2 && found[1] == options.currencyDecimalMark) {
              precision = found[0].length -1;
              v2 = numeral(v1).format(buildFormat(precision), roundFunction);
          }
      }
      return v2;
  }

  // we provide custom roundFunction to prevent rounding.
  function roundFunction(x) { return x; }

  function buildFormat(precision) {
      var x = '0,0.';
      _.each(_.range(precision), function() { x += '0'; });
      return x;
  }
  // TODO: fix the allowZero param
  function isEmpty (v, allowZero) {
    return v === "" || ((typeof allowZero === "undefined") && Adcurve.unformatNumber(v) === 0);
  }
}(Adcurve.shopOptions));
