/*globals Adcurve, $ */
Adcurve.onLoad(function(){
  var uploadField = document.getElementById("manual_product_data_uploader_file");
  var uploadButton = $('#manual_product_data_uploader_button');
  uploadField.addEventListener("change", function() {
      var file = uploadField.files[0];
      if (file.type !== 'text/csv') {
        $('#shop-settings.content > .header').after("<div class='flash error'><i class='material-icons md-24 icon'>error</i>Please select a csv file.</div>");
        $('#shop-settings.content .flash.error').fadeOut(2000);
        uploadField.value = '';
        uploadButton.prop('disabled', true);
        uploadButton.addClass('disabled');
      } else {
        uploadButton.prop('disabled', false);
        uploadButton.removeClass('disabled');
      }
  }, false);
}, '#manual_product_data_uploader_file');
