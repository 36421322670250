/*global Adcurve, $, AdcurveDatePicker, routie */
(function () {
  'use strict';

  Adcurve.productAd = {};
  var ENTER_ESCAPE_KEY = 27;

  var hideProductDetails = function () {
    location.hash = '';
    $('#product-details-container').html('').css('display', 'none');
    $('.window-overlay').css('display', 'none');
    $('#tip-details, #order-details, #ad_management').css('display', 'block');
  };
  var renderRoot = function () {
    $('#product-details-container').html('').css('display', 'none');
    $('.window-overlay').css('display', 'none');
    $('#tip-details, #order-details, #ad_management').css('display', 'block');
  };
  var renderProductDetails = function (shopCode) {
    var shopId = $('#product-details-container').data().shopId,
        url = '/shops/' + shopId + '/shop_products/' + encodeURIComponent(shopCode),
        data = {
          publisher_id: $('#product-details-container').data().publisherId
        };

    if ($('#order-details').length > 0) {
      data.allowed_actions = true;
    }
    var loader = "<div class='spinner' style='margin-top: 100px'><div></div><div></div><div></div></div>"

    $('#tip-details, #order-details, #ad_management').css('display', 'none');
    $('#product-details-container').css('display', 'block');
    $('.window-overlay').css('display', 'block');
    $('#product-details-container').append(loader);

    $.ajax({
      url: url,
      data: data,
      success: function (data) {
        $('#product-details-container').html(data);
        $('.select').dropdown();
        initImageCarroussel();
        $('.variant-information section').each(function() {
          if ($(this).find('h4').length === 0) {
            var text = $(this).text().trim();
            $(this).html(text);
          }
        });
        $('.window-overlay, .close-overlay p, .close-overlay .material-icons').on('click', function() {
          hideProductDetails();
        });
        $('.variant-excerpt a').click(function() {
          var variant = $(this).parents('.variant');
          $(variant).toggleClass('open');
          $('.variant').not(variant).removeClass('open');
          $('.variant').not(variant).find('.variant-excerpt a').html('More information');

          if ($(this).html() === 'More information') {
          $(this).html('Less information');
          } else {
          $(this).html('More information');
          }
        });
      },
      error: function () {
        var html = "<div class='close-overlay'><i class='material-icons md-18 close-button'>clear</i><p>Close details</p></div><div class='flash error product-details-noload'>Failed to load data</div>";
        $('#product-details-container').html(html);
        $('.window-overlay, .close-overlay p, .close-overlay .material-icons').on('click', function() {
          hideProductDetails();
        });
      },
    });

    $(window).on('keydown', function () {
      if (ENTER_ESCAPE_KEY == event.keyCode) {
        hideProductDetails();
      }
    });
  };

  var moveLeft = function(currentTarget) {
    var carroussel_ul = $(currentTarget).next().find('ul');
    $(carroussel_ul).animate({
      left: + 180
    }, 200, function () {
      $(carroussel_ul).find('li:first-child').appendTo(carroussel_ul);
      $(carroussel_ul).css('left', '');
    });
  };

  var moveRight = function(currentTarget) {
    var carroussel_ul = $(currentTarget).next().next().find('ul');
    $(carroussel_ul).animate({
      left: - 180
    }, 200, function () {
      $(carroussel_ul).find('li:first-child').appendTo(carroussel_ul);
      $(carroussel_ul).css('left', '');
    });
  };

  var initImageCarroussel = function() {
    $('.image-carroussel').each(function() {
      var carroussel = this;
      var carroussel_ul = $(this).find('ul');
      if ($(carroussel).length > 0) {

        var el = $(carroussel).find('ul li'),
            slideCount = el.length,
            slideWidth = el.width(),
            slideHeight = el.height(),
            sliderUlWidth = slideCount * slideWidth;

        $(carroussel).css({ minWidth: slideWidth, minHeight: slideHeight, width: slideWidth });
        if (slideCount > 1) {
          $(carroussel).find('ul').css({ width: sliderUlWidth, marginLeft: - slideWidth });
          $(carroussel).find('ul li:last-child').prependTo(carroussel_ul);
        }
      }
    });
    $('a.control_prev').click(function (e) {
      e.preventDefault();
      moveLeft(e.currentTarget);
    });

    $('a.control_next').click(function (e) {
      e.preventDefault();
      moveRight(e.currentTarget);
    });
  };

  Adcurve.productAd.initRoutie = function(){
    routie({ '': function() { renderRoot(); },
             'product-details-*': function(shopCode) {
                renderProductDetails(shopCode);
              }
           });
    $(document.body).on('click', '.show-more', function (e) {
      e.preventDefault();
      $('.description.truncated').html($('.description.truncated').data().fullText);
    });

    $(document.body).on('mouseenter', '#productad-details .action', function() {
      var target = $(this).find('.action-details');
      $(target).removeClass('hidden');
      $(target).find('button[value=1]').data($(this).data());
    });

    $(document.body).on('mouseleave', '#productad-details .action', function() {
      var target = $(this).find('.action-details');
      $(target).addClass('hidden');
    });

    $(document.body).on('click', '.action-details button[value=1]', function () {
      var data = $(this).data();
      var endpoint = data.endpoint;
      var shopCodes = [data.shopCode];


      $.post(endpoint, {shop_codes: shopCodes}, function () {
        var flash = document.createElement("div");
        $(flash).addClass('flash success sticky').html('<i class="material-icons md-24 icon">check_circle</i>Successfully ' + data.label + 'd product <i class="material-icons md-24 dismiss close">clear</i>');
        $('.action-details').addClass('hidden');
        $('#productad-details > .header').after(flash);
        setTimeout(function() {
          $('.flash.success').fadeOut();
        }, 3000);
        $('.detail-status .material-icons').toggleClass('visibility');
        if ($('.detail-status .material-icons').html() == 'visibility') {
          $('.detail-status .material-icons').html('visibility_off');
        } else {
          $('.detail-status .material-icons').html('visibility');
        }
      });
    });
    $(document.body).on('click', '.action-details button[value=0]', function () {
      $('.action-details').addClass('hidden');
    });
  };
}());
