var ready = function() {
  $(document.body).on('change', '#analytics_name', function(e) {
    const el = $('#shop_ref_parameter');
    const form_value = $(this).val();

    switch (form_value) {
      case "ga":
        el.addClass('hidden');
        el.val('utm_source');
        break;
      case "other":
        el.removeClass('hidden');
        el.val(el.val() === 'utm_source' ? '' : el.data('origin-value'));
        el.focus();
        break;
      case "none":
        el.val('');
        el.addClass('hidden');
        break;
    }

    return true;
  });

  $('#next-step').parents('form').submit(function(e) {
    if($(this).find('.field_with_errors').length === 0) {
      $('#next-step').prop('disabled', true);
    }
  });

  $('#publisher-setup-tags a#skip-step').click(function (event) {
    event.preventDefault();
    $('#publisher_variable1_content').attr('disabled', true);
    $('#publisher_variable2_content').attr('disabled', true);
    $('#publisher-setup-tags form').submit();
  });
};

document.addEventListener("DOMContentLoaded", ready);
