/*global Adcurve, $ */
Adcurve.LIST_TO_ENABLE_DATA_TABLES_FOR = ['#tips_MANUAL_table', '#tips-table', '#tips_MAPPING_table',
                                          '#tips_ROI_table', '#tips_CONTENT_table', '#tips_MAX_CPC_table'];

Adcurve.createDataTable = function(selector) {
  $(selector).DataTable({
    "paging": false,
    "pageLength": 2000,
    "order": [[3, "desc"]],
    "ordering": true,
    "info": false,
    "autoWidth": false,
    "bFilter": false,
    "oLanguage": {
      "sLoadingRecords": "<div class='spinner'><div></div><div></div><div></div></div>"
    },
    "columnDefs": [{targets: 0, orderable: false},
                   {targets: 1, orderable: false},
                   {targets: 2, orderable: false},
                   {targets: 3, orderData: [3, 4], "render": Adcurve.FORMAT_DATATABLE_CURRENCY, "type": "currency"},
                   {targets: 4, orderData: 4}]
  });
}

Adcurve.onLoad(function(){
  $.each(Adcurve.LIST_TO_ENABLE_DATA_TABLES_FOR, function(i, item){
    if ($(item).length > 0) {
      Adcurve.createDataTable(item);
    }
  })

  if ($('#tips-table').length > 0){
    $('input[id^="select_all_"]').change(function() {
        var elements = $('input[id$="_select"]:visible').get()
        var checked = this.checked;
        for (var i = 0; i < elements.length; i++) {
          elements[i].checked = checked;
        }
        if(checked) {
          $('#execute-tips').removeClass('hidden');
        } else{
          $('#execute-tips').addClass('hidden');
        }
    });

    $('input[id$="_select"]').change(function() {
      var atLeastOneChecked = $('input[id$="_select"]:visible:checked').length > 0

      if(atLeastOneChecked) {
        $('#execute-tips').removeClass('hidden');
      } else {
        $('#execute-tips').addClass('hidden');
      }
    });

    $('#execute-tips').click(function(event){
      event.preventDefault();
      var tipIds = [];
      var deactivateButton = $(this);

      $('input[id$="_select"]:visible:checked').each(function(){
        tipIds.push($(this).data('id'));
      })

      vex.defaultOptions.className = 'vex-theme-plain bulk-tip-confirm';
      vex.dialog.buttons.YES.text = 'OK';
      vex.dialog.buttons.YES.className = 'button success full';
      vex.dialog.buttons.NO.text = 'CANCEL';
      vex.dialog.buttons.NO.className = 'button error full';

      vex.dialog.confirm({
        message: I18n.t('tips.popup_title'),
        input: 'You will execute ' + tipIds.length + ' tip(s)',
        callback: function(value){
          $('input[id="tip_ids"]').val(tipIds.join());
          $('input[id="selected_tab"]').val(location.hash.replace('#',''));
          if(value) deactivateButton.parents('form').trigger('submit');
        }
      })
      return false;
    })
  }
});
