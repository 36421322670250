
/*global d3, $, D3_LOCALE, I18n */
const preparseData = function(inputData, metrics) {
  var data = JSON.parse(JSON.stringify(inputData));
  var parseDate = d3.timeParse("%Y-%m-%d");
  // Parse the date for each value that comes from api
  data.forEach(function(d) {
    d.date = parseDate(d.date);
    metrics.forEach(function(metric){
      if (!d[metric]){
        d[metric] = 0.0;
      } else {
        d[metric] = parseFloat(d[metric]);
      }
    });
  });
  // Find date min and max and store the existing days
  var extent = d3.extent(data, function(d) { return d.date; }),
      // hash the existing days for easy lookup
      dateHash = data.reduce(function(agg, d) {
          agg[d.date] = true;
          return agg;
      }, {});
    // make even intervals
  d3.timeDays(extent[0], extent[1])
    // drop the existing ones
    .filter(function(date) {
        return !dateHash[date];
    })
    // and push them into the array
    .forEach(function(date) {
        var emptyRow = { date: date };
        // Set empty metrics to 0
        metrics.forEach(function(header) {
          emptyRow[header] = 0;
        });
        emptyRow.events = [];
        data.push(emptyRow);
    });
  // re-sort the data
  data.sort(function(a, b) { return d3.ascending(a.date, b.date); });
  return data;
};

const getTopThreeEvents = function(data){
  var events = [];
  data.forEach(function(d) {
    if (!_.isEmpty(d.events)) {
      d.events.sort(function(a,b){
        return b.estimated_gain - a.estimated_gain;
      });
      events.push({date: d.date, gain: d.events[0].estimated_gain});
    }
  });
  events.sort(function(a, b){
    return b.gain - a.gain;
  });
  return events.slice(0,3).map(function(event){
    return event.date;
  });

};

function createLineGraph(data, metricY0, metricY1, options, topThreeEvents, metricNames) {
  // CLEAR CHART IF THERE IS ONE
  d3.select('#line_graph').select('svg').remove();
  // CREATE OWN X AXIS ()
  function oddDaysOfYear(start, stop) {
    return d3.time.days(start, stop).filter(function(d) {
      return d3.time.dayOfYear(d) & 1;
    });
  }

  function roundValue(value, variable) {
    var abs_value;
    if (variable === 'diff') {
      abs_value = Math.abs(value[1] - value[0]);
    } else {
      abs_value = Math.abs(value);
    }
    var zeroCount = Math.floor( Math.log(abs_value) / Math.LN10 ) -1;

    var thousands = Math.pow(10, zeroCount);
    var topValue = Math.ceil(abs_value / thousands) / 5;
    var returnValue;
    if (variable === 'max' && value > 0) {
      returnValue = Math.ceil(topValue) * 5 * thousands;
    } else if (variable === 'max' && value < 0) {
      returnValue = '-' + Math.ceil(topValue) * 5 * thousands;
    } else if (variable === 'min' && value >= 0) {
      returnValue = 0;
    } else if (variable === 'min' && value < 0) {
      returnValue = '-' + Math.ceil(topValue) * 5 * thousands;
    } else if (variable === 'diff') {
      returnValue = Math.ceil(topValue) * 5 * thousands;
    }
    if (isNaN(returnValue)){
      returnValue = 1;
    }
    return returnValue;
  }

  function createTickValues(rangeValue, maxValue) {
      var tick = rangeValue / 5.0;
      var tickValues = [5, 4, 3, 2, 1, 0];
      tickValues.forEach(function(value, index) {
        tickValues[index] = maxValue - (value * tick);
      });
      return tickValues;
  }

  function calculateYAxes(metricY0, metricY1) {
    // Get rounded min and max values
    // create tick values (first check if diff between min and max is dividable by 5 for ticks)
    var axisY0 = createTickValues(roundValue([minY0, maxY0], 'diff'), maxY0);
    var axisY1 = createTickValues(roundValue([minY1, maxY1], 'diff'), maxY1);
    return [axisY0, axisY1];
  }

  function makeReadable(value, metric) {
    if (metric == 'orders' || metric == 'traffic' || metric == 'assists') {
      return shopLocale.format("d")(value);
    } else if (metric == 'assist_ratio'){
      return shopLocale.format(",.2f")(value) + '%';
    } else {
      return shopLocale.format("$,d")(value);
    }
  }

  function formatAxis(tick, metric, ticks) {
    var prefix = d3.format('.2s');
    var maxValue;
    if (Math.abs(ticks[0]) > Math.abs(ticks[5])) {
      maxValue = Math.abs(ticks[0]);
    } else {
      maxValue = Math.abs(ticks[5]);
    }
    if (metric === 'assist_ratio') {
      return Math.floor(tick) + '%';
    } else if (metric === 'orders' || metric === 'traffic' || metric === 'assists') {
      return prefix(tick);
    } else {
      // console.log(tick);
      if (tick === 0) {
        return 0;
      } else if (maxValue <= 10) {
        if (tick < 1) {
          return shopLocale.format("$,.2r")(tick);
        } else {
          return shopLocale.format("$,.1")(tick);
        }
      } else if (maxValue <= 100) {
        return shopLocale.format("$,.2")(tick);
      } else if (maxValue <= 1000) {
        return shopLocale.format("$,.3")(tick);
      } else if (maxValue <= 10000) {
        return shopLocale.format("$,.2s")(tick);
      } else if (maxValue <= 100000) {
        if (tick < 10000) {
          return shopLocale.format("$,.1s")(tick);
        } else {
          return shopLocale.format("$,.2s")(tick);
        }
      } else if (maxValue <= 1000000) {
        return shopLocale.format("$,.3s")(tick);
      } else {
        return shopLocale.format("$,.2s")(tick);
      }
    }
  }

  function createEvents(array) {
    var eventTooltip = '<h4>Events</h4>';
    array.forEach(function(item){
      if (item.estimated_gain > '0' ) {
        eventTooltip += '<div class="tooltip-event">' +
          '<div class="event-type">' + item.product_count + ' ' + I18n.t('events.' + item.event_type) + '</div>' +
          '<div class="event-gain"> with estimated gain ' + makeReadable(item.estimated_gain)  + '</div>' +
          '</div><hr/>';
      } else {
        eventTooltip += '<div class="tooltip-event">' +
          '<div class="event-type">' + item.product_count + ' ' + I18n.t('events.' + item.event_type) + '</div>' +
          '</div><hr/>';
      }
    });
    return eventTooltip;
  }

  var shopLocale = D3_LOCALE(options);
  // SETUP
  var line_graph = document.getElementById('line_graph');
  var width, height;
  var offsetGraph;
  if (line_graph !== null) {
    width = line_graph.offsetWidth;
    offsetGraph = width + $('#comparison-graph').offset().left;
  }
  var margin = {top: 30, right: 0, bottom: 30, left: 0},
      tempHeight = width / 2.5 - margin.top - margin.bottom;
      height = '';
      if (tempHeight > 400) {
        height = 400;
      } else {
        height = tempHeight;
      }

  var formatTime = d3.timeFormat('%b %e');
  var formatTimeTooltip = d3.timeFormat('%A / %b %e, %Y');

  var minY0 = roundValue(d3.min(data, function(d) { return d[metricY0];}), 'min');
  var maxY0 = roundValue(d3.max(data, function(d) { return d[metricY0];}), 'max');
  var minY1 = roundValue(d3.min(data, function(d) { return d[metricY1];}), 'min');
  var maxY1 = roundValue(d3.max(data, function(d) { return d[metricY1];}), 'max');

  // SETUP FOR X AXIS
  var start, end;
  if (data.length > 0) {
    start = data[0].date;
    end = data[data.length-1].date;
  } else {
    start = new Date();
    end = new Date();
    start.setDate(start.getDate() - 1);
    end.setDate(end.getDate() - 30);
  }

  // AXIS BLOCK
  // X AXIS
  var x = d3.scaleTime()
      .range([75, (width - 75)])
      .domain(d3.extent(data, function(d) { return d.date; }));
  var xAxis = d3.axisBottom()
      .scale(x)
      .tickSize(-height)
      .tickFormat(formatTime)
      .ticks(10)
      .tickPadding(10);
  // Y AXIS
  var axesY = calculateYAxes(metricY0, metricY1);
  var y0 = d3.scaleLinear()
      .domain(d3.extent(axesY[0], function(a) { return a; }))
      .range([height, 0]);
  var yAxisLeft = d3.axisLeft()
      .scale(y0)
      .tickPadding(15)
      .tickSize(-width)
      .tickValues(axesY[0])
      .ticks(5)
      .tickFormat(function (d) {
        return formatAxis(d, metricY0, axesY[0]);
      });
  var y1 = d3.scaleLinear()
      .domain(d3.extent(axesY[1], function(a) { return a; }))
      .range([height, 0]);
  var yAxisRight = d3.axisRight()
      .scale(y1)
      .tickPadding(15)
      .tickSize(-width)
      .tickValues(axesY[1])
      .ticks(5)
      .tickFormat(function (d) {
        return formatAxis(d, metricY1, axesY[1]);
      });

  // CREATE GRAPH
  var svg = d3.select("#line_graph").append("svg")
              .attr("width", width + margin.left + margin.right)
              .attr("height", height + margin.top + margin.bottom)
              .append("g")
              .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

  // CREATE GRAPH LINES/PATHS
  var line = d3.line()
               .x(function(d) { return x(d.date); })
               .y(function(d) { return y0(d[metricY0]); });
  var area = d3.area()
                .x(function(d) { return x(d.date); })
                .y0(height)
                .y1(function(d) { return y0(d[metricY0]);});
  var line2 = d3.line()
                .x(function(d) {return x(d.date); })
                .y(function(d) {return y1(d[metricY1]); });
  var path = svg.append("path")
                .attr("d", line(data))
                .attr("class", "line")
                .style("stroke", "rgb(103,121,156)");
  svg.append('path')
    .datum(data)
    .attr("class", "area")
    .attr("d", area)
    .style("fill", "rgba(103,121,156, 0.2)");
  var path2 = svg.append("path")
                 .attr("d", line2(data))
                 .attr("class", "line")
                 .style("stroke", "rgb(39,48,66)");

  // CREATE BACKGROUND X-AXIS
  // svg.append("rect")
  //    .attr("class", "x-axisbar")
  //    .attr("height", "30")
  //    .attr("width", "100%")
  //    .attr("y", "365");


  // CREATE X AXIS
  svg.append("g")
     .attr("class", "x axis")
     .attr("transform", "translate(0," + height + ")")
     .call(xAxis)
     .selectAll("text")
     .style("fill", "#0E445F");

  // if last xaxis value falls of screen, correct it.
  if (line_graph !== null) {
    if (d3.select('.x').selectAll('.tick')._groups[0].length > 0) {
      var lastXValue = d3.select('.tick:last-of-type').attr("transform");
      if (lastXValue >= width) {
        d3.select('.tick:last-of-type').select('text').attr('x', '-30');
      }
    }
  }


  // CREATE Y AXES
  svg.append("g")
     .attr("class", "y axis left-axis")
     .call(yAxisLeft)
     .selectAll("text")
     .attr('x', '15')
     .attr('y', '-10')
     .style("text-anchor", "start")
     .style("fill", "#67799C");


  svg.append("g")
     .attr("class", "y axis right-axis")
     .attr("transform", "translate(" + width + " ,0)")
     .call(yAxisRight)
     .selectAll("text")
     .attr('x', '-15')
     .attr('y', '-10')
     .style("text-anchor", "end")
     .style("fill", "#273042");

  // DOTS + TOOLTIP + EVENTS(maybe some refactoring)
  var div = d3.select("#comparison-graph").append("div")
              .attr("class", "chart-tooltip")
              .style("opacity", 0);

  svg.selectAll("dot")
      .data(data)
      .enter().append("circle")
      .attr("r", 5)
      .attr("cx", function(d) { return x(d.date); })
      .attr("cy", function(d) { return y0(d[metricY0]); })
      .style("fill", "white")
      .style("stroke", "#0096B5")
      .style("stroke-width", "3")
      .style("opacity", function(d) {
        if (topThreeEvents.indexOf(d.date) >= 0 ) {
          return '1';
        } else {
          return '0';
        }
      })
      .on("mouseover", function(d) {
        div.transition()
            .duration(200)
            .style("opacity", '.9');
        div.html(
            '<div class="date">' + formatTimeTooltip(d.date) + '</div>' +
            '<div class="statistics"><div><h4>' + metricNames[0] + '</h4><div class="stat-value"><div class="axis-color left"></div>' + makeReadable(d[metricY0], metricY0) + '</div></div>' +
            '<div><h4>' + metricNames[1] + '</h4><div class="stat-value"><div class="axis-color right"></div>' + makeReadable(d[metricY1], metricY1) + '</div></div></div>' +
            '<div class="events">' + createEvents(d.events) + '</div>')
            .style("left", function() {
              if (d3.event.pageX > offsetGraph - 295) {
                return (d3.event.pageX - 480) + "px";
              } else {
                if ($('#comparison-graph').offset().left === 20) {
                  return (d3.event.pageX) + "px";
                } else {
                  return (d3.event.pageX - 220) + "px";
                }
              }
            })
            .style("top", '130px')
            .style('background-color', '#0E445F')
            .style('color', 'white');
          })
      .on("mouseout", function() {
        div.transition().duration(500).style("opacity", 0);
      });

  svg.selectAll("dot")
      .data(data)
      .enter().append("circle")
      .attr("r", 5)
      .attr("cx", function(d) { return x(d.date); })
      .attr("cy", function(d) { return y1(d[metricY1]); })
      .style("fill", "white")
      .style("stroke", "#6b486b")
      .style("stroke-width", "3")
      .style("opacity", function(d) {
        if (topThreeEvents.indexOf(d.date) >= 0 ) {
          return '1';
        } else {
          return '0';
        }
      })
      .on("mouseover", function(d) {
        div.transition()
            .duration(200)
            .style("opacity", '.9');
        div.html(
          '<div class="date">' + formatTimeTooltip(d.date) + '</div>' +
          '<div class="statistics"><div><h4>' + metricNames[0] + '</h4><div class="stat-value"><div class="axis-color left"></div>' + makeReadable(d[metricY0], metricY0) + '</div></div>' +
          '<div><h4>' + metricNames[1] + '</h4><div class="stat-value"><div class="axis-color right"></div>' + makeReadable(d[metricY1], metricY1) + '</div></div></div>' +
          '<div class="events">' + createEvents(d.events) + '</div>')
            .style("left", function() {
              if (d3.event.pageX > offsetGraph - 295) {
                return (d3.event.pageX - 480) + "px";
              } else {
                if ($('#comparison-graph').offset().left === 20) {
                  return (d3.event.pageX) + "px";
                } else {
                  return (d3.event.pageX - 220) + "px";
                }
              }
            })
            .style("top", '130px')
            .style('background-color', '#0E445F');
          })
      .on("mouseout", function() {
        div.transition().duration(500).style("opacity", 0);
      });

  // ANIMATION FOR GRAPH LINES
  if (path.node() !== null ) {
    var totalLengthPath = path.node().getTotalLength();
    path.attr("stroke-dasharray", totalLengthPath + " " + totalLengthPath)
        .attr("stroke-dashoffset", totalLengthPath)
        .transition()
        .duration(2000)
        .ease(d3.easeLinear)
        .attr("stroke-dashoffset", 0);

  }
  if (path2.node() !== null ) {
    var totalLengthPath2 = path2.node().getTotalLength();
    path2.attr("stroke-dasharray", totalLengthPath2 + " " + totalLengthPath2)
         .attr("stroke-dashoffset", totalLengthPath2)
         .transition()
         .duration(2000)
         .ease(d3.easeLinear)
         .attr("stroke-dashoffset", 0);
  }
}


window.preparseData = preparseData;
window.getTopThreeEvents = getTopThreeEvents;
window.createLineGraph = createLineGraph;

export default {
  preparseData,
  getTopThreeEvents,
  createLineGraph
};
