/*global Adcurve, _, $, vex, I18n */
Adcurve.onLoad(function(){
  $('.toggle-errors').click(function() {
    var toggle = $(this);
    if($(toggle).text() === 'Show all errors') {
      toggle.text('Hide all errors');
      $('#feed-errors').show();
    } else {
      toggle.text('Show all errors');
      $('#feed-errors').hide();
    }
  });
  $(document).on('submit', '#configuration-wizard form', function() {
    $('#next-step i').removeClass('hidden');
  });
});

Adcurve.installationWizardForm = function () {
  var api = {}, that = {};

  api.init = function (config) {
    that.wizard = $('#installation-wizard');

    if (!that.wizard && validConfig(config)) {
      return false;
    }

    that.config = config;
    that.form = that.wizard.find('form');
    if (that.config['remote']) {
      validateAndSubmit(that.form);
    }
    $('.select').dropdown();
  };

  that.formValues = function () {
    return _.reduce(that.form.serializeArray(), function (acc, obj) {
      acc[obj.name] = obj.value;
      return acc;
    }, {});
  };

  function validConfig(config) {
    _.isObject(config) && _.has(config, 'url_to_shop_settings');
  }

  function validateAndSubmit (form) {
    form.on('submit', function(e){
      e.preventDefault();
      if(form.isValid(form[0].ClientSideValidations.settings.validators)) {
        submitAndShowWelcome(form);
      }
    });
  }

  function submitAndShowWelcome () {
    $('#next-step').prop('disabled', true);

    var email = $('#shop_settings_email').val();
    if($('#shop_settings_s2m_email').is(':visible')) {
      email = $('#shop_settings_s2m_email').val();
    }
    $('.validate_email').find('input').val(email);

    $('#step-one').hide();
    $('#next-step').hide();

    $('#step-two').show();
    $.ajax({
        type: 'POST',
        url: that.form.attr('action'),
        data: that.formValues(),
        dataType: 'JSON',
        success: onSuccess,
        error: onFailure
    });
  }

  function isWhitelabeled() {
    let h = window.location.hostname
    return h === "webshop.nl" || h === "webshopnl.demo-k8s.adcurve.com";
  }

  function onSuccess () {
    vex.defaultOptions.className = 'vex-theme-plain';
    vex.defaultOptions.overlayClosesOnClick = false;
    vex.dialog.buttons.YES.text = 'OK';
    vex.dialog.buttons.YES.className = 'hidden';
    vex.dialog.alert({
      message: isWhitelabeled() ? I18n.t('shop_wizard.dialog_webshop.success_title') : I18n.t('shop_wizard.dialog.success_title'),
      input: $('.validate_email')
    });
    $('.spinner').addClass('hidden');
  }

  function onFailure () {
    vex.defaultOptions.className = 'vex-theme-plain';
    vex.defaultOptions.overlayClosesOnClick = false;
    vex.dialog.buttons.YES.text = 'OK';
    vex.dialog.buttons.YES.className = 'hidden';
    let title,
        message;
    if (isWhitelabeled() ) {
      title = I18n.t('shop_wizard.dialog_webshop.error_title');
      message = I18n.t('shop_wizard.dialog_webshop.something_went_wrong');
    } else {
      title = I18n.t('shop_wizard.dialog.error_title');
      message = I18n.t('shop_wizard.dialog.something_went_wrong');
    }
    vex.dialog.alert({
      message: title,
      input: '<div class="flash error">' + message + '</div>'
    });
    $('.spinner').addClass('hidden');
  }
  return api;
};
