/*global Adcurve, _, Lazy */
Adcurve.TaxonomiesCollection = function(taxonomies){
  this._collection = taxonomies;
  Lazy(this._collection).each(function(taxonomy){
    var categories = new Adcurve.CategoriesCollection(taxonomy.categories);
    taxonomy.categories = categories.collection();
  });
  this._filters = this.buildTaxonomyFilters();
  this._notPermittedFilters = _.map(this._filters, function(element) { return element.label; });
  this._notPermittedFilterIDs = _.map(this._filters, function(element) { return element.id; });
};

Adcurve.TaxonomiesCollection.prototype.isEmpty = function(){
  return this._collection.length === 0;
};

Adcurve.TaxonomiesCollection.prototype.findById = function(id){
  return _.find(this._collection, function(t){ return t.id === parseInt(id); });
};

// TODO: remove all the references
Adcurve.TaxonomiesCollection.prototype.collection = function(){
  return this._collection;
};

Adcurve.TaxonomiesCollection.prototype.notPermittedFilters = function(){
  return this._notPermittedFilters;
};

Adcurve.TaxonomiesCollection.prototype.notPermittedFilterIDs = function(){
  return this._notPermittedFilterIDs;
};

Adcurve.TaxonomiesCollection.prototype.each = function(callback){
  _.each(this._collection, function(taxonomy){
    callback(taxonomy);
  });
};

Adcurve.TaxonomiesCollection.prototype.ids = function(){
  return _.map(this._collection, function(x) { return x.id; });
};

Adcurve.TaxonomiesCollection.prototype.mappable = function(){
  var taxonomies = [], found;
  _.each(this._collection, function(taxonomy) {
    found = _.find(taxonomy.categories, function(category){
      return category.mapping_allowed;
    });
    if (found) {
      taxonomies.push(taxonomy);
    }
  });
  return taxonomies;
};

Adcurve.TaxonomiesCollection.prototype.allowTaxonomyMapper = function(){
  if (this._collection.length === 0) {
    return false;
  }
  return _.find(this._collection, function(taxonomy){
    return _.find(taxonomy.categories, function(category){
      return (category.mapping_allowed === true);
    });
  });
};

Adcurve.TaxonomiesCollection.prototype.filters = function() {
  return this._filters;
};

Adcurve.TaxonomiesCollection.prototype.buildTaxonomyFilters = function () {
  return _.map(this._collection, function (t) {
      var values = _.map(t.categories, function(v) {
          var obj = {};
          obj[v.id] = v.name.trim();
          return obj;
      });
      values = _.sortBy(values, function(cat){
        return _.values(cat)[0];
      });
      return { id: t.id, label: t.name, optgroup: 'x_extra', operators: ["equal", "not_equal", "is_empty", "is_not_empty", "is_not_manually_set", "is_manually_set", "is_manually_set_by_tip"], type: "string", input: "select", values: values };
  });
};
