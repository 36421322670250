const D3_LOCALE = function(shopOptions) {
  return d3.formatLocale({
    "decimal": shopOptions.currencyDecimalMark,
    "thousands": shopOptions.currencyThousandsSeparator,
    "grouping": [3],
    "currency": [shopOptions.currencyUnit, ""],
    "dateTime": "%a %b %e %X %Y",
    "date": "%m/%d/%Y",
    "time": "%H:%M:%S",
    "periods": ["AM", "PM"],
    "days": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    "shortDays": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    "months": ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    "shortMonths": ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
  });
};

window.D3_LOCALE = D3_LOCALE;
export default D3_LOCALE;
