/*global CurrencyValue, Adcurve, Lazy */
import CurrencyValue from "../../apps/product_management/components/listing_components/CurrencyValue";

function AdsAggregator(field) {
  this.products = {};
  this.index = 0;
  this.field = field;
  this.push = function(element) {
    this.products[element[this.field]] = Adcurve.aggregateProperties(this.products[element[this.field]], element);
    this.products[element[this.field]].key = this.index;
    this.index += 1;
  };
  this.formatted = function() {
    var element, self = this;
    // When griddle is moved to lazy instead of _ remove the .toArray() call
    return Lazy(this.products).map(function(value, key){
      element = {
        name: key,
        id: value.key,
        key: value.key,
        costs: value.costs,
        traffic: value.traffic,
        revenue: value.revenue,
        profit: value.profit,
        margin: value.margin,
        assists: value.assists,
        productNumber: value.productNumber
      };
      element[self.field] = key;
      Adcurve.aggregationExtraFields.each(function(field){
        element[Adcurve.shopFields.byUserFieldName(field)] = value[Adcurve.shopFields.byUserFieldName(field)];
      })
      return element;
    }).toArray();
  };
}
Adcurve.aggregateProperties = function(oldProps, newProps) {
  var props = {
    revenue: newProps.revenue,
    costs: newProps.costs,
    profit: newProps.profit,
    traffic: newProps.traffic,
    margin: newProps.margin,
    assists: newProps.assists,
    productNumber: 1,
  };
  Adcurve.aggregationExtraFields.each(function(field){
    props[Adcurve.shopFields.byUserFieldName(field)] = newProps[Adcurve.shopFields.byUserFieldName(field)];
  });
  if (typeof oldProps === 'undefined') {
    props.children = [];
    return props;
  }
  props.revenue += oldProps.revenue;
  props.costs += oldProps.costs;
  props.profit += oldProps.profit;
  props.traffic += oldProps.traffic;
  props.margin += oldProps.margin;
  props.assists += oldProps.assists;
  props.productNumber += oldProps.productNumber;
  return props;
};
// Devine all extra fields for aggregation views that don't need to be accumulated, but should show in columns/filters
Adcurve.aggregationExtraFields = Lazy(['auction_budget']).filter(function(field) {
    return Adcurve.shopFields.hasField(field);
  });
Adcurve.aggregationColumns = function() {
  var columns = Lazy(['name', 'traffic', 'revenue', 'margin', 'costs', 'profit', 'assists']);
  var extraColumns = Adcurve.aggregationExtraFields.map(function(field){
      return Adcurve.shopFields.byUserFieldName(field);
    });
  return columns.union(extraColumns).toArray();
};
// Needs to be function because CurrencyValue is not defined until react runs.
const AggregationCommonColumns = function() {
  return [{
    "columnName": "revenue",
    "locked": true,
    "visible": true,
    "displayName": "Revenue",
    "cssClassName": "statistic",
    "customComponent": CurrencyValue
  },
  {
    "columnName": "margin",
    "locked": true,
    "visible": true,
    "displayName": "Margin",
    "cssClassName": "statistic",
    "customComponent": CurrencyValue
  },
  {
    "columnName": "costs",
    "locked": true,
    "visible": true,
    "displayName": "Costs",
    "cssClassName": "statistic",
    "customComponent": CurrencyValue
  },
  {
    "columnName": "profit",
    "locked": true,
    "visible": true,
    "displayName": "Profit",
    "cssClassName": "statistic",
    "customComponent": CurrencyValue
  },
  { "columnName": "traffic",
    "locked": false,
    "visible": true,
    "cssClassName": "statistic",
    "displayName": "Visits"
  },
  { "columnName": "assists",
    "locked": false,
    "visible": true,
    "cssClassName": "statistic",
    "displayName": "Assists"
  }];
};
window.AggregationCommonColumns = AggregationCommonColumns;

export default AdsAggregator;
