/*global Adcurve, _ */
Adcurve.contentBasicFilters = function () {
  var result = Adcurve.buildFilters(Adcurve.CONTENT_FLOAT_OPERATORS, Adcurve.CONTENT_STRING_OPERATORS, Adcurve.CONTENT_STRING_OPERATORS, true);
  result = result.concat([
    { id: 'description',
      label: 'Description',
      type: 'string',
      optgroup: 'g_information',
      operators: Adcurve.CONTENT_STRING_OPERATORS
    },
    { id: 'deeplink',
      label: 'Deeplink',
      type: 'string',
      optgroup: 'g_information',
      operators: Adcurve.CONTENT_STRING_OPERATORS
    }
  ]);
  return result.filter(function(element){
    return element.id !== 'category';
  }).toArray();
};

Adcurve.contentAllFilters = function () {
  return Adcurve.contentBasicFilters().concat(Adcurve.shopFields.mappedForContent());
};

Adcurve.contentChangableFilters = function () {
  var result = Adcurve.contentBasicFilters().concat(Adcurve.contractFields.mappedForContent());

  return _.filter(result, function(element){
    return element.optgroup !== 'm_statistics';
  });
};

Adcurve.contentFilterGroupTranslations = function (key) {
  return { g_information: 'Details', m_statistics: 'Analytics', x_extra: 'Extra' }[key];
};
Adcurve.sortedContentFilters = function () {
  var grouppedFilters = {
    g_information: [],
    m_statistics: [],
    x_extra: []
  };

  var sortedFilters = Adcurve.contentChangableFilters().sort(Adcurve.sortFilters);
  _.each(sortedFilters, function(filter) {
    switch (filter.optgroup) {
      case 'g_information':
        grouppedFilters.g_information.push(filter);
        break;
      case 'm_statistics':
        grouppedFilters.m_statistics.push(filter);
        break;
      case 'x_extra':
        grouppedFilters.x_extra.push(filter);
        break;
    }
  });
  return grouppedFilters;
};

Adcurve.contentSelectizedFilters = function () {
  return _.map(Adcurve.contentChangableFilters(), function(element) {
    return Adcurve.contentQueryBuilder.selectizeFilter(element);
  });
};

Adcurve.contentFloatFilters = function () {
  return _.filter(Adcurve.contentAllFilters(), function(element) {
    return element.operators == Adcurve.CONTENT_FLOAT_OPERATORS;
  });
};

Adcurve.contentComparableFilters = function (currentFilter) {
  var result = currentFilter.operators === Adcurve.CONTENT_FLOAT_OPERATORS ? Adcurve.contentFloatFilters() : Adcurve.contentAllFilters();

  return _.filter(result, function(element){
    return element.id !== currentFilter.id;
  });
};

Adcurve.comparableContentFieldsIDs = function (currentFilter) {
  return _.map(Adcurve.contentComparableFilters(currentFilter), function(element) {
    return element.id;
  });
};
