/*global Adcurve */
Adcurve.TaxonomiesItem = function(id, value, metadata){
  this._item = metadata;
  this._item.id = id;
  this._item.value = value;
};

Adcurve.TaxonomiesItem.prototype.item = function() {
  return this._item;
};
