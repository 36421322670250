Adcurve.onLoad(function(){
  $('#user_form').submit(function(event) {
    if ($('#user_password_confirmation').val() != $('#user_password').val()) {
      $('.content-messages .message-box.error').html('Passwords did not match.');
      var box = $('.content-messages');
      if (box.is(':hidden')) {
        box.transition('slide down');
      } else {
        box.transition('slide up');
        box.transition('slide down');
      }
      return false;
    } else {
      return true;
    }
  });
});
