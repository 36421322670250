/*global Adcurve, _, $ */
(function () {
  'use strict';
  // TODO: replace with collection.findById
  Adcurve.findCategory = function(allCategories, categoryId) {
    return _.find(allCategories, function(element){
      return element.id == categoryId;
    });
  };


  Adcurve.allowMapping = function() {
    return Adcurve.categories.allowedCategories().length > 0;
  };
}());
