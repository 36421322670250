var ready = function () {
  $("[data-element-behaviour='auto-submit']").change(function(event) {
    $(this).closest('form').submit();
  });

  var formatter = Adcurve.formWithNumberFormat($('#edit_shop'));
  formatter.addInput('#shop_margin', '%');
  formatter.addInput('#shop_tax', '%');
};

document.addEventListener("DOMContentLoaded", ready);
