require("@rails/ujs").start();

import '@client-side-validations/client-side-validations';
import 'rails.validations.simple_form';
import 'rails.validations.form_tag';
import 'rails.validations.custom';

import 'semantic-ui/dist/components/accordion.css';
import 'semantic-ui/dist/components/accordion';
import 'semantic-ui/dist/components/dropdown.css';
import 'semantic-ui/dist/components/dropdown';
import 'semantic-ui/dist/components/transition.css';
import 'semantic-ui/dist/components/transition';
import "semantic-ui/dist/components/popup.css";
import "semantic-ui/dist/components/popup";

import "bootstrap-daterangepicker/daterangepicker.css";

import vex from 'vex';
import dialog from 'vex-dialog';


const images = require.context("images", true);

window.vex = vex;
window.vex.dialog = dialog;

$.chosen = require('chosen-js');
window.routie = require('routie');

import "jquery-querybuilder/dist/js/query-builder";
import "jquery-querybuilder/dist/css/query-builder.default.css"


import {daterangepicker as DateRangePicker} from "bootstrap-daterangepicker/daterangepicker";


$.fn.daterangepicker = function(options, callback) {
  this.each(function() {
    var el = $(this);
    if (el.data('daterangepicker'))
      el.data('daterangepicker').remove();
    el.data('daterangepicker', new DateRangePicker(el, options, callback));
  });
  return this;
};

// Exporting jQuery to window because it will not be accessible outside of webpack
window.$ = window.jQuery = $;

import "helpers";
import "models";
import "routes";
import "connectors";
