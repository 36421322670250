/*global jQuery, d3, _, $, Adcurve, D3_LOCALE */
function createTopFlops(data, metric, options, showAll) {

  d3.select('#top_flop_chart').select('svg').remove();

  // Remove publishers that have no stats on selected metric
  function removeLowStats(data) {
    var cleanedData = [];
    var maxValue = d3.max(data, function(d) {return d.statistics[0][metric];});
    data.forEach(function(d) {
      if (d.statistics[0][metric] < (-1 * maxValue/100) || d.statistics[0][metric] > (maxValue/100)) {
        cleanedData.push(d);
      }
    });
    return cleanedData;
  }

  function makeReadable(value, metric) {
    if (metric == 'orders' || metric == 'traffic' || metric == 'assists') {
      return shopLocale.format(",")(value);
    } else if (metric == 'assist_ratio' || metric == 'bounce_rate' || metric == 'contribution' || metric == 'roi'){
      return shopLocale.format(",.3")(value) + '%';
    // } else if (round === true) {
    //   return shopLocale.format("$,.f")(value);
    } else {
      return shopLocale.format("$,.2f")(value);
    }
  }
  var top_flop_chart = document.getElementById('top_flop_chart');
  var width;
  if (top_flop_chart !== null) {
    width = top_flop_chart.offsetWidth;
  }

  var sortedData = _.chain(data).sortBy(function(d) { return d.statistics[0][metric];}).value().reverse();
  // var topFlopData = _.union(_.first(sortedData, 5), _.last(sortedData, 5));
  // var tops = _.pluck(_.union(_.first(sortedData, 5)), 'name');
  var margin = {top: 20, right: 30, bottom: 40, left: 0},
      height = sortedData.length * 30;
  var shopLocale = D3_LOCALE(options);
  var x = d3.scaleLinear()
      .rangeRound([250, width - 50]);
  var xAxis = d3.axisTop()
      .scale(x)
      .ticks(5)
      .tickSize(-height)
      .tickPadding(6)
      .tickFormat(function(d) {
        return makeReadable(d, metric, true);
      });

  var y = d3.scaleBand()
      .range([0, height], 0.1);
  var yAxis = d3.axisLeft()
      .scale(y)
      .tickSize(-width)
      .tickPadding(6);

// CREATE GRAPH
  var svg = d3.select("#top_flop_chart").append("svg")
      .attr("width", width)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

  var top_flop_tooltip = d3.select('body').append('div')
      .attr('class', 'top_flop_tooltip')
      .style('opacity', 0);

  x.domain(d3.extent(sortedData, function(d) { return d.statistics[0][metric];})).nice();
  y.domain(sortedData.map(function(d) { return d.name; }));

  svg.append("g")
      .attr("class", "y axis")
      .call(yAxis);

  svg.selectAll(".bar")
      .data(sortedData)
      .enter().append("rect")
      .attr("class", function(d) { return "bar bar--" + (d.statistics[0][metric] < 0 ? "negative" : "positive"); })
      .attr("x", function(d) { return x(Math.min(0, d.statistics[0][metric])); })
      .attr("y", function(d) { return y(d.name) + 3; })
      .attr("rx", 2)
      .attr("width", 0)
      .attr("height", 26)
      .on('mouseover', function(d) {
        top_flop_tooltip.transition()
          .duration(200)
          .style('opacity', 0.9);
        top_flop_tooltip.html(
          '<div class="value">' + makeReadable(d.statistics[0][metric], metric) + '</div>')
          .style("left", (d3.event.pageX) + "px")
          .style("top", (d3.event.pageY) + "px");
      })
      .on('mouseout', function(d) {
        top_flop_tooltip.transition()
          .duration(500)
          .style('opacity', 0);
      })
      .transition()
          .duration(1200)
          .ease(d3.easeLinear)
          .attr("width", function(d) { return Math.abs(x(d.statistics[0][metric]) - x(0)); });

  svg.append("g")
      .attr("class", "x axis")
      .call(xAxis);

  svg.select(".x.axis").selectAll("text")
      .attr("fill", "#0E445F")
      .style("font-size", "14px")
      .style("font-family", "AGB");
  svg.select(".y.axis").selectAll("text")
      .style("text-anchor", "start")
      .attr("x", 10)
      .attr("fill", "#0E445F")
      .style("font-size", "14px")
      .style("font-family", "AGB");

  // svg.selectAll(".y.axis .tick").append('text')
  //     .attr("font-family", "Material Icons")
  //     .attr("font-size", "18px")
  //     .attr('x', 220)
  //     .attr('y', 9)
  //     .attr('fill', function(d) {
  //       if (_.contains(tops, d)) {
  //         return '#42D17F';
  //       } else {
  //         return '#E74C3C';
  //       }
  //     })
  //     .text(function(d) {
  //       if (_.contains(tops, d)) {
  //         return 'thumb_up';
  //       } else {
  //         return 'thumb_down';
  //       }
  //     });

  svg.selectAll(".y.axis .tick")
    .data(sortedData)
    .on('mouseover', function(d) {
      top_flop_tooltip.transition()
        .duration(200)
        .style('opacity', 1);
      top_flop_tooltip.html(
        '<div class="value">' + makeReadable(d.statistics[0][metric], metric) + '</div>')
        .style("left", (d3.event.pageX) + "px")
        .style("top", (d3.event.pageY) + "px");
    })
    .on('mouseout', function(d) {
      top_flop_tooltip.transition()
        .duration(500)
        .style('opacity', 0);
    });
  if ($('#top_flop_table').is(':visible')) {
    $('#top_flop_chart').hide();
  }
}
$.extend( $.fn.dataTableExt.oSort, {
  "currency-pre": function ( a ) {
    if (a === "-" || a === "N/A") {
      return 0.0;
    }
    a = a.replace(/[^\d\-]/g, "").replace(Adcurve.shopOptions.currencyThousandsSeparator, "");
    return parseFloat(a);
  },
  "currency-asc": function ( a, b ) {
    return a - b;
  },
  "currency-desc": function ( a, b ) {
    return b - a;
  }
} );
Adcurve.onLoad(function(){
  if ($('#top_flop_table').length > 0) {
    var dataColumns = [
      { "data": "name", "className": "info"},
      { "data": "statistics[0].traffic", "className": "statistic"},
      { "data": "statistics[0].assists", "className": "statistic"},
      { "data": "statistics[0].cex_amount", "render": Adcurve.FORMAT_DATATABLE_CURRENCY, "className": "statistic", "type": "currency"},
      { "data": "statistics[0].cmargin", "render": Adcurve.FORMAT_DATATABLE_CURRENCY, "className": "statistic", "type": "currency"},
      { "data": "statistics[0].costs", "render": Adcurve.FORMAT_DATATABLE_CURRENCY, "className": "statistic", "type": "currency"},
      { "data": "statistics[0].ecpc", "render": Adcurve.FORMAT_DATATABLE_CURRENCY, "className": "statistic", "type": "currency"},
      { "data": "statistics[0].profit", "render": Adcurve.FORMAT_DATATABLE_CURRENCY, "className": "statistic", "type": "currency"},
      { "data": "statistics[0].roi", "render": Adcurve.FORMAT_DATATABLE_PERCENTAGE, "className": "statistic", "type": "currency"}
    ];
    if (Adcurve.channelUser){
      dataColumns.splice(8,1);
      dataColumns.splice(7,1);
      dataColumns.splice(4,1);
      dataColumns.splice(3,1);
    }
    $('#top_flop_table').DataTable( {
      "paging": false,
      "pageLength": 2000,
      "order": [[1, "desc"]],
      "ordering": true,
      "info": false,
      "autoWidth": false,
      "bFilter": false,
      "scrollX": true,
      "scrollY": "532px",
      "scrollCollapse": true,
      "fixedColumns": false,
      "oLanguage": {
        "sLoadingRecords": "<div class='spinner'><div></div><div></div><div></div></div>"
      },
      "initComplete": function( settings, data ) {
        $('#publisher-bars').click(function() {
          var metric = $('.metric_selector_topflop').dropdown('get value');
          createTopFlops(data.data, metric, Adcurve.shopOptions);
        });
        $('.metric_selector_topflop').on('change', function() {
          var metric = $('.metric_selector_topflop').dropdown('get value');
          createTopFlops(data.data, metric, Adcurve.shopOptions);
        });
        $( window ).resize(function() {
          var metric = $('.metric_selector_topflop').dropdown('get value');
          createTopFlops(data.data, metric, Adcurve.shopOptions);
        });
      },
      "ajax": Adcurve.shopStatsPath,
      "columns": dataColumns,
      "footerCallback": function ( row, data, start, end, display ) {
            var api = this.api(), data;
            // Remove the formatting to get integer data for summation
            var columns = [1, 2, 3, 4, 5, 6];
            if (Adcurve.channelUser){
              columns = [1, 2, 3];
            }
            var intVal = function ( i ) {
              return typeof i === 'string' ?
              i.replace(/[\$,]/g, '')*1 :
              typeof i === 'number' ?
              i : 0;
            };

            _.each(columns, function(columnNo) {
              var total = api.column( columnNo ).data().reduce( function (a, b) { return intVal(a) + intVal(b);}, 0 );
              if (columnNo !== 1 && columnNo !== 2){
                total = Adcurve.FORMAT_DATATABLE_CURRENCY(total)
              }
              $( api.column( columnNo).footer() ).html(total);
            });

            // Total over all pages

            // Update footer
        }
    });
  }
});
