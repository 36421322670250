Adcurve.ProductInformation = function(elementsSelector, commonElementSelector){
  this.elementsSelector = elementsSelector;
  this.commonElementSelector = commonElementSelector;

  this.displayValues = function(){
    if(this.isValueSame()) {
      $(this.commonElementSelector).removeClass('hidden');
    } else {
      $(this.elementsSelector).removeClass('hidden');
    }
  };

  this.isValueSame = function(){
    var values = this.collectValues();

    var uniqValues = values.filter(function(item, i, values) {
      return i == values.indexOf(item);
    });

    return uniqValues.length == 1;
  };

  this.collectValues = function(){
    var values = [];
    $(this.elementsSelector).each(function(i){
      values.push($.trim($(this).find('p').html()));
    });
    return values;
  };
}

Adcurve.showBonoboMappingColumn = function(){
  if($('[class^="bonobo_mapping_field_info_"]').not('.hidden').length > 0) {
    $('.bonobo_variants_mapping_column').removeClass('hidden');
  }else{
    $('#bonobo_mapping_column').removeClass('hidden');
  }
}

Adcurve.showUserFieldColumn = function(){
  if($('[class^="user_field_"]').not('.hidden').length > 0) {
    $('.user_field_variants_column').removeClass('hidden');
  }else{
    $('#user_field_column').removeClass('hidden');
  }
}
