/*global Adcurve, $, _ */
Adcurve.TimeZoneChanger = function(selector, initialCountry) {
  var that = this;
  this.init = function() {
    that._options = {};
    that._selectList = $('#' + selector);
    that._timeZoneDiv = $('.' + selector);
    that._selectList.find('option').each(function() {
      that._options[$(this).val()]= $(this).text();
    });
    that.change(initialCountry);
  };
  // Shows or hides the time zone div depending on number of options and recreates the semantic dropdown
  this.toggleTimeZoneSelector = function(timeZones) {
    if (timeZones.length > 1) {
      that._timeZoneDiv.show();
    } else {
      that._timeZoneDiv.hide();
    }
    that._selectList.dropdown('clear');
    that._selectList.dropdown('refresh');
    // semantic UI doesn't refresh properly otherwise
    setTimeout(function () {
      that._selectList.dropdown('set selected',  that._selectList.find("option:first").val());
    }, 1);

  };
  // Change the timezone selector depending on current selected country
  this.change = function(country) {
    var timeZones = Adcurve.timeZones[country];
    // remove all options
    that._selectList.find('option').remove();
    // append matching time zones
    _.each(timeZones, function (zone){
      that._selectList.append($('<option>').text(that._options[zone]).val(zone));
    });
    that.toggleTimeZoneSelector(timeZones);
  };
  this.init();
  return { change: this.change };
};

Adcurve.onLoad(function(){
  var countrySelector = $('#shop_settings_country');
  var zone = new Adcurve.TimeZoneChanger('shop_settings_time_zone', countrySelector.val());
  countrySelector.on('change', function(element) {
    zone.change($(element.target).val());
  });
},'#shop_settings_country');
