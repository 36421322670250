/* global Adcurve, vex, $, I18n */

Adcurve.Subscriptions = (paymentForm) => {
  const api = {};

  function openCancelVex(e) {
    e.preventDefault();
    const el = $(this);
    vex.defaultOptions.className = 'vex-theme-plain';
    vex.dialog.buttons.YES.text = 'Yes';
    vex.dialog.buttons.YES.className = 'button full success';
    vex.dialog.buttons.NO.text = 'Cancel';
    vex.dialog.buttons.NO.className = 'button full error';
    vex.dialog.open({
      message: I18n.t('cancel_confirm_text_html', { publisher: el.data('publisher') }),
      callback: (success) => {
        if (success) {
          el.parents('.confirm-wrapper').find('.hidden.action')[0].click();
        }
      },
    });
  }

  function openConfirmVex(el) {
    vex.defaultOptions.className = 'vex-theme-plain vex-payment-window';
    vex.dialog.buttons.YES.text = 'Yes';
    vex.dialog.buttons.YES.className = 'button full success';
    vex.dialog.buttons.NO.text = 'No';
    vex.dialog.buttons.NO.className = 'button full error';
    vex.dialog.open({
      message: el.data('confirm'),
      callback: (success) => {
        if (success) {
          el.parents('.confirm-wrapper').find('.hidden.action')[0].click();
        }
      },
    });
  }

  api.init = function () {
    // app details page
    $('#upgrade-action').click(function (e) {
      const el = $(this);
      if (!Adcurve.billing.required) {
        el.parents('.confirm-wrapper').find('.hidden.action').click();
        return false;
      }
      e.preventDefault();
      if (Adcurve.billing.shopify_payment_type || Adcurve.billing.saved) {
        openConfirmVex(el);
      } else {
        paymentForm.openVex();
      }
      return false;
    });
    $('#cancel-action').click(openCancelVex);
    // settings page
    $('#edit_billing').click((e) => {
      e.preventDefault();
      paymentForm.openVex();
    });
    $('#change_stripe').click((e) => {
      e.preventDefault();
      paymentForm.openStripe();
    });
  };

  return api;
};

Adcurve.onLoad(() => {
  const paymentForm = Adcurve.PaymentForm(Adcurve.paymentConfig, Adcurve.stripeConfig);
  Adcurve.Subscriptions(paymentForm).init();
});

export default Adcurve.Subscriptions;
