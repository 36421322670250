var ready = function () {
    // Stripe button fix
    // $('.stripe-button-el').addClass('button button full success detail-action-button').removeClass('stripe-button-el');

  $('.hover-action').hover(
      function() {
        $(this).find('span.default').addClass('hidden');
        $(this).find('span.hovered').removeClass('hidden');
      },
      function() {
        $(this).find('span.default').removeClass('hidden');
        $(this).find('span.hovered').addClass('hidden');
      });
};

document.addEventListener("DOMContentLoaded", ready);
