$(function() {
  var ready;
  ready = function () {
    var inputsToValidate = ':input:enabled[data-validate-presence],:input:enabled[data-validate-iban]';

    $('.validate-form-tag').on('submit', function(e){
      FormTagValidator.validateForm($(this), e);
    });

    $(inputsToValidate).on('focusout', function(){
      FormTagValidator.validate($(this));
    })

    FormTagValidator = {
      validators: {
        presence: function(element, options) {
          switch (element.attr('type')) {
            case 'checkbox':
              if (!element.prop('checked')) {
                return true;
              }
              break;
            case 'text':
              if (/^\s*$/.test(element.val() || '')) {
                return true;
              }
            }
          return false;
        }
      },

      addError: function(element, message) {
        if (element.attr('type') != 'checkbox') {
          var errorContainer = $("<div/>", {"class": 'state error form_tag_error'});
          var errorIcon = $("<i/>", {"class": 'material-icons md-18', text: 'error'});
          var errorMessage = $("<div/>", {"class": 'input-error', text: message});
          errorContainer.append(errorIcon);
          errorContainer.append(errorMessage);

          var existingError = $(element).parent().find('.form_tag_error');
          if (existingError.length == 0) {
            $(element).after(errorContainer);
          } else {
            existingError.find('.input-error').text(message);
          }
        }

        $(element).parent().addClass('field_with_errors');
      },

      removeError: function(element) {
        $(element).parent().find('.form_tag_error').remove();
        $(element).parent().removeClass('field_with_errors');
      },

      validate: function(element) {
        var message;
        if (element.data('validate-iban')) {
          if (FormTagValidator.validators.presence(element)) {
            message = I18n.t('errors.messages.blank');
          } else if (!IBAN.isValid(element.val())) {
            message = I18n.t('errors.messages.invalid');
          }
        } else if (FormTagValidator.validators.presence(element)) {
          message = I18n.t('errors.messages.blank');
        }

        if (message) {
          FormTagValidator.addError(element, message);
        } else {
          FormTagValidator.removeError(element);
        }
      },

      validateForm: function(form, e) {
        form.find(inputsToValidate).each(function() {
          FormTagValidator.validate($(this));
        });

        if (FormTagValidator.withErrors(form)) {
          e.preventDefault();
          e.stopPropagation();
        }
      },

      valid: function(form, e) {
        FormTagValidator.validateForm(form, e)

        if (FormTagValidator.withErrors(form)) {
          return false;
        }
        return true;
      },

      withErrors: function(form) {
        return form.find('.field_with_errors:visible').length != 0;
      }
    }
  }

  Adcurve.onLoad(ready);
});
