/*global Adcurve, _, AdcurveAbility, Lazy */
Adcurve.customFilter = function(data, filter) {
  if (typeof filter.rules == "undefined" || filter.rules.length === 0 ){
    Adcurve.productForRulePreview = Lazy(data).first();
    return data;
  }
  var rules = new Adcurve.RulesCollection(filter.rules);
  var result = Lazy(data).reject({unspecified: true}).filter(function(element) {
    return rules.include(element);
  });
  Adcurve.productForRulePreview = result.first();
  return result.toArray();
};

Adcurve.permittedFilters = function() {
  var priceModelSpecific = [], publisherSpecific = [];
  if (Adcurve.adSettings.has_publisher && Adcurve.categories.allowedCategories().length !== 0) {
    publisherSpecific = ['pub_category_path'];
  }
  if (_.indexOf(['gold', 'silver'], Adcurve.adSettings.price_model) !== -1) {
    priceModelSpecific = Adcurve.PRICE_MODEL_SPECIFIC_FILTERS;
  }
  var basic = ['brand', 'category', 'margin', 'product_margin', 'product_name', 'price_incl', 'price_excl', 'floor_price', 'revenue', 'shop_code', 'visits', 'products_in_stock', 'stock_status', 'delivery_period', 'delivery_cost', 'margin_ratio', 'product_ean', 'vendor_code', 'order_amount_excluding_tax', 'status', 'assists', "marked_price", "picture_link"];
  return basic.concat(priceModelSpecific).concat(publisherSpecific);
};

Adcurve.sidebarFilters = function () {
  var result = Adcurve.buildFilters(Adcurve.FLOAT_OPERATORS, Adcurve.STRING_OPERATORS, Adcurve.SELECT_OPERATORS);
  var publisherOperators = ["equal", "not_equal", "is_empty", "is_not_empty", "is_not_manually_set", "is_manually_set"];
  if (AdcurveAbility.can('manageAds')) {
    publisherOperators.push("is_manually_set_by_tip");
  } else {
    Adcurve.STATUS_VALUES.splice(Adcurve.STATUS_VALUES.length - 2, 1);
  }
  if (!Adcurve.adSettings.has_publisher) {
    Adcurve.STATUS_VALUES = [{'enabled': 'Enabled'},
    {'disabled': 'Disabled'}];
  }
  result = result.concat([
    { id: 'status',
      label: 'Status',
      type: 'string',
      input: 'select',
      optgroup: 'a_status',
      values: Adcurve.STATUS_VALUES,
      operators: ['equal', 'not_equal']
    },
    { id: 'pub_category_path',
      label: 'Publisher category',
      type: 'string',
      input: 'select',
      optgroup: 'g_information',
      operators: publisherOperators,
      values: Adcurve.appliedCategories
    }
  ]);
  var permittedFilters = Adcurve.permittedFilters();
  result = result.filter(function(element){
    return _.indexOf(permittedFilters, element.id) !== -1;
  }).concat(Adcurve.taxonomies.filters()).concat(Adcurve.shopFields.mapped());

  result = result.map(function(filter) {
    return (_.indexOf(Adcurve.comparableFieldsIDs(), filter.id) !== -1) ? Adcurve.queryBuilder.selectizeFilter(filter) : filter;
  });

  return result.toArray();
};

Adcurve.buildFilters = function (floatOperators, stringOperators, selectOperators, isContentFilter) {
  var result = [
        { id: 'costs',
          label: 'Costs',
          type: 'double',
          validation: {
            min: 0,
            step: 0.01
          },
          optgroup: 'm_statistics',
          operators: floatOperators
        },
        { id: 'ecpc',
          label: 'eCPC',
          type: 'double',
          validation: {
            min: 0,
            step: 0.01
          },
          optgroup: 'm_statistics',
          operators: floatOperators,
        },
        { id: 'max_cpc',
          label: 'Max CPC',
          type: 'double',
          validation: {
            min: 0,
            step: 0.01
          },
          optgroup: 'm_statistics',
          operators: floatOperators
        },
        { id: 'profit',
          label: 'Profit',
          type: 'double',
          validation: {
          min: -10000000,
          step: 0.01
          },
          optgroup: 'm_statistics',
          operators: floatOperators
        },
        { id: 'roi',
          label: 'ROI %',
          type: 'double',
          validation: {
            min: -100,
            step: 0.01
          },
          optgroup: 'm_statistics',
          operators: floatOperators
        },
        { id: 'brand',
          label: 'Brand',
          type: 'string',
          optgroup: 'g_information',
          operators: stringOperators
        },
        { id: 'category',
          label: 'Category',
          type: 'string',
          optgroup: 'g_information',
          operators: stringOperators
        },
        { id: 'margin',
          label: 'Margin',
          type: 'double',
          validation: {
          min: -10000000,
          step: 0.01
          },
          optgroup: 'm_statistics',
          operators: floatOperators
        },
        { id: 'product_margin',
          label: 'Product Margin',
          type: 'double',
          validation: {
          min: -10000000,
          step: 0.01
          },
          optgroup: 'g_information',
          operators: floatOperators
        },
        { id: 'margin_ratio',
          label: 'Product Margin %',
          type: 'double',
          validation: {
            min: -100,
            step: 0.01
          },
          optgroup: 'g_information',
          operators: floatOperators
        },
        { id: 'product_name',
          label: 'Product Name',
          type: 'string',
          optgroup: 'g_information',
          operators: stringOperators
        },
        { id: 'picture_link',
          label: 'Picture Link',
          type: 'string',
          optgroup: 'g_information',
          operators: stringOperators
        },
        { id: 'price_incl',
          label: 'Selling Price',
          type: 'double',
          validation: {
          min: -10000000,
          step: 0.01
          },
          optgroup: 'g_information',
          operators: floatOperators
        },
        { id: 'price_excl',
          label: 'Selling Price Excl',
          type: 'double',
          validation: {
          min: -10000000,
          step: 0.01
          },
          optgroup: 'g_information',
          operators: floatOperators
        },
        { id: 'floor_price',
          label: 'Floor Price',
          type: 'double',
          validation: {
          min: -10000000,
          step: 0.01
          },
          optgroup: 'g_information',
          operators: floatOperators
        },
        { id: 'marked_price',
          label: 'Marked Price',
          type: 'double',
          validation: {
          min: -10000000,
          step: 0.01
          },
          optgroup: 'g_information',
          operators: floatOperators
        },
        { id: 'order_amount_excluding_tax',
          label: 'Attributed revenue',
          type: 'double',
          validation: {
          min: -10000000,
          step: 0.01
          },
          optgroup: 'm_statistics',
          operators: floatOperators
        },
        { id: 'revenue',
          label: 'Revenue',
          type: 'double',
          validation: {
            min: -10000000,
            step: 0.01
          },
          optgroup: 'm_statistics',
          operators: floatOperators
        },
        { id: 'shop_code',
          label: 'Product ID',
          type: 'string',
          optgroup: 'g_information',
          operators: stringOperators
        },
        { id: 'visits',
          label: 'Visits',
          type: 'integer',
          validation: {
            min: 0
          },
          optgroup: 'm_statistics',
          operators: floatOperators
        },
        { id: 'products_in_stock',
          label: 'Stock',
          type: 'integer',
          validation: {
            min: 0
          },
          optgroup: 'g_information',
          operators: floatOperators
        },
        { id: 'assists',
          label: 'Assists',
          type: 'integer',
          validation: {
            min: 0
          },
          optgroup: 'm_statistics',
          operators: floatOperators
        },
        { id: 'stock_status',
          label: 'Stock Status',
          type: 'string',
          input: isContentFilter ? 'text' : 'select',
          operators: selectOperators,
          optgroup: 'g_information',
          values: Adcurve.stockStatuses
        },
        { id: 'delivery_period',
          label: 'Delivery period',
          type: 'string',
          input: isContentFilter ? 'text' : 'select',
          operators: selectOperators,
          optgroup: 'g_information',
          values: Adcurve.deliveryPeriods
        },
        { id: 'delivery_cost',
          label: 'Delivery Costs',
          type: 'double',
          validation: {
            min: -10000000,
            step: 0.01
          },
          optgroup: 'g_information',
          operators: floatOperators
        },
        { id: 'vendor_code',
          label: 'Vendor Code',
          type: 'string',
          optgroup: 'g_information',
          operators: stringOperators
        },
        { id: 'product_ean',
          label: 'EAN',
          type: 'string',
          optgroup: 'g_information',
          operators: stringOperators
        }
    ];
  return Lazy(result);
};
Adcurve.brandFilters = function() {
  return Adcurve.buildAggregatedFilters(Adcurve.FLOAT_OPERATORS).concat([{ id: 'brand',
    label: 'Product Brand',
    type: 'string',
    optgroup: 'g_information',
    operators: Adcurve.STRING_OPERATORS
  }]);
};
Adcurve.familyCodeFilters = function() {
  var result = Adcurve.buildAggregatedFilters(Adcurve.FLOAT_OPERATORS).concat([{ id: Adcurve.shopFields.byUserFieldName('family_code'),
    label: 'Family Code',
    type: 'string',
    optgroup: 'g_information',
    operators: Adcurve.STRING_OPERATORS
  }]);
  Adcurve.aggregationExtraFields.each(function(field){
    result.push({ id: Adcurve.shopFields.byUserFieldName(field),
      label: Adcurve.humanize(field),
      type: 'double',
      optgroup: 'g_information',
      operators: Adcurve.FLOAT_OPERATORS
    });
  });
  result = _.map(result, function(filter) {
    return (_.indexOf(Adcurve.aggregatedComparableFieldsIDs(), filter.id) !== -1) ? Adcurve.queryBuilder.selectizeAggregatedFilter(filter) : filter;
  });
  return result;
};
Adcurve.buildAggregatedFilters = function(floatOperators) {
  return [{ id: 'costs',
    label: 'Costs',
    type: 'double',
    validation: {
      min: 0,
      step: 0.01
    },
    optgroup: 'm_statistics',
    operators: floatOperators
  },
  { id: 'profit',
    label: 'Profit',
    type: 'double',
    validation: {
    min: -10000000,
    step: 0.01
    },
    optgroup: 'm_statistics',
    operators: floatOperators
  },
  { id: 'visits',
    label: 'Visits',
    type: 'integer',
    validation: {
      min: 0
    },
    optgroup: 'm_statistics',
    operators: floatOperators
  },
  { id: 'assists',
    label: 'Assists',
    type: 'integer',
    validation: {
      min: 0
    },
    optgroup: 'm_statistics',
    operators: floatOperators
  },
  { id: 'revenue',
    label: 'Revenue',
    type: 'double',
    validation: {
      min: -10000000,
      step: 0.01
    },
    optgroup: 'm_statistics',
    operators: floatOperators
  },
  { id: 'margin',
    label: 'Margin',
    type: 'double',
    validation: {
    min: -10000000,
    step: 0.01
    },
    optgroup: 'm_statistics',
    operators: floatOperators
  }];
};
Adcurve.buildFreeFeedFilters = function() {
  return Lazy(Adcurve.sidebarFilters()).reject({optgroup: 'm_statistics'}).toArray();
};
Adcurve.buildChannelFilters = function() {
  var excludeFields = Lazy(['floor_price', 'product_margin', 'margin_ratio']);
  var includeFields = Lazy(['revenue', 'visits', 'assists', 'costs', 'ecpc']);
  return Lazy(Adcurve.sidebarFilters()).reject(function(element){
    return (element.optgroup === 'm_statistics' && !includeFields.contains(element.id)) || excludeFields.contains(element.id)
  }).toArray();
};

Adcurve.sortFilters = function(a, b) {
  var aOptGroup = a.optgroup;
  var bOptGroup = b.optgroup;
  var aLabel = a.label.toLowerCase();
  var bLabel = b.label.toLowerCase();
  if(aOptGroup == bOptGroup) {
    return (aLabel < bLabel) ? -1 : (aLabel > bLabel) ? 1 : 0;
  } else {
    return (aOptGroup < bOptGroup) ? -1 : 1;
  }
};
