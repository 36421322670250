/*global Adcurve, _ */

class ColumnsCollection {
  constructor() {
    if (Adcurve.channelUser){
      this._currentColumns = ["traffic", "assists", "revenue", "costs", "price_incl", "products_in_stock"];
    } else if (Adcurve.freeFeed) {
      this._currentColumns = ["price_incl", "floor_price", "margin", "products_in_stock", "stock_status", "delivery_period"];
    } else {
      this._currentColumns = this.statisticsColumnsForPriceModel();
    }
    if (window.location.hash !== "") {
      this.setPredefinedColumns(window.location.hash.substr(1));
    }
    this._suggestedCategorySearch = "";
  }


  suggestedCategorySearch() {
    return this._suggestedCategorySearch;
  }

  currentColumns() {
    return this._currentColumns;
  }

  statisticsColumnsForPriceModel() {
    let columns;

    if (['gold', 'silver'].indexOf(Adcurve.adSettings.price_model) !== -1) {
      columns = ["revenue", "margin", "costs", "profit", "roi"];
    } else {
      columns = ["revenue", "margin", "products_in_stock", "stock_status", "delivery_period"];
    }
    if (!Adcurve.adSettings.marketplace) {
      columns.splice(0, 0,"traffic");
    }
    return columns;
  }

  setPredefinedColumns(type) {
    switch(type){
      case 'statistics' :
        this._currentColumns = this.statisticsColumnsForPriceModel();
      break;
      case "content" :
        this._currentColumns = ["price_incl", "floor_price", "product_margin", "products_in_stock", "stock_status", "delivery_period"];
      break;
    }
  }

  getFormatted = function() {
    let columns = [...new Set([...["picture_link", "product_name"], ...this._currentColumns])];

    if (Adcurve.adSettings.has_publisher) {
      columns = [...new Set([...["check_box"], ...columns, ...["active"]])];
    }

    return columns;
  }

  rulesToColumns(rules) {
    const columns = [];
    const allowedColumns = ["pub_category_path", "status", "category", "shop_code", "brand", "product_name"];
    const currentColumns = this.currentColumns()
    const shouldPushRule = (ruleID) => !(currentColumns.includes(ruleID) || typeof ruleID === "number" || allowedColumns.includes(ruleID));

    rules && rules.forEach((rule) => {
      // ignore existing columns and bidding groups and columns shown already in other fields
      if (shouldPushRule(rule.id)) {
        if (rule.id === "visits"){
          columns.push("traffic");
        } else {
          columns.push(rule.id);
        }
      }

      // add columns if you have a filter that requires another field for comparison
      if (Adcurve.isFieldAsValueRule(rule) && currentColumns.indexOf(rule.data.fieldToCompare) === -1) {
        columns.push(rule.data.fieldToCompare);
      }
      // store suggested category for the search modals
      if (rule.id === 'pub_category_path' || rule.id === 'category') {
        this._suggestedCategorySearch = rule.value;
      }
    });
    return columns;
  };

  applyRules(rules) {
    // Fetch all columns added by filters
    const columns = this.rulesToColumns(rules);
    // append those columns to current columns and return final 6 columns
    const uniqueColumns = [...new Set([...this._currentColumns, ...columns])];

    this._currentColumns = uniqueColumns.slice(Math.max(uniqueColumns.length - 6, 0));
  }
}

Adcurve.ColumnsCollection = ColumnsCollection;
