if (typeof(Adcurve) === "undefined") {
  window.Adcurve = {};
}

import AreaGraph from "./AreaGraph";
import AssistsGraph from "./AssistsGraph";
import ChartCookie from "./ChartCookie";
import ContributionDonut from "./ContributionDonut";
import "./D3Ttimeline";
import D3_LOCALE from "./Shared";
import "./StatsDailyTable";
import "./Timeline"
import "./TopFlop"

const charts = { AreaGraph, AssistsGraph, ChartCookie, ContributionDonut, D3_LOCALE };

export default charts;
