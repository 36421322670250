/* globals $, FB, console */
window.FacebookConnect = (function () {
  'use strict';
  var api = {},
      config = {},
      FB,
      accessToken,
      urlParamsRegExp = new RegExp("shops/([0-9]+)/publishers/([0-9]+)");

  api.init = function (sdk, obj) {
    FB = sdk;
    config = obj;
    $('#form-connection #next-step').hide();
    bindButtons();
    facebookInit();
  };

  function facebookInit() {
    FB.getLoginStatus(function(response) {
      if (response.status == 'not_authorized') {
        showControls(false);
      } else if (response.status == 'connected') {
        var userId = response.authResponse.userID;
        searchOauthIdentity(userId, function (response) {
          setIdentityIdDOM(response.id);
          showControls(true);
        }, function(response) {
          if (response.status == 404) {
            revokeWithSession(userId, showControls);
          }
        });
      } else {
        showControls(false)
      }
    });
  }

  function getAuthDetails(options) {
    $.getJSON(config.get_oauth_identity + '&sub=' + getIdentityId()).done(options.success).fail(options.error);
  };

  function searchOauthIdentity(userId, sucessCallback, failCallback) {
    $.getJSON(config.search_oauth_identity + '&sub=' + userId).done(sucessCallback).fail(failCallback);
  };

  function setIdentityIdDOM(id) {
    $('#cost_connection_oauth_identity_id').val(id);
  }

  function getIdentityId() {
    return $('#cost_connection_oauth_identity_id').val();
  }

  function parseIDsFrom(location) {
    var res = location.match(urlParamsRegExp);
    return { shop_id:      res[1],
             publisher_id: res[2] }
  }

  function storeAuthResult(authResponse, callback) {
    var payload = parseIDsFrom(window.location.toString());
    payload.code = authResponse.accessToken;
    payload.id_token = authResponse.userID;

    $.ajax({
      type: 'POST',
      url: config.save_oauth_identity,
      dataType: 'json',
      success: function(result) {
        // Handle or verify the server response.
        setIdentityIdDOM(result.id);
        callback();
      },
      error: function(result) {
        console.error('Did not exchange token with facebook backend: storeAuthResult got error result', result);
        showControls(true);
      },
      data: payload
    });
  }

  function bindButtons() {
    $("#facebook-connect").click(function (event) {
      event.preventDefault();
      var form = $(this).parents('form');
      signIn(function () {
        showControls(true);
      });
    });

    $("#facebook-signout").click(function (event) {
      event.preventDefault();
      $('#form-connection #next-step').hide();
      signOut(function () {
        showControls(false);
        signIn();
      })
    });

    $("#facebook-disconnect").click(function (event) {
      event.preventDefault();
      var form = $(this).parents('form');
      revokeWithData(function () {
        form.submit();
      });
    });
  }

  function revokeWithSession(userId, callback) {
    FB.api('/' + userId + '/permissions', 'delete', function(response) {
      setIdentityIdDOM(null);
      callback();
    });
  }

  function revokeWithData(callback) {
    getAuthDetails({
      success: function (response) {
        var payload = { access_token: response.access_token }
        FB.api('/' + response.external_user_id + '/permissions', 'delete', payload, function(response) {
          setIdentityIdDOM(null);
          callback(false);
        });
      },
      error: function (response) {
        setIdentityIdDOM(null);
        callback(false);
        console.log('Error: Cannot find oauth identity')
      }
    })
  }

  function signIn(callback) {
    callback = callback || function () {};
    FB.login(function (response) {
      if (response.authResponse === null) {
        //user cancelled the login dialog / flow
        return false;
      }
      storeAuthResult(response.authResponse, callback);
    }, {scope: config.scope});
  }

  function signOut(callback) {
    callback = callback || function () {};
    setIdentityIdDOM(null);
    FB.logout(callback)
  }

  function showControls(isSignedIn) {
    if (isSignedIn) {
      // FIXME it doesn't hide the connect button...
      $('#signed-out').hide();
      $('#signed-out-message').hide();
      $('#signed-out-flash').hide();
      $('#signed-in').show();
      $('#signed-in-message').show();
      renderDisplayInfo();
      renderAccounts();
    } else {
      $('#signed-in').hide();
      $('#signed-in-message').hide();
      $('#signed-out').show();
      $('#signed-out-message').show();
      $('#signed-out-flash').show();
    }
  }

  function renderDisplayInfo() {
    FB.api('/me', function(response) {
      $('#facebook-name').text(response.name);
    });
  }

  function renderAccounts() {
    var selector = $('#accounts-list'),
        errorText = $('#no-accounts');
    FB.api('/me/adaccounts', function(response) {
      if (response.error !== undefined || response.data.length == 0) {
        errorText.show();
        selector.hide();
        return false;
      }
      var tpl = _.template('<option value="<%=k%>"><%=v%></option>');
      var options = _.map(response.data, function(x) { return tpl({ k: x.account_id, v: x.id }) })
      $('#accounts-list #cost_connection_ad_account_id').html(options);
      // sorry there is no way i can have a callback on the dom modifications above, semantic-ui is a bitch
      window.setTimeout(function () {
        $('#accounts-list .select').dropdown();
        selector.parent().show();
        $('#form-connection #next-step').show();
      }, 100);
    });
  }

  return api;
}());
