class PublisherWizardForm {
  constructor(id, resetForm) {
    this.wizard = $(id);
    this.form = this.wizard.find('form');
    this.resetForm = resetForm || false;
  }

  init(validation_types) {
    this.validation_types = validation_types || {};

    const formatter = Adcurve.formWithNumberFormat(this.form);
    formatter.addInput('#currency_value');
    formatter.addInput('.rule-description input.currency');
    formatter.addInput('#percentage_value', '%');

    $('.setting-bidgroups input.currency').each(function (index, input) {
      formatter.addInput('#' + $(input).attr('id'), '%', true);
    });

    $("#currency_value").parents('form').submit(function (e) {
      let final;
      if ($('#currency_value').attr('disabled') === 'disabled') {
        final = Adcurve.unformatNumber($("#percentage_value").val());
      } else {
        final = Adcurve.unformatNumber($("#currency_value").val()) * 100;
      }
      $("#final_cost_value").val(final);
    });

    $("#publisher_settings_form").submit(function (e) {
      $('#selected_tab').val(window.location.hash.slice(1));
      $('.setting-bidgroups input.currency').each(function (index, input) {
        const el = $(input);
        el.val(Adcurve.unformatNumber(el.val()));
      });
    });

    this.changeInputType(); // set validation
    this.form.on('change', '#cost_cost_type', this.changeCostType);
    this.form.on('change', 'select[name="cost[cost_structure]"]', this.changeInputType);
  }

  hideAllDropdowns() {
    $('#dropdown_CPC').attr('disabled', true);
    $('#dropdown_CPM').attr('disabled', true);
    $('#dropdown_CPS').attr('disabled', true);
    $('#dropdown_CPS').parents('.form-row').addClass('hidden');
  }


  showHideInputs(showInput, hideInput) {
    $(showInput).show().prop('disabled', false);
    $(hideInput).hide().prop('disabled', true);
    $(showInput).closest('.input').show();
    $(hideInput).closest('.input').hide();
  }

  changeCostType (event) {
    const costType = $('#cost_cost_type').val();

    // hide and disable all by default
    this.hideAllDropdowns();

    // show and enable selected cost type
    const labelValue = $(`#dropdown_${costType}`).attr('rel');
    $('label[for="cost_cost_value"]').text(labelValue);

    if (costType === 'CPS') {
      $('#dropdown_CPS').parents('.form-row').removeClass('hidden');
      this.changeInputType();
    } else {
      this.showHideInputs('#currency_value', '#percentage_value');
    }
    $('#dropdown_' + costType).attr('disabled', false);

    this.resetValue();
  }

  changeInputType (event) {
    let inputType = this.validation_types[$('#dropdown_CPS').val()];
    // Needed because a percentage option is now set to default PD-2563
    const costType = $('#cost_cost_type').val();
    if (costType !== 'CPS') {
      inputType = 'cents';
    }
    if (inputType === '%') {
      this.showHideInputs('#percentage_value', '#currency_value');
    }

    if (inputType === 'cents') {
      this.showHideInputs('#currency_value', '#percentage_value');
    }
    if (event !== undefined) {
      this.resetValue();
    }

    return true;
  }

  resetValue() {
    if (!this.resetForm) return false;
    const type = that.validation_types[$('#dropdown_CPS').val()];

    if (type === '%') {
      $('#percentage_value').val('0');
      Adcurve.formatNumberInput('#percentage_value', '%');
    }
    if (type === 'cents') {
      $('#currency_value').val('0.00');
      Adcurve.formatNumberInput('#currency_value');
    }
  }
}


$(function () {
  $('#form-publish a#skip-step').click(function (event) {
    event.preventDefault();
    $('#publish_inform_now').val('false');
    $('#form-publish').submit();
  });
});

window.PublisherWizardForm = PublisherWizardForm;
export default PublisherWizardForm;

