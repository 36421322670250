/*global Adcurve, Lazy */
RegExp.escape = function(s) {
    return s.replace(/[-\/\\^$*+?.()[\]{}]/g, '\\$&');
};
Adcurve.RulesItem = function(rule){
  this._rule = rule;
  if (typeof this._rule.value === 'string') {
    this._rule.regexpValue = new RegExp(RegExp.escape(this._rule.value).toLowerCase(), 'i');
  }
  if (this._rule.operator === 'equal') {
    var separator = '|';
    if (this._rule.id === 'shop_code' && this._rule.value.indexOf(' ') !== -1) {
      separator = ' ';
    }
    this._rule.splitRuleValue = Lazy(this._rule.value.toString().toLowerCase().split(separator)).map(function(el) { return el.trim(); });
  }
};
Adcurve.RulesItem.prototype.rule = function() {
  return this._rule;
}

Adcurve.RulesItem.prototype.compare = function(element, shouldShow) {
  if (this._rule.id === 'status') {
    if (!this.compareStatus(element)) {
      shouldShow = false;
      return;
    }
  }
  if (Lazy(Adcurve.taxonomies.ids()).indexOf(this._rule.id) !== -1) {
    if (this.compareTaxonomy(element)) {
      shouldShow = false;
      return;
    }
  } else {
    if (Lazy(['double', 'integer']).indexOf(this._rule.type) !== -1) {
      if (!this.compareNumberRule(element)) {
        shouldShow = false;
        return;
      }
    }

    if (Lazy(['string']).indexOf(this._rule.type) !== -1 && this._rule.id !== 'status') {
      if (!this.compareStringRule(element)) {
        shouldShow = false;
        return;
      }
    }
  }
  return shouldShow;
};

Adcurve.RulesItem.prototype.compareStringRule = function(element) {
  var value = element[this._rule.id];
  var isDefined = typeof value !== 'undefined';
  switch (this._rule.operator) {
    case 'equal':
      return isDefined && value !== null && this._rule.splitRuleValue.indexOf(value.toLowerCase()) !== -1;
    case 'not_equal':
      return (isDefined && value !== null && value.toLowerCase() !== this._rule.value.toLowerCase()) || !isDefined;
    case 'contains':
      return isDefined && value !== null && this._rule.regexpValue.test(value.toLowerCase());
    case 'not_contains':
      return (isDefined && value !== null && !this._rule.regexpValue.test(value.toLowerCase())) || !isDefined;
    case 'is_not_empty':
      return isDefined && value !== null && value.length !== 0;
    case 'is_empty':
      return !isDefined || value === null || value.length === 0;
    case 'is_manually_set':
      return isDefined && value !== null && value.length !== 0 && value == element.manual_pub_category_path;
    case 'is_not_manually_set':
      return isDefined && value !== null && value.length !== 0 &&
             element.manual_channel_category_ids.length === 0 && value == element.rule_pub_category_path;
    case 'is_manually_set_by_tip':
      return isDefined && element.category_set_from_tip && value !== null && value.length !== 0 &&
             value == element.manual_pub_category_path;
  }
};

Adcurve.RulesItem.prototype.compareTaxonomy = function(element) {
  var rule = this._rule;
  var value = Lazy(element.taxonomies).find(function(taxonomy){ return taxonomy.id == rule.id});
  // RETURNS FALSE TO SHOW THE ELEMENT
  switch (rule.operator) {
    case 'equal':
      return typeof value === "undefined" || value.value != rule.value;
    case 'not_equal':
      return typeof value !== "undefined" && value.value == rule.value;
    case 'is_not_empty':
      return typeof value === "undefined";
    case 'is_empty':
      return typeof value !== "undefined";
    case 'is_manually_set':
      return typeof value === "undefined" || value.type !== "manual" || value.taxonomy_set_from_tip;
    case 'is_not_manually_set':
      return typeof value === "undefined" || value.type !== "rule";
    case 'is_manually_set_by_tip':
      return typeof value === "undefined" || value.type !== "manual" || !value.taxonomy_set_from_tip;
  }
};

Adcurve.RulesItem.prototype.compareStatus = function( element){
  if (this._rule.operator === "equal") {
    switch(this._rule.value) {
    case 'active':
      if (!element.active) {
        return false;
      }
      break;
    case 'inactive':
      if (element.active) {
        return false;
      }
      break;
    case 'enabled':
      if (!element.enabled) {
        return false;
      }
      break;
    case 'disabled':
      if (element.enabled) {
        return false;
      }
      break;
    case 'manually_enabled':
      if (!element.manually_set) {
        return false;
      }
      break;
    case 'manually_disabled':
      if (element.manually_set === undefined || element.manually_set === null || element.manually_set) {
        return false;
      }
      break;
    case 'manually_disabled_tip':
      if (element.manually_set_tip === undefined || element.manually_set_tip === null || element.manually_set_tip) {
        return false;
      }
      break;
    case 'content_rule_deactivation':
      if (element.deactivation_reason === undefined || element.deactivation_reason === null){
        return false;
      }
      break;
    }
  } else if (this._rule.operator === "not_equal") {
    switch(this._rule.value) {
    case 'active':
      if (element.active) {
        return false;
      }
      break;
    case 'inactive':
      if (!element.active) {
        return false;
      }
      break;
    case 'enabled':
      if (element.enabled) {
        return false;
      }
      break;
    case 'disabled':
      if (!element.enabled) {
        return false;
      }
      break;
    case 'manually_enabled':
      if (element.manually_set) {
        return false;
      }
      break;
    case 'manually_disabled':
      if (element.manually_set === false) {
        return false;
      }
      break;
    case 'manually_disabled_tip':
      if (element.manually_set_tip === false) {
        return false;
      }
      break;
    case 'content_rule_deactivation':
      if (!element.deactivation_reason){
        return false;
      }
      break;
    }
  }

  return true;
};
Adcurve.RulesItem.prototype.compareNumberRule = function(element) {
  var value = this._rule.id === 'visits' ? element.traffic : element[this._rule.id];
  var ruleValue = Adcurve.getRuleValue(this._rule, element);
  var multiplier = (this._rule.id === 'roi' || this._rule.id === 'margin_ratio') ? 100.0 : 1.0;

  switch (this._rule.operator) {
    case 'less':
      return value < (ruleValue / multiplier);
    case 'less_or_equal':
      return value <= (ruleValue / multiplier);
    case 'greater':
      return value > (ruleValue / multiplier);
    case 'greater_or_equal':
      return value >= (ruleValue / multiplier);
    case 'between':
      return (this._rule.value[0] / multiplier) <= value && value <= (this._rule.value[1] / multiplier);
    case 'equal':
      return value == (ruleValue / multiplier);
    case 'not_equal':
      return value != (ruleValue / multiplier);
    case 'is_not_empty':
      return value !== null && value !== undefined && value.length !== 0;
    case 'is_empty':
      return value === null || value === undefined || value.length === 0;
  }
};
