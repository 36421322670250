Adcurve.semantic_scripts = function () {
  $('.redirect').show().dropdown({onChange: function(value){
    window.location.href = value;
  }});
  $('#custom-dropdown, .select, .channel-select').dropdown();
  $('.tooltip-top').popup({
    lastResort: true,
    exclusive: true,
    position: 'top center'
  });
  $('.tooltip-bottom').popup({
    lastResort: true,
    exclusive: true,
    hoverable: true,
    position: 'bottom center'
  });
  $('.input .state').popup({
    lastResort: true,
    exclusive: true,
    distanceAway: 5,
    preserve: true,
    position: 'bottom center',
  });
  $('.status-tooltip').popup({
    position: 'left center',
    delay: {
      show: 100,
      hide: 100
    },
    className: {
      popup: 'ui popup status-tooltip'
    }
  });
};
Adcurve.onLoad(Adcurve.semantic_scripts);
