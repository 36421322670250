var ready = function(){
  var alert_box = $('#no_traffic_alert');

  if(alert_box && alert_box.data() && alert_box.data().publisherId){
    if( $.cookie('no_traffic_alert_'+alert_box.data().publisherId) === undefined ){
      alert_box.removeClass('hidden');
    }
  }

  $('.flash .close').on('click', function() {
    var box = $(this).closest('.flash');
    if(box.attr('id') == 'no_traffic_alert'){
      var tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate()+1);
      tomorrow.setHours(0);
      tomorrow.setMinutes(0);
      tomorrow.setSeconds(0);

      $.cookie('no_traffic_alert_'+box.data().publisherId, true,  { expires: tomorrow });
    }

    box.transition('fade');
  });
};

document.addEventListener("DOMContentLoaded", ready);
