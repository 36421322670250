/*global Adcurve, _, Lazy */

import accounting from 'accounting';

Adcurve.rulesText = function(r, originalRule) {
  var rules = r || Adcurve.queryBuilder.getRules().rules;
  var i = 0, label = 'IF', fullTitle = [], title = '';
  var usableRules = Lazy(Adcurve.usableRules(rules));
  var formattedUsableRules = usableRules.map(function(rule) {
    if (i === 1) {
      label = 'AND';
    }
    i++;
    var operatorValue = rule.operator.replace(/_/g, ' ');
    if (rule.operator === 'is_empty') {
      title = rule.label + ' is not set';
    } else if (rule.operator === 'is_not_empty') {
      title = rule.label + ' is set';
    } else if (rule.operator === 'is_manually_set') {
      title = rule.label + ' is manually set';
    } else if (rule.operator === 'is_not_manually_set') {
      title = rule.label + ' is not manually set';
    } else if (rule.operator === 'is_manually_set_by_tip') {
      title = rule.label + ' is manually set by tip';
    } else if (rule.operator === 'not_contains') {
      operatorValue = "doesn't contain";
      title = rule.label + ' ' + operatorValue + ' ' + Adcurve.createRuleValueText(rule);
    } else {
      title = rule.label + ' ' + operatorValue + ' ' + Adcurve.createRuleValueText(rule);
    }
    fullTitle.push(title);
    return '<div class="rule"><div class="condition">' + label + '</div><p>' + title + '</p></div>';
  });
  var periodDependentList = Lazy(Adcurve.PERIOD_DEPENDENT);
  var periodDependent = usableRules.find(function(rule) {
    return periodDependentList.contains(rule.id) || Adcurve.isFieldAsValueRule(rule) && periodDependentList.contains(rule.data.fieldToCompare)
  });
  if (typeof periodDependent !== 'undefined') {
    var timePeriod;
    if (originalRule) {
      timePeriod = originalRule.time_period;
    } else {
      timePeriod = Adcurve.sidebarComponent.props.days;
    }
    formattedUsableRules = formattedUsableRules.concat(['<div class="rule"><div class="condition">OVER</div><p>the past </p> <div class="input inline"><input type="text" value="' + timePeriod + '" class="form-control" id="periodDependent"/></div> <p> days</p></div>'])
  }
  return [formattedUsableRules.join(''), fullTitle];
};
Adcurve.usableRules = function(r) {
  var rules = r || Adcurve.queryBuilder.getRules().rules;
  var rulesProps = _.reject(rules, function(rule) {
    var contains = _.find(Adcurve.taxonomies.notPermittedFilters(), function(field) {
      return field === rule.label;
    });
    return contains !== undefined;
  });
  return rulesProps;
};
Adcurve.createRuleValueText = function(rule) {
  var valueLabel = rule.value_label;
  if (!_.isArray(valueLabel)) {
    valueLabel = [valueLabel];
  }
  valueLabel = _.map(valueLabel, function(label) {
    if (rule.data !== undefined && rule.data.fieldToCompare !== undefined) {
      var field = Lazy(Adcurve.comparableFields()).find(function(element){
        return rule.data.fieldToCompare == element.id;
      });
      if (typeof field === "undefined") {
        field = Lazy(Adcurve.aggregatedComparableFields()).find(function(element){
          return rule.data.fieldToCompare == element.id;
        });
      }
      return field.name;
    }

    if (_.indexOf(Adcurve.CURRENCY_FIELDS, rule.id) !== -1) {
      return accounting.formatMoney(label, Adcurve.currency.sign, 2, Adcurve.currency.thousand_separator,
                                    Adcurve.currency.decimal_mark, Adcurve.currency.currency_format);
    } else {
      var formattedLabel = label.toString();
      if (rule.id === 'shop_code') {
        formattedLabel = formattedLabel.split(" ").join(" or ");
      }
      formattedLabel = formattedLabel.split("|").join(" or ");

      return formattedLabel;
    }
  });
  return valueLabel.join(' and ');
};
