$(function () {
  var GAEvents = {
    trackEvent: function(event){
      if (typeof ga != 'undefined') {
        ga('send', {
          hitType: 'event',
          eventCategory: GAEvents[event].category,
          eventAction: GAEvents[event].action,
          eventLabel: GAEvents[event].label || ""
        });
      }
    },
    dashboard_tips_details: {
      category: "click to tips",
      action: "shop dashboard level",
      label: "button – see details"
    },
    dashboard_tips_title: {
      category: "click to tips",
      action: "shop dashboard level",
      label: "tip link – see details"
    },
    dashboard_tips_menu: {
      category: "click to tips",
      action: "shop dashboard level",
      label: "button menu – see tip list"
    },
    dashboard_publisher_tips_details: {
      category: "click to tips",
      action: "publisher dashboard level",
      label: "button – see details"
    },
    dashboard_publisher_tips_title: {
      category: "click to tips",
      action: "publisher dashboard level",
      label: "tip link – see details"
    },
    dashboard_publisher_tips_menu: {
      category: "click to tips",
      action: "publisher dashboard level",
      label: "button menu – see tip list"
    },
    tip_list_tips_details: {
      category: "click on tips",
      action: "tip list page - shop level",
      label: "button – see details"
    },
    tip_list_tips_title: {
      category: "click on tips",
      action: "tip list page - shop level",
      label: "tip link – see details"
    },
    tip_list_tips_dismiss: {
      category: "click on tips",
      action: "tip list page - shop level",
      label: "button - dismiss"
    },
    tip_list_publisher_tips_details: {
      category: "click on tips",
      action: "tip list page - publisher level",
      label: "button – see details"
    },
    tip_list_publisher_tips_title: {
      category: "click on tips",
      action: "tip list page - publisher level",
      label: "tip link – see details"
    },
    tip_list_publisher_tips_dismiss: {
      category: "click on tips",
      action: "tip list page - publisher level",
      label: "button – dismiss"
    },
    tip_details_dismiss: {
      category: "click on tips",
      action: "tip details page",
      label: "button – dismiss tip"
    },
    tip_details_deactivate_products: {
      category: "click on tips",
      action: "tip details page",
      label: "button – deactivate product ads"
    }
  };

  $('a').on('click', function(e){
    if (typeof(ga) == "function") {
      var target = $(e.target);
      if (!target.is('a')) {
        target = target.parent();
      }
      var event = target.data('ga-event');
      if (event !== undefined){
        GAEvents.trackEvent(event);
      }
    }
  });
  $('#chat-widget').on('click', function(){
    GAEvents.trackEvent('chat_with_us');
  });
  $('#tip-products-form').on('submit', function(){
    GAEvents.trackEvent('tip_details_deactivate_products');
  })
});

$(document).on('page:change', function() {
  if (typeof ga != 'undefined') {
    ga('send', 'pageview', window.location.pathname);
  }
});
