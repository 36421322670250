function createContributionDonut (data, options, current_publisher) {
  d3.select('#contribution_graph').select('svg').remove();
  d3.select('.contribution_tooltip').remove();
  function formatPercentage(value) {
    return Math.round(value) + '%';
  }

  function formatNumber(value) {
    return d3.format('.2f');
  }

  function calculateContribution(value) {
    return shopLocale.format("$,.2f")(value/100 * Math.round10(data.margin, -2));
  }

  function getCurrentPublisher(publisher) {
    if (!_.isEmpty(publisher)) {
      return publisher.id;
    }
  }
  function decimalAdjust(type, value, exp) {
    // If the exp is undefined or zero...
    if (typeof exp === 'undefined' || +exp === 0) {
      return Math[type](value);
    }
    value = +value;
    exp = +exp;
    // If the value is not a number or the exp is not an integer...
    if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
      return NaN;
    }
    // Shift
    value = value.toString().split('e');
    value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
    // Shift back
    value = value.toString().split('e');
    return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
  }

  // Decimal round
  if (!Math.round10) {
    Math.round10 = function(value, exp) {
      return decimalAdjust('round', value, exp);
    };
  }
  // Decimal floor
  if (!Math.floor10) {
    Math.floor10 = function(value, exp) {
      return decimalAdjust('floor', value, exp);
    };
  }
  // Decimal ceil
  if (!Math.ceil10) {
    Math.ceil10 = function(value, exp) {
      return decimalAdjust('ceil', value, exp);
    };
  }
  var width = document.getElementById('contribution_graph').offsetWidth,
      height = 200,
      radius = Math.min(width, height) / 2;
  var shopLocale = D3_LOCALE(options);

  var contribution = d3.entries(data.contribution);
  var publishers = {};
  var attrPublisher = {};
  var currentPublisher = getCurrentPublisher(current_publisher);
  data.assists.forEach(function(d) {
    publishers[d.publisher_id] = d.publisher;
    if (d.assigned === true) {
      attrPublisher = d.publisher_id;
    }
  });
  var color = d3.scaleOrdinal()
      .range(["#0096B5", "#42D17F", "#F6A800", "#F2E300", "#E74C3C", "#0E445F", "#cccccc", "#c2c2c2", "#b8b8b8", "#adadad", "#a3a3a3", "#999999", "#8f8f8f"]);

  var arc = d3.arc()
      .outerRadius(radius - 2)
      .innerRadius(radius - 30);

  var pie = d3.pie()
      .value(function(d) { return d.value; })
      .sort(null);

  var svg = d3.select("#contribution_graph").append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

  svg.append("linearGradient")
      .attr("id", "gradient")
      .attr("gradientUnits", "userSpaceOnUse")
      .attr("x1", -200)
      .attr("x2", 150)
      .selectAll("stop")
        .data([
          {offset: "5%", color: "#0096B5"},
          {offset: "70%", color: "#42D17F"}
        ])
      .enter().append("stop")
      .attr("offset", function(d) { return d.offset; })
      .attr("stop-color", function(d) { return d.color; });

  var contribution_tooltip = d3.select('#contribution_graph').append('div')
      .attr('class', 'contribution_tooltip')
      .style('opacity', 1)
      .html('<h4>Total</h4><p>' + shopLocale.format("$,.2f")(data.margin) + ' margin</p>' +
            '<p>' + data.assists.length + ' assist(s)</p>')


  var g = svg.selectAll(".arc")
      .data(pie(contribution))
      .enter().append("g")
      .attr("class", "arc")
      .on('mouseover', function(d) {
          contribution_tooltip.transition()
            .duration(200)
            .style('opacity', 0.9);
          contribution_tooltip.html(
            '<div class="contribution"><div class="dot" style="background-color:' + color(d.data.key) + '"></div><p>' + formatPercentage(d.value) + '</p></div>' +
            '<p>' + publishers[d.data.key] + '</p>' +
            '<p>' + calculateContribution(d.value) + ' margin</p>'
          );
      })
      .on('mouseout', function(d) {
          contribution_tooltip.transition()
            .duration(500)
            .style('opacity', 1);
          contribution_tooltip.html(
            '<h4>Total</h4><p>' + shopLocale.format("$,.2f")(data.margin) + ' margin</p>' +
                  '<p>' + data.assists.length + ' assist(s)</p>'
          );
      });

  g.append("path")
      .attr("d", arc)
      .attr('class', 'donut-piece')
      .style("fill", function(d) {
          return color(d.data.key);
      })
}

window.createContributionDonut = createContributionDonut;

export default { createContributionDonut };
