document.addEventListener("DOMContentLoaded", function () {
  if($('#orders-table').length){
    var elementHeight = $(window).height() - $('#orders-table').offset().top - 106 + 'px';
    $('#orders-table').DataTable( {
      "paging": false,
      "ordering": false,
      "info": false,
      "bFilter": false,
      "autoWidth": false,
      "scrollY": elementHeight,
      "scrollX": '100%',
      "scrollCollapse": true
    });
    $(".dataTables_scrollHeadInner, .dataTables_scrollFootInner").css({"width":"100%"});
    $(".table ").css({"width":"100%"});

    $('tfoot').first().remove();

    if($('tbody#orders-list').data('filtered-orders') === true){
      const totals = { 'contributed-revenue': 0, 'contributed-margin': 0, 'revenue': 0, 'margin': 0, 'costs': 0 };

      $.each(totals, function(key, item){
        const columnValues = $('tbody').find('.order-item-' + key);

        if(columnValues.length > 0){
          $.each(columnValues, function(index, element){
            const value = $(element).html().replace(/[^\d.,]/g, '').replace(/,/, '.');
            totals[key] += parseFloat(value);
          });

          const sampleValue = $('tbody').find('.order-item-' + key + ':first').html();
          const actualValue = sampleValue.replace(/[\d,]/g, '') + (totals[key].toFixed(2));

          $('tfoot').find('#total-' + key).html(actualValue);
        }
      });
    }
  }

  if ($('.pagination').length) {
    $(".dataTables_scrollBody").on("scroll", function() {
      const url = $('.pagination .next_page').attr('href');

      // Prevent double calls if user scrolls while the loading is still in progress
      const noLoadingInProgress = !$('.order-loader').is(":visible");
      const scrolledEnough = $('#orders-list tr').last().offset().top < 1000;

      if (noLoadingInProgress && scrolledEnough) {
        $('.order-loader').show();
        return $.getScript(url);
      }
    });
  }
});

